import React, { useEffect, useRef } from 'react'
import { Grid, Button, Typography, IconButton } from '@mui/material'
//import {getCookie} from '../../helpers/helpers';
import { TitleComponent } from '../../components/TitleComponent'
import Cart from '../../media/lottie/CartAnimation.json'
import lottie from 'lottie-web'
import CloseIcon from '@mui/icons-material/Close'

const Lottie = () => {
  const anime = useRef(null)
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: Cart
    })
    return () => lottie.stop()
  }, [])
  return <div ref={anime}/>
}

const BuyNow = (props) => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  const isInstagram = ua.indexOf('Instagram') > -1 ? true : false
  const tld = window.location.hostname.includes('.co.uk') ? '.co.uk' : '.com'
  const redirectURL = `https://www.amazon${tld}/afx/ingredients/landing?tag=saltnprep00-20`


  // if(isInstagram){
  //  redirectURL = "https://www.amazon${tld}/afx/ingredients/landing?tag=saltnprepig00-20";
  // }
  return (
    <Grid
      item
      xs={12}
      sm={11}
      style={{ margin:'auto', display: 'flex', flex: 1, alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}
    >
      <TitleComponent
        title='Grocery Shop Online'
        description='Recieve an aggregated ingredient list to either take to your favorite grocery store or to order online. All for no extra cost. '
      />

      <Grid
        container
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 25,
          paddingBottom: 10
        }}
      >
        <Typography variant='h5'> Order Groceries </Typography>
        <IconButton onClick={props.onClose} sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <CloseIcon style={{ fontSize: 22 }} />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          margin: 'auto'
        }}
      >
        <Typography
          variant='h5'
          style={{ position: 'relative', top: 30, zIndex: 3, textAlign: 'center', paddingTop: 10, color: '#555' }}
        >
          Amazon<b style={{ color: '#7abd25' }}>Fresh</b>
        </Typography>
        <Lottie />
        <Grid item xs={12} style={{ zIndex: 3, position: 'relative', bottom: 30 }}>
          <Typography variant='h5' style={{ textAlign: 'center', paddingBottom: 15 }}>
            We've prepared your <b style={{ color: '#555' }}>Amazon</b>
            <b style={{ color: '#7abd25' }}>Fresh</b> cart
          </Typography>
          <Typography variant='body2' style={{ textAlign: 'center', paddingBottom: 15 }}>
            Continue to AmazonFresh to choose your products, arrange delivery, and pay for your cart.
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: 15, paddingBottom: 15 }}>
        <form
          name='afx-ingredients-verifier-form'
          target={isInstagram ? '' : '_blank'}
          method='post'
          action={redirectURL}
          autoComplete='off'
          style={{ width: '100%' }}
        >
          <input
            type='hidden'
            name='ingredients'
            id='afx-ingredients-verified-hidden-input'
            value={JSON.stringify(props.purchasePayload)}
          />
          <Button onClick={props.onNext} type='submit' variant='contained' color='primary' fullWidth disableElevation>
            Checkout with AmazonFresh
          </Button>
        </form>
        <Typography
          variant='body2'
          style={{ margin: 'auto', textAlign: 'center', paddingTop: 10, paddingBottom: 10, fontStyle: 'italic' }}
        >
          Salt'nPrep and its' partners may earn affiliate fees from any purchases
        </Typography>
        <Button variant='outlined' color='primary' onClick={props.onPrev} fullWidth disableElevation>
          Return to Cart
        </Button>
      </Grid>
    </Grid>
  )
}
export default BuyNow
