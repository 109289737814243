import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Divider
} from '@mui/material'
import ConfirmActionModal from '../../components/ConfirmActionModal'
import MealTemplate from '../../components/MealTemplate'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { delFavorite } from '../../actions'
import PageWrapper from '../../components/PageWrapper'

function Favorites() {
  const favorites = useSelector(state => state.planner.favorites)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [remove, setRemove] = useState(false)

  return (
    <PageWrapper>
      <Grid item xs={12} md={8} style={{ paddingTop: 40 }}>
        <Typography variant='h4'>Favorites</Typography>
        <Divider style={{ marginTop: 15, marginBottom: 15 }}/>
        <Grid container>
          {favorites.length ? favorites.map((meal, index) => {
            return (
              <MealTemplate
                key={index}
                handleTemplateClick={()=>{navigate(`/recipe/${meal.Meal.Name}/${meal.Meal.ID}`, {state: { from: location.pathname}})}}
                handleIconButton={()=>setRemove(meal.Meal.ID)}
                icon={<FavoriteIcon style={{ fontSize: 18, color: 'white' }}/>}
                chefID={meal.Meal.ChefID}
                name={meal.Meal.Name}
                mealid={meal.Meal.ID}
              />)
          })
            :
            <Typography variant='body2'>You have no favorite meals.</Typography>
          }
        </Grid>
        <ConfirmActionModal
          title='Remove Favorite'
          description='Are you sure you want to remove this favorite?'
          onConfirmText='Yes'
          onCloseText='Cancel'
          open={remove}
          onConfirm={()=>{
            dispatch(delFavorite(remove)); setRemove(false)
          }}
          onClose={()=>setRemove(false)}
        />
      </Grid>
    </PageWrapper>
  )
}
export default Favorites

