import React, { useState, useEffect } from 'react'
import { TitleComponent } from '../TitleComponent'
import { Grid, Typography, Tab, Tabs, Avatar, Button } from '@mui/material'
import PageWrapper from '../PageWrapper'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { unfollow } from '../../actions'
import env_config from '../../env_config'


const Label = ({ count, title }) => {
  return <Typography variant='body2'>{title} ({count})</Typography>
}

function Stats(props) {
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(null)
  const following = useSelector((state) => state.user.following)
  const followers = useSelector((state) => state.user.followers)
  const dispatch = useDispatch()

  useEffect(()=>{
    setIsLoading(()=>null)
  }, [following])

  const handleUnfollow = (id) => {
    setIsLoading(()=>id)
    dispatch(unfollow(id))
  }

  const renderAudience = () => {
    switch (value) {
      case 0:
        if (following.length === 0) {
          return (
            <Typography variant='body2' style={{ padding: '20px 8px' }}>
              You are not following anyone.
            </Typography>
          )
        }
        return following.map((person, index) => (
          <Person
            mode='following'
            id={person.UserID}
            username={person.Username}
            name={person.Name}
            handleUnfollow={() => {
              handleUnfollow(person.UserID)
            }}
            disabled={isLoading === person.UserID}
          />
        ))
      case 1:
        if (followers.length === 0) {
          return (
            <Typography variant='body2' style={{ padding: '20px 8px' }}>
              You do not have any followers.
            </Typography>
          )
        }
        return followers.map((person, index) => {
          return (
            <Person
              mode='followers'
              id={person.UserID}
              username={person.Username}
              name={person.Name}
              unfollow={() => {
                unfollow(person.UserID)
              }}
            />
          )
        })
      default:
        return null
    }
  }

  return (
    <PageWrapper>
      <TitleComponent title='Audience' description='Keep track of what ingredients you own with a digital pantry.' />
      <Grid
        item
        xs={12}
        md={8}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 40 }}
      >
        <Typography variant='h4'>Your Stats</Typography>
      </Grid>
      <Grid item xs={12} md={8} style={{ paddingTop: 35 }}>
        <Tabs
          style={{ borderWidth: 0, borderBottomWidth: 1, borderStyle: 'solid', borderColor: '#e6e6e6' }}
          value={value}
          variant='scrollable'
          scrollButtons='off'
        >
          <Tab
            value={0}
            onClick={() => {
              setValue(0)
            }}
            label={<Label count={following.length} title='Following' />}
            aria-label='Following'
          />
          <Tab
            value={1}
            onClick={() => {
              setValue(1)
            }}
            label={<Label count={followers.length} title='Followers' />}
            aria-label='Followers'
          />
        </Tabs>
        {renderAudience()}
      </Grid>
    </PageWrapper>
  )
}

const Person = ({ id, username, name, handleUnfollow, mode, disabled }) => {
  return (
    <Grid
      fullWidth
      item
      xs={12}
      style={{
        borderRadius: 0,
        margin: 'auto',
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: '#e6e6e6'
      }}
    >
      <Grid
        component={Link}
        to={`/chef/${username}`}
        style={{
          textDecoration: 'none',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Avatar
          style={{ height: '48px', width: '48px', backgroundColor: '#E87B51' }}
          alt={username}
          src={id === undefined ? '' : `https://${env_config.s3.BUCKET}.s3.amazonaws.com/protected/${id}/avatar.jpg`}
        />
        <Grid container style={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant='body1' style={{ paddingLeft: 25, fontWeight: 'bold' }}>
            {username}
          </Typography>
           <Typography variant='body2' style={{ paddingLeft: 25, fontSize: 12 }}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      {mode === 'following' && (
        <Button disabled={disabled} onClick={handleUnfollow} variant='outlined' disableElevation color='secondary' size='small'>
          Following
        </Button>
      )}
    </Grid>
  )
}

export default Stats
