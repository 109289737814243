import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Button, CircularProgress, IconButton} from '@mui/material'
import Meal from '../Meal'
import SearchBar from './SearchBar'
import CompactPlanSnackBar from '../Planner/CompactPlanSnackBar'
import { getOfficialMeals, getMealsByQuery, getMeals } from '../../helpers/API'
import { setCookie, getCookie, toTitleCase } from '../../helpers/helpers'
import { TitleComponent } from '../TitleComponent'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import '../../styles/helpers.css'



export default function Search() {
  const [mealList, setMealList] = useState([])
  const [pageNumber, setPageNumber] = useState(getCookie('page') ? JSON.parse(getCookie('page')) : 1)
  const [query, setQuery] = useState('')
  const [selectedDifficulty, setSelectedDifficulty] = useState([])
  const [selectedFollowing, setSelectedFollowing] = useState([])
  const username = useSelector((state) => state.user.account.Username)
  const [selectedCourse, setSelectedCourse] = useState([])
  const [mode, setMode] = useState(getCookie('mode') ? getCookie('mode') : '')
  const [mealError, setMealError] = useState(false)
  const [title, setTitle] = useState('Your Feed')
  const auth = useSelector((state) => state.auth.authenticated)
  const favorites = useSelector((state) => state.planner.favorites.map((meal) => meal['Meal']))

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
      setMealList([])
      if (mode === 'Favorites') {
        handleGetFavoriteMeals()
        setTitle('Your Favorites')
      }
      else if (mode === 'Cookbook'){
        handleGetUserMeals()
        setTitle('Your Cookbook')
      }
      else if (selectedFollowing.length) {
        handleGetChefMeals()
        setTitle(`Meals by ${selectedFollowing[0]}`)
      }
      else {
        handleGetOfficialMeals()
        setTitle('Your Feed')
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, pageNumber, query, selectedDifficulty, selectedCourse, selectedFollowing])

  const handleGetChefMeals = (chef) => {
    getMeals({chef}, pageNumber).then((m) => {
      setMealError(!m.length)
      setMealList(m)
  })
  }

  const handleGetOfficialMeals = () =>{
    var q = {}
    if(query){
      // q += `&name=${toTitleCase(query)}`
      q['name'] = toTitleCase(query)
    }
    if(selectedDifficulty.length){
      q['tag'] = selectedDifficulty.toString()
    }
    if(selectedCourse.length){
      q['category'] = selectedCourse.toString()
    }
    getOfficialMeals(q, pageNumber).then((meals) => {
      setMealError(!meals.length)
      setMealList(meals)
    })

  }

  const handleGetUserMeals = () =>{
    if (username){
      getMealsByQuery({ chef: username }, pageNumber).then((m) => {
        setMealError(!m.length)
        setMealList(m)
      })
    }
    else {
      setMealError(true)
      setMealList([])
    }
  }

  const handleGetFavoriteMeals = () =>{
    setMealError(!favorites.length)
    setMealList(favorites)
  }

  const handlePageChange = (value) => {
    setCookie('page', JSON.stringify(value), 1)
    setPageNumber(value)
  }

  const handleQuerySubmit = (q) => {
    setQuery(q)
  }

  const handleModeChange = (m) =>{
    setMode(m)
    handlePageChange(1)
    setCookie('mode', m, 1)
  }

  const reset = () => {
    handleModeChange('')
    setSelectedFollowing([])
    setSelectedDifficulty([])
    setSelectedCourse([])
    handlePageChange(1)
    setMealError(false)
  }

  const loadMore = () => {
    handlePageChange((p)=> p + 1)
    window.scrollTo(0, 0)
  }
  return (
    <Grid container style={{
        position: 'relative',
        flexGrow: 1,
        minHeight: 'calc(100vh - 60px)',
        display: 'flex'
        }}>
      <TitleComponent
        title='Search | SaltnPrep'
        description='Search for a meal by name or select filters to find the perfect recipe. You can even saerch using ingredients you already own.'
      />

      <Grid item xs={12} md={10} lg={9} style={{paddingTop: 50}} sx={{mx: 'auto'}}>
        <SearchBar
          handleQuerySubmit={handleQuerySubmit}
          handleDifficulty={setSelectedDifficulty} selectedDifficulty={selectedDifficulty}
          handleFollowing={setSelectedFollowing} selectedFollowing={selectedFollowing}
          handleCourse={setSelectedCourse} selectedCourse={selectedCourse}
          handleMode={handleModeChange} selectedMode={mode}
        />

        <Grid item xs={11} md={12} sx={{mx:'auto'}}>
          <Typography variant='h5' style={{ paddingTop: 30, paddingBottom: 20 }}>
            {title}
          </Typography>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems:'flex-start', flexWrap: 'wrap' }}
          >
            {mealList.map((meal) => {
              return (
                <Meal
                  meal={meal}
                  key={meal['ID']}
                  name={meal['Name']}
                  mealID={meal['ID']}
                  chefID={meal['ChefID']}
                  chefName={meal['ChefName']}
                  favoriteCount={meal['FavoriteCount']}
                  calories={Math.round(meal['NutritionFacts']['Calories'])}
                  ingredients={meal['FoundationIngredientList']}
                  prepTime={meal['PrepTime']}
                  cookTime={meal['CookTime']}
                />
              )
            })}
          </Grid>

          {mealError && mode === 'Favorites' && pageNumber === 1 && (
            <Typography variant='body1' style={{ paddingBottom: 150 }}>
              Your favorite meals can be found here for easy meal planning.
              { !auth && ' Login to unlock this feature.'}
            </Typography>
          )}

          {mealError && mode === 'Cookbook' && pageNumber === 1 && (
            <Typography variant='body1' style={{ paddingBottom: 150 }}>
              Meals you create can be found here for easy meal planning.
              { !auth && ' Login to unlock this feature.'}
            </Typography>
          )}

          {mealError && (mode === 'All' || mode === 'Query') && pageNumber === 1 && (
            <Typography variant='body1' style={{ paddingBottom: 150 }}>
              No meals currently match your search criteria
            </Typography>
          )}

          {mealError && pageNumber !== 1 && (
            <Typography variant='body1' style={{ paddingBottom: 150 }}>
              There are no more meals matching your criteria.
            </Typography>
            )}

          <Grid item xs={3} sm={2} lg={1} sx={{mx: 'auto'}} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
             {mealError && <Button fullWidth variant='contained' disableElevation onClick={mealError ? reset : loadMore}>
              Reset&nbsp;Search
            </Button>}

            {(!mealError && !mealList.length) && <CircularProgress color='primary'/>}
          </Grid>

          { ((Boolean(mealList.length)) || pageNumber !== 1) &&
            <Grid item xs={12} sm={6} sx={{mx: 'auto'}} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <IconButton size='medium' disabled={pageNumber === 1} onClick={()=>{handlePageChange(pageNumber - 1)}} ><KeyboardArrowLeftIcon fontSize='small' style={{...pageNumber === 1 && {color: '#ccc'}}}/></IconButton>
              <IconButton size='medium' onClick={()=>{window.scrollTo(0, 0)}}><Typography variant='body1'>{pageNumber}</Typography></IconButton>
              <IconButton size='medium' disabled={mealError || mealList.length < 12} onClick={()=>{handlePageChange(pageNumber + 1)}}><KeyboardArrowRightIcon fontSize='small' style={{...(mealError || mealList.length < 12) && {color: '#ccc'}}}/></IconButton>
            </Grid>
          }
        <CompactPlanSnackBar/>
        <div style={{width: '100%', paddingTop: 55}}/>
        </Grid>
      </Grid>
    </Grid>
  )
}
