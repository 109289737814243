import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Checkbox,
  Grid,
  CircularProgress
} from '@mui/material'

export default function ConfirmActionModal(props) {
  const [selected, setSelected] = React.useState(false)
  return (
    <Dialog
      open={props.open}
      onClose={props.onMinimize ? props.onMinimize : props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {props.title && <DialogTitle id='alert-dialog-title' style={{ padding: 20 }}>
        <Typography variant='h5' style={{ textAlign: 'center' }}>
          {props.title}
        </Typography>
      </DialogTitle>
    }
      <DialogContent style={props.title && {paddingTop: 0 }}>
        <DialogContentText id='alert-dialog-description'>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {props.description}
          </Typography>
        </DialogContentText>
      </DialogContent>
      {props.Checkbox && (
        <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Checkbox
            onChange={(event) => {
              setSelected(event.target.checked)
            }}
            size='small'
          />
          <Typography variant='body2'>{props.Checkbox}</Typography>
        </Grid>
      )}
      <DialogActions style={{ display: 'flex', justifyContent: 'space-around', padding: 20, paddingTop: 10 }}>
        {props.onCloseText && (
          <Button
            disabled={props.isLoading}
            onClick={props.onClose}
            size='small'
            fullWidth
            variant='outlined'
            style={{ textTransform: 'uppercase', height: 42.75, maxHeight: 42.75 }}
          >
            {props.onCloseText}
          </Button>
        )}
        <Button
          onClick={() => {
            props.onConfirm(selected)
          }}
          disabled={props.isLoading}
          disableElevation
          size='small'
          fullWidth
          variant='contained'
          color='primary'
          style={{ textTransform: 'uppercase', height: 42.75, maxHeight: 42.75 }}
          autoFocus
        >
          {props.isLoading ? <CircularProgress size={22} style={{color: 'white'}}/> : props.onConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
