import React, { useState, useEffect } from 'react'
import { Dialog, Grid, Typography, Button, TextField, InputAdornment, IconButton, Tooltip, CircularProgress } from '@mui/material'
import { cleanUsername } from '../../helpers/helpers'

import {
  postAccount
} from '../../actions'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import {
  handleMailChimpSubscription,
  usernameExists
} from '../../helpers/API'
import { BiError, BiCheckShield } from 'react-icons/bi'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'


export default function OnBoarding(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [usernameClash, setUsernameClash] = useState(true)
    const [isLoadingClash, setIsLoadingClash] = useState(false)
    const profile = useSelector((state) => state.auth.profile)
    const responseCode = useSelector((state) => state.user.response)

    const theme = useTheme()

    useEffect(()=>{
      if(responseCode === 200){
        navigate('/plan')
      }
      else{
        console.log({responseCode})
      }
    }, [responseCode])

    useEffect(()=>{
        async function init() {
            try {
                setIsLoadingClash(()=>true)
                const email = profile.email
                const tempUsername = cleanUsername(email)
                let clash = await usernameExists(tempUsername)
                setUsernameClash(clash)
                setUsername(tempUsername)
                setIsLoadingClash(()=>false)
            } catch (e) {
                setIsLoadingClash(()=>false)
                console.log({e})
            }
        }
        if(profile){
            init()
        }
    },[profile])

    const changeText = async(event) => {
        setIsLoadingClash(()=>true)
        var cleaned = cleanUsername(event.target.value)
        setUsername(cleaned)
        if (cleaned.length > 6){
        let clash = await usernameExists(cleaned)
        setUsernameClash(clash)
        }
        setIsLoadingClash(()=>false)
    }

    const handleContinueDisabled = () => {
        return !(!usernameClash && (username.length > 6))
    }

    const handleSignUp = (event) => {
        event.preventDefault()
        setIsLoadingClash(()=>true)
        const email = profile.email
        let body = {
            Username: username,
            Name: username,
            Email: email,
            Birthday: '',
            Zipcode: '',
            Newsletter: true,
            Allergy: [],
            Appliance: [],
            Link: [],
            Bio: []
        }
        dispatch(postAccount(body))
        handleMailChimpSubscription(email, username, 'subscribe')
        ReactGA.event({category: 'authenticate', action: 'signup'})
        setIsLoadingClash(()=>false)
    }

  const renderUserNameInput = () => {
    return(
      <TextField
        color='primary'
        size='small'
        onChange={changeText}
        fullWidth
        required
        id='username'
        autoComplete='new-username'
        value={username}
        label='Username'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <PersonOutlineOutlinedIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
            {isLoadingClash ?
              <CircularProgress size={24}/>
              :
            <Tooltip arrow placement='top' title={username.length < 7 ? 'Username must be at least 7 characters' : (usernameClash ? 'This username is not available' : 'This username is available!')}>
              <span>
                {username.length !== 0 &&
                <IconButton size='small' disabled>
                  {
                    (username.length < 7 || usernameClash) ? <BiError style={{color: '#eed202', fontSize: 24}}/> : <BiCheckShield style={{color: theme.palette.primary.main, fontSize: 24}}/>
                  }
                </IconButton>
                }
              </span>
            </Tooltip>
          }
            </InputAdornment>
          )
        }}
    />
    )
  }

  return (
    <Dialog
      BackdropProps={{ style: { backgroundColor: '#FFFFFFF2' } }}
      open={true}
      fullWidth
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundColor: '#FFFFFF',
          display: 'flex',
          marginBottom: 0,
          paddingTop: 0,
          marginTop: 0,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flex: 1
        }
      }}
    >

        <Typography variant='h3' style={{ textAlign: 'center', paddingTop: 140, paddingBottom:100}}>
            Hello
        </Typography>

        <Grid container >
            <Grid item xs={8} md={6} style={{marginLeft:'auto', marginRight: 'auto', width:'100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6' style={{ textAlign: 'center'}}>
                    What shall we call you?
                </Typography>
                {renderUserNameInput()}
            <Button
                disabled={handleContinueDisabled() || isLoadingClash}
                onClick={handleSignUp}
                variant='contained'
                color='primary'
                fullWidth
                size='small'
                disableElevation
                style={{ padding: 10, marginTop: 15, marginBottom: 15, borderRadius: 25, color: 'white' }}
            >
                Complete Registration
            </Button>
        </Grid>
        </Grid>
    </Dialog>
  )
}
