import React, { useState } from 'react'
import { Grid, Typography, Button, TextField, InputAdornment, IconButton, Tooltip, CircularProgress, Alert, AlertTitle } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import { TitleComponent } from '../../components/TitleComponent'
import {
  registerUser
} from '../../actions'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { validateEmail, cleanUsername, validateLowerCase, validateUpperCase, validateNumber, validateLength, validateSpecial} from '../../helpers/helpers'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CloseIcon from '@mui/icons-material/Close'
import {
  usernameExists
} from '../../helpers/API'
import { BiError, BiCheckShield } from 'react-icons/bi'
import { useTheme } from '@mui/material/styles'


export default function RegisterEmail(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [usernameClash, setUsernameClash] = useState(true)
  const [isLoadingClash, setIsLoadingClash] = useState(false)
  const [password, setPassword] = useState('')
  const [bannerText, setBannerText] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const theme = useTheme()

  const registerError = useSelector((state) => state.auth.error)

  const changeText = async(event) => {
    if (event.target.id === 'password') {
      setPassword(event.target.value)
    }
    else if (event.target.id === 'email') {
      setEmail(event.target.value)
    }
    else if (event.target.id === 'username') {
      setIsLoadingClash(()=>true)
      var cleaned = cleanUsername(event.target.value)
      setUsername(cleaned)
      if (cleaned.length > 6){
        let clash = await usernameExists(cleaned)
        setUsernameClash(clash)
      }
      setIsLoadingClash(()=>false)
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const getConditionsMet = () => {
    const conditions = [ validateLowerCase(password), validateNumber(password), validateUpperCase(password), validateSpecial(password)]
    return conditions.filter((e) => e === true).length
  }

  const handleContinueDisabled = () => {
      return !(validateEmail(email) && validateLength(password) && getConditionsMet() >= 3 )
  }

  const handleSignUp = async () => {
    setisLoading(()=>true)
    try {
      dispatch(
        registerUser({ email, password })
      )
      setBannerText('')
      setisLoading(()=>false)
    } catch (e) {
      setisLoading(()=>false)
      setBannerText(e.message)
      }
    }

  const renderEmailInput = () => {
    return(
      <TextField
        color='primary'
        autoComplete='email'
        onChange={changeText}
        fullWidth
        required
        size='small'
        id='email'
        label='Email'
        type='text'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <MailOutlineSharpIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          )
      }}
    />
    )
  }

  const renderUserNameInput = () => {
    return(
      <TextField
        color='primary'
        size='small'
        onChange={changeText}
        fullWidth
        required
        id='username'
        autoComplete='new-username'
        value={username}
        label='Username'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <PersonOutlineOutlinedIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
            {isLoadingClash ?
              <CircularProgress size={24}/>
              :
            <Tooltip arrow placement='top' title={username.length < 7 ? 'Username must be at least 7 characters' : (usernameClash ? 'This username is not available' : 'This username is available!')}>
              <span>
                {username.length !== 0 &&
                <IconButton size='small' disabled>
                  {
                    (username.length < 7 || usernameClash) ? <BiError style={{color: '#eed202', fontSize: 24}}/> : <BiCheckShield style={{color: theme.palette.primary.main, fontSize: 24}}/>
                  }
                </IconButton>
                }
              </span>
            </Tooltip>
          }
            </InputAdornment>
          )
        }}
    />
    )
  }
  const renderPasswordInput = () => {
    return(
      <TextField
        color='primary'
        size='small'
        onChange={changeText}
        fullWidth
        required
        id='password'
        type={showPassword ? 'text' : 'password'}
        value={password}
        autoComplete='password'
        label='Password'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <LockOutlinedIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>{' '}
            </InputAdornment>
          )
        }}
      />
    )
  }

  return (
    <Grid
      container
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
      }}
    >
      <TitleComponent
        title='Login'
        description='Login to access advanced features such as pantry tracking, recommended meals, and a customizable meal planning experience.'
      />
      <Grid
        item
        xs={11}
        sm={10}
        style={{ width: '100%', position: 'absolute', top: 10, display: 'flex', alignItems: 'flex-end' }}
      >
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <CloseIcon size={32} style={{ color: 'black' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>
          Join Salt'nPrep
        </Typography>
      </Grid>

      <Grid
        container
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item xs={8} md={4} style={{ width:'100%', paddingTop: 15, paddingBottom: 15}}>
            {bannerText}
            {registerError && <Alert icon={<ErrorIcon style={{color:'#f44336'}}/>} severity='error' color='error'>{registerError}</Alert>}
        </Grid>
        <Grid item xs={8} md={4} style={{ margin: 'auto', width: '100%', display: 'flex', flexDirection: 'column'}}>
            {renderEmailInput()}
            {renderPasswordInput()}
            {password && handleContinueDisabled() &&
              <Alert style={{marginTop:15, marginBottom: 15}} severity='warning' icon={<WarningIcon style={{color:'rgb(102, 60, 0)'}}/>} >
              <AlertTitle color='warning' style={{color: 'rgb(102, 60, 0)', fontWeight: 'bold'}}>Password Requirements</AlertTitle>
              <li style={{...validateLength(password) && {fontWeight: 'bold'}}}>At least 8 characters in length </li>
              <li style={{...getConditionsMet() >= 3 && {fontWeight: 'bold'}}}>Contain at least 3 of the following 4 types of characters</li>
              <li style={{...{paddingLeft: 25}, ...validateLowerCase(password) && {fontWeight: 'bold'}}}>Lower case letters (a-z)</li>
              <li style={{...{paddingLeft: 25}, ...validateUpperCase(password) && {fontWeight: 'bold'}}}>Upper case letters (A-Z)</li>
              <li style={{...{paddingLeft: 25}, ...validateNumber(password) && {fontWeight: 'bold'}}}>Numbers (ie. 0-9)</li>
              <li style={{...{paddingLeft: 25}, ...validateSpecial(password) && {fontWeight: 'bold'}}}>Special characters (!@#$%^&*)</li>
            </Alert>}
            <Button
                disabled={handleContinueDisabled() || isLoading}
                onClick={handleSignUp}
                variant='contained'
                color='primary'
                fullWidth
                size='small'
                disableElevation
                style={{ padding: 10, marginTop: 15, marginBottom: 15, borderRadius: 25, color: 'white' }}
            >
                {isLoading ? <CircularProgress size={20}/> : 'Register'}
            </Button>

        <Grid
            style={{
                paddingTop: 25,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
            <ChevronLeftIcon
                onClick={() => {
                props.setStep('login')
                }}
                size={20}
                style={{ color: '#E87B51', cursor: 'pointer' }}
            />
            <Typography
                style={{ fontSize: 16, color: '#E87B51', cursor: 'pointer' }}
                onClick={() => {
                props.setStep('login')
                }}
                disableRipple
            >
                All Sign In Options
            </Typography>

            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
        <Typography variant='body2' style={{ color: '#757575', textAlign: 'center' }}>
          Click "Continue" to agree to SaltnPrep's <Link to='/termsofservice'>Terms of Service</Link> and acknowledge
          that SaltnPrep's <Link to='/privacypolicy'>Privacy Policy</Link> applies to you.
        </Typography>
      </Grid>
    </Grid>
  )
}
