import auth0 from 'auth0-js'
import env_config from './../../env_config'

export default class Auth {

    auth0 = new auth0.WebAuth({
      domain: env_config.auth0.domain,
      clientID: env_config.auth0.clientId,
      audience: env_config.auth0.apiAudience,
      redirectUri: env_config.auth0.callbackUrl,
      scope: env_config.auth0.scope,
      responseType: 'token id_token'
    })

    login(email, password) {
        return new Promise((resolve, reject) => {
          this.auth0.login(
            { realm: env_config.auth0.realm, email, password },
            (err, authResult) => {
              if (err) {
                return reject(err);
              }
              this.setSession(authResult);
              return resolve();
            }
          );
        })
    }

    authorize(connection) {
      return new Promise((resolve, reject) => {
        this.auth0.authorize(
          { connection: connection},
          (err, authResult) => {
            if (err) {
              return reject(err);
            }
            this.setSession(authResult);
            return resolve();
          }
        );
      })
  }

    signup(email, password) {
      return new Promise((resolve, reject) => {
        this.auth0.signup(
          { connection: env_config.auth0.realm, email, password },
          (err, authResult) => {
            if (err) {
              return reject(err)
            }
            return resolve();
          }
        );
      })
  }

    logout() {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
        this.auth0.logout({
          returnTo: `${window.origin}/`
        })
      }

    handleAuthentication(){
        return new Promise(async(resolve, reject) => {
          this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
              this.setSession(authResult);
              // return resolve(authResult.idToken)
              return this.auth0.client.userInfo(authResult.accessToken, (error, result)=>{
                if(result){
                  return resolve({token: authResult.accessToken, profile: result});
                }
                return reject(error)
              })
            } else if (err) {
              return reject(err);
            }
            return reject
          });
        })
      }

    setSession = async(authResult) =>{
      // Set the time that the access tokenwill expire at
      let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      await localStorage.setItem('access_token', authResult.accessToken);
      await localStorage.setItem('id_token', authResult.idToken);
      await localStorage.setItem('expires_at', expiresAt);
    }

    isAuthenticated(){
      // Check whether the current time is past the
      // access token's expiry time
      return new Promise((resolve, reject) => {
        let expiresAt = JSON.parse(localStorage.getItem('expires_at', ''));
        if(new Date().getTime() < expiresAt){
          const accessToken = localStorage.getItem('access_token', '')
          return this.auth0.client.userInfo(accessToken, (error, result)=>{
            if(result){
              return resolve({token: accessToken, profile: result});
            }
            return reject(error)
          })
        }
        return reject()
      })
    }
}