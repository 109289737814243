import React from 'react'
import { Grid } from '@mui/material'

export default function PageWrapper(props) {
  return (
    <Grid container style={{ backgroundColor: '#ffffff', minHeight: 'calc(100vh - 69px)' }}>
      <Grid item xs={11} md={10} lg={9} style={{ marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
        {props.children}
      </Grid>
    </Grid>
  )
}
