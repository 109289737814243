import React, { useEffect, useState } from 'react'
import { Grid, Button, IconButton, InputBase } from '@mui/material'
import { IoIosSearch } from 'react-icons/io'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import { setCookie, getCookie } from '../../helpers/helpers'
import { useLocation } from 'react-router-dom'

import '../../styles/helpers.css'


const SearchInput = ({ handleQuerySubmit}) => {
    const { state } = useLocation()
    const [query, setQuery] = useState(getCookie('query') ? JSON.parse(getCookie('query')) : '')

    useEffect(()=>{
      if(state){
        if(state.query){
          setQuery(state.query)
          handleQuerySubmit(state.query)
          setCookie('query', JSON.stringify(state.query), 1)
      }
    }
  },[state])

    const handleSubmit = () =>{
      handleQuerySubmit(query)
      setCookie('query', JSON.stringify(query), 1)
    }
    const changeText = (event) => {
      setQuery(event.target.value)
    }
    const resetText = () => {
      setQuery('')
      handleQuerySubmit('')
      setCookie('query', '', 1)
    }
    const keyPress = (e) => {
      if (e.keyCode === 13) {
       handleSubmit()
      }
   }
    return (
      <Grid item xs={12} md={10} lg={8}>
      <div
        className='search-field'
        style={{ width: '100%', p: '5px 0px', mb: 1, display: 'flex', alignItems: 'center', height: 55, color: 'grey', backgroundColor:  'rgb(241, 245, 246)', borderRadius: 25, overflow: 'hidden', cursor: 'pointer' }}
      >
        <IconButton sx={{ pl: '15px' }} aria-label='menu' disabled>
          <IoIosSearch style={{ fontSize: 24, color: '#999c9c' }} />
        </IconButton>
        <InputBase
          value={query}
          onChange={changeText}
          onKeyDown={keyPress}
          sx={{ ml: 0.5, flex: 1}}
          placeholder='What are you craving today?'
          inputProps={{ 'aria-label': 'What are you craving today?' }}
        />
        { query &&
        <IconButton onClick={resetText} >
          <CancelSharpIcon className='icon-grow' style={{color: 'rgba(0, 0, 0, 0.6)'}}/>
        </IconButton>
        }
        <div color='primary' style={{ padding: '7.5px', paddingLeft: 0, display: 'flex'}} aria-label='directions'>
          <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleSubmit}
                style={{
                  height: 40,
                  borderRadius: 25
                }}
                sx={{display: { xs: 'none', sm: 'flex' }, p:{ xs: '6px 15px', sm: '6px 25px'}}}
              >
                Search
              </Button>
        </div>
        </div>
      </Grid>
    );
  }

  export default SearchInput