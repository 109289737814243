import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getAccount } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

export default function ManagementRoute(props) {
  const auth = useSelector((state) => state.auth.authenticated)
  const group = useSelector((state) => state.user.group)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchAccount() {
      await dispatch(getAccount())
      setIsLoading(false)
    }
    if (!group) {
      fetchAccount()
    }
  }, [group, auth])

  if ((!isLoading && !auth) || (auth && !group.includes('admin'))) {
     return <Navigate to='/login' replace />
   }
  if (group.includes('admin')){
    return props.children
  }
  return null
}
