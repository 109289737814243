import React from 'react'
import { Button, Grid } from '@mui/material'

const PinnedButton = ({onClick, title, disabled}) => {
    return(
        <Grid
        item
        xs={12}
        sx={{ display: { xs: 'block', sm: 'none' } }}
        style={{ backgroundColor: 'transparent', position: 'fixed', bottom: 10, left: 10, right: 10, zIndex: 1001 }}
      >
        <Button
          disabled={disabled}
          disableElevation
          onClick={onClick}
          variant='contained'
          color='primary'
          size='large'
          fullWidth
          style={{ opacity: 0.99, Index: 1001 }}
        >
          {title}
        </Button>
      </Grid>
    )
}

export default PinnedButton
