import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import NavBar from './components/NavBar'
import {
  getAuthState,
  getFavorites,
  getAccount,
  getPantry,
  getOrders,
  getCurrentPlan
} from './actions'
import { useDispatch, useSelector } from 'react-redux'

export default function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth.authenticated)
  const responseCode = useSelector((state) => state.user.response)
  const userName = useSelector((state) => (state.user.account.Username ?? ''));

  useEffect(()=>{
    if(responseCode === 500 || responseCode === 403){
      navigate('/welcome')
    }
  }, [responseCode])

  useEffect(() => {
    async function fetchAuth() {
      dispatch(getCurrentPlan())
      await dispatch(getAuthState())
    }
    if (!auth) {
      fetchAuth()
    }
    if (auth && !userName ) {
      dispatch(getAccount())
      dispatch(getPantry())
      dispatch(getFavorites())
      dispatch(getOrders())
    }
  }, [auth, userName])

  return (
    <Grid
      id='root'
      style={{
        paddingTop: 60,
        minHeight: 'calc(100vh - 60px)'
      }}
    >
        <NavBar />
        <main style={{ display: 'flex', flexGrow: 1, alignItems: 'flex-start'}}>
          <Outlet/>
        </main>
    </Grid>
  )
}
