import React, { useState, useEffect } from 'react'
import { Typography, Grid, Avatar, IconButton, Button } from '@mui/material'
import { quickAddMeal, deleteMeal, delFavorite, addFavorite } from '../actions'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import DynamicImage from './DynamicImage'
import placeholder from '../media/placeholder.jpg'
import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded'
import env_config from '../env_config'
import {IoMdHeartEmpty, IoMdHeart} from 'react-icons/io'

import '../styles/helpers.css'

const Meal = ({meal, mealID, ingredients = [], name, chefName, chefID, mode = 'default', disabled = false, minWidth = false }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const location = useLocation()
  const pantry = useSelector((state) => state.planner.pantry)
  const plan = useSelector((state) => (state.planner.plan ? state.planner.plan : {}))
  const [pantryCount, setPantryCount] = useState(0)
  // const [missingIngredients, setMissingIngredients] = useState(ingredients)
  const [inPlan, setInPlan] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const [disableFavoriteButton, setDisableFavoriteButton] = useState(true)
  const favorites = useSelector((state) => state.planner.favorites)
  const auth = useSelector((state) => state.auth.authenticated)

  const goToRecipe = () => {
    if(!disabled){
      navigate(`/recipe/${name}/${mealID}`, {state: { from: location.pathname}})
    }
  }


  const goToChefPage = () => {
    if(!disabled){
      navigate(`/chef/${chefName}`)
    }
  }

  useEffect(() => {
    var isInPlan = mealID in plan
    setInPlan(isInPlan)
  }, [plan, mealID])

  useEffect(() => {
      var count = 0
      var tempMissingIngredients = []
      ingredients.forEach(function(i){
          var found = false
          pantry.forEach(function(p){
            if(i['FamilyID'] === p['FamilyID']){
              count++
              found = true
            }
          })
          if (!found){
            tempMissingIngredients.push(i)
          }
      })
      // setMissingIngredients(tempMissingIngredients)
      setPantryCount(count)
  }, [pantry])

  useEffect(() => {
    /*
      Checks to see if the meal is in the users favorites. Runs once at load
    */
    var fav = false
    for (var i = favorites.length - 1; i >= 0; i--) {
      if (favorites[i]['Meal']['ID'] === mealID) {
        fav = true
        break
      }
    }
    setIsFavorite(fav)
    setDisableFavoriteButton(false)
  }, [favorites, mealID])

  const toggleMeal = () => {
    if (inPlan) {
      setInPlan(false)
      dispatch(deleteMeal(pantry, plan, mealID))
    } else {
      setInPlan(true)
      dispatch(quickAddMeal(pantry, mealID, plan))
    }
  }
  const toggleFavorite = () => {
    if (auth && !disableFavoriteButton) {
      if (isFavorite) {
        dispatch(delFavorite(mealID))
        setIsFavorite(false)
        // setFavoriteCount((c)=> c - 1)
        setDisableFavoriteButton(true)
      } else {
        dispatch(addFavorite(meal))
        setIsFavorite(true)
       // setFavoriteCount((c)=> c + 1)
        setDisableFavoriteButton(true)
      }
    }

    else if (!auth) {
      navigate('/login', {state: { from: location.pathname}})
    }
  }

  const renderButton = () => {
    if (mode === 'see'){
      return(<Button onClick={goToRecipe} disabled={disabled} fullWidth disableElevation variant='outlined' color='primary' size='small' sx={{padding: 0, borderColor: 'rgba(209, 214, 220)'}}>See the recipe</Button>)
    }
    else {
      return(<Button onClick={toggleMeal} disabled={disabled} fullWidth disableElevation variant={inPlan ? 'contained' : 'outlined'} color='primary' size='small' sx={{padding: 0, borderColor: 'rgba(209, 214, 220)'}}> {inPlan ? 'Remove Meal' : 'Add Meal to Cart' }</Button>)
    }
  }

  return (
    <Grid
      item
      xs={6}
      sm={6}
      md={minWidth ? 5 : 4}
      lg={minWidth ? 4 : 3}
      style={{ display: 'block'}}
      sx={{ paddingRight: { xs: 1.5, sm: 2 }, paddingBottom: { xs: 1.5, sm: 2} }}
    >
      <Grid className={!disabled && 'recipe-card-bg'} sx={{...minWidth && {minWidth: { xs: 180, sm: 235, md: 200, lg: 190}}}} style={{...{position: 'relative', display: 'flex', flexDirection: 'column', border: '1px solid rgba(209, 214, 220)', overflow: 'hidden', borderRadius: 10},... disabled ? {minWidth: 200} : { cursor: 'pointer' }}}>
       <div onClick={goToRecipe}>
          <DynamicImage
            style={{display: 'table', height: '100%', width: '100%', objectFit: 'cover'}}
            alt={name}
            placeholder={placeholder}
            bucket={'protected/' + chefID}
            url={mealID + '300x200'}
          />
       </div>

      {pantryCount > 0 &&
      <div onClick={goToRecipe} style={{position:'absolute', top: 7.5, right: 7.5, borderRadius: 7.5, padding: '1px 6px', border:'1px solid #d8daec', backgroundColor: 'rgba(255,255,255)', display: 'flex', alignItems: 'center'}}>
        <KitchenRoundedIcon style={{fontSize: 16, color: theme.palette.primary.main, marginRight: 5}}/>
        <Typography variant='body2' style={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{pantryCount} / {ingredients.length}</Typography>
      </div>
      }

       <div style={{position: 'relative', padding: 10, paddingTop: 15}}>
        <div
          style={{
            position: 'absolute',
            top: -47,
            left: -5,
            zIndex: 4
          }}
        >
          <div style={{position: 'relative', top: 0, left: 0 }} onClick={goToChefPage}>
            <SVG/>
            {!disabled &&
            <Avatar
              style={{ position: 'absolute', top: '38px', left: '50%', transform: 'translate(-50%, -50%)', height: '21px', width: '21px', backgroundColor: '#E87B51' }}
              alt={chefName}
              src={chefID === undefined ? '' : `https://${env_config.s3.BUCKET}.s3.amazonaws.com/protected/${chefID}/avatar.jpg`}
            />
            }
          </div>
        </div>

        <Grid item xs={12} style={{zIndex: 5}}>
          <div onClick={goToRecipe} className='twolinesContainer'>
            <Typography variant='body1' className='twolinesText' style={{ textAlign: 'left', fontWeight: 'bold' }}>
              {name}
            </Typography>
          </div>
          <div style={{width:'100%', padding: 10}}/>
          {/*
          <div onClick={goToRecipe} className='twolinesShortContainer'>
            <Typography variant='body2' className='twolinesShortText' style={{ textAlign: 'left', fontSize: 13, color:'#758c98', paddingTop: 7.5}}>
              {missingIngredients.map(ing => ing['Name']).join(', ')}
            </Typography>
          </div>
          */
          }

          <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'center', marginTop: 15 }}>
            {renderButton()}
            <IconButton
              size='small'
              onClick={toggleFavorite}
              disabled={disableFavoriteButton || disabled}
              sx={{ ml: 1}}

            >
              {isFavorite ? (
                <IoMdHeart style={{fontSize: 24, color: theme.palette.primary.main }} />
              ) : (
                <IoMdHeartEmpty className='icon' style={{color: 'rgba(209, 214, 220)', fontSize: 24}}/>
                )
              }
            </IconButton>
        </Grid>

        </Grid>
      </div>

      </Grid>

    </Grid>
  )
}
export default Meal


const SVG = () => {
  return(
    <svg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg' className='svg-bg' style={{height: '70px', width: '70px', position: 'relative', top: 0, left: 0, fill: 'white'}}>
      <path d='M 490.246 341.509 L 489.604 486.595 L 7.276 488.785 L 8.408 342.519 L 34.69 342.519 C 79.223 342.519 118.647 310.241 140.548 260.802 C 142.009 257.778 142.738 255.757 144.198 252.734 C 166.1 204.304 204.794 171.016 250.056 171.016 C 294.59 171.016 333.283 203.302 355.185 250.713 C 355.916 252.734 356.645 254.754 357.375 255.757 C 378.547 307.21 418.7 341.509 464.694 341.509 C 473.951 341.509 481.215 341.509 490.246 341.509 Z'/>
    </svg>
  )
}

/*         {pantryCount > 0 &&
        <div
          sx={{display: 'none'}}
          style={{
            position: 'absolute',
            top: -25,
            right: 5,
            zIndex: 4,
            borderRadius: 7.5, padding: '1px 10px', border:'1px solid #d8daec', backgroundColor: 'rgba(255,255,255)', display: 'flex', alignItems: 'center'
          }}
        >
           <KitchenRoundedIcon style={{fontSize: 16, color: theme.palette.primary.main, marginRight: 5}}/>
          <Typography variant='body2' style={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{pantryCount} / {ingredients.length}</Typography>
        </div>}*/