import { FETCH_PLAN_SUCCESS, FETCH_HISTORY_SUCCESS, FETCH_FAVORITES_SUCCESS, FETCH_PANTRY_SUCCESS } from './types'
import configureAxios from '../../helpers/configureAxios';
import { getMealByID } from '../../helpers/API'
import ReactGA from 'react-ga4'

const api = configureAxios();

function receiveOrders(orders) {
  return {
    type: FETCH_HISTORY_SUCCESS,
    orders: orders
  }
}

function receiveCurrentPlan(planJson) {
  if (planJson) {
    return {
      type: FETCH_PLAN_SUCCESS,
      plan: planJson
    }
  } else {
    return {
      type: FETCH_PLAN_SUCCESS,
      plan: {}
    }
  }
}

function receiveFavorites(json) {
  return {
    type: FETCH_FAVORITES_SUCCESS,
    favorites: json
  }
}

function receivePantry(pantry, plan) {
  return {
    type: FETCH_PANTRY_SUCCESS,
    pantry: pantry,
    plan: plan
  }
}

// Verified Works with new API
// TO DO: Dispatch early to change the number of items in the cart, placeholder status, then proper add
export const quickAddMeal = (pantry, id, plan) => {
  if (plan !== undefined) {
    return async (dispatch) => {
      getMealByID(id)
      .then((Meal) => {
        var servings = parseInt(Meal['Servings'])
        for (var i = 0; i < Meal['FoundationIngredientList'].length; i++) {
          Meal['FoundationIngredientList'][i]['Display'] = true
          Meal['FoundationIngredientList'][i]['Message'] = 'In Cart'
        }
        if (Meal['ID'] in plan) {
          servings = plan[Meal['ID']]['Servings']
          for (var x = 0; x < plan[Meal['ID']]['Ingredients'].length; x++) {
            Meal['FoundationIngredientList'][x]['Display'] = plan[Meal['ID']]['Ingredients'][x]['Display']
            Meal['FoundationIngredientList'][x]['Message'] = plan[Meal['ID']]['Ingredients'][x]['Message']
          }
        }
        for (i = pantry.length - 1; i >= 0; i--) {
          var pantryAmount = parseFloat(pantry[i]['MetricWeight'])
          var indexes = []
          //subtract all THIS meals use of the ingredient.
          for (var j = 0; j < Meal['FoundationIngredientList'].length; j++) {
            if (Meal['FoundationIngredientList'][j]['FamilyID'] === pantry[i]['FamilyID']) {
              Meal['FoundationIngredientList'][j]['Display'] = true
              Meal['FoundationIngredientList'][j]['Message'] = 'In Pantry'
              pantryAmount -=
                (servings / Meal['Servings']) * parseFloat(Meal['FoundationIngredientList'][j]['MetricWeight'])
              indexes.push(j)
            }
          }
          //subtract all OTHER meals from plan use of the ingredient
          for (var otherMeals in plan) {
            if (otherMeals !== Meal['ID']) {
              for (x = plan[otherMeals]['Ingredients'].length - 1; x >= 0; x--) {
                if (plan[otherMeals]['Ingredients'][x]['FamilyID'] === pantry[i]['FamilyID']) {
                  pantryAmount -=
                    (plan[otherMeals]['Servings'] / plan[otherMeals]['RecipeServings']) *
                    parseFloat(plan[otherMeals]['Ingredients'][x]['MetricWeight'])
                }
              }
            }
            if (pantryAmount < 0) {
              for (var z = 0; z < indexes.length; z++) {
                Meal['FoundationIngredientList'][indexes[z]]['Display'] = true
                Meal['FoundationIngredientList'][indexes[z]]['Message'] = 'May Need More'
              }
            }
          }
        }
        ReactGA.event({category: 'plan', action: 'add_meal_to_cart'})
        dispatch(addMeal(pantry, plan, Meal, servings, Meal['FoundationIngredientList']))
      })
    }
  }
  return ( -1 )
}

// Verified Works with new API
export const getFavorites = () => {
  return async (dispatch) => {
    try {
      await api.get('/favorites/meal', {})
      .then(response => response.data.Favorites)
      .then((favorites) => {
        if (favorites === null) {
          dispatch(receiveFavorites([]))
        } //Null Favorites is returned. Should return [] if null.
        else {
          dispatch(receiveFavorites(favorites))
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Verified Works with new API
export const getPantry = () => {
  return async (dispatch) => {
    try {
      await api.get('/pantry', {})
        .then(response => response.data.Pantry)
        .then((pantry) => {
        var userPlan = {}
        if (pantry === null) {
          userPlan = JSON.parse(localStorage.getItem('userPlan'))
          var tempPlan = checkPantry([], userPlan)
          dispatch(receivePantry([], tempPlan))
        } //Null Favorites is returned. Should return [] if null.
        else {
          userPlan = JSON.parse(localStorage.getItem('userPlan'))
          tempPlan = checkPantry(pantry, userPlan)
          dispatch(receivePantry(pantry, tempPlan))
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Verified Works with new API
export const delPantry = (IngredientId) => {
  return async (dispatch) => {
    try {
      await api.delete(
        `/pantry/${IngredientId}`
      ).then((response) => {
        dispatch(getPantry())
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Verified Works with new API
export const postPantry = (Ingredient) => {
  return async (dispatch) => {
    try {
      await api.post(
        '/pantry',
         Ingredient
      ).then((response) => {
        dispatch(getPantry())
      })
    } catch (error) {
      console.log(error)
    }
  }
}


// Verified Works with new API
export const delOrders = (orderId) => {
  return async (dispatch) => {
    try {
      await api.delete(`/history/${orderId}`)
      .then((response) => {
        dispatch(getOrders())
      })
    } catch (error) {
      console.log(error.message)
    }
  }
}


// Verified Works with new API
export const delFavorite = (mealid) => {
  return async (dispatch) => {
    try {
      await api.delete(
        `/favorites/meal/${mealid}`
      ).then((response) => {
        dispatch(getFavorites())
      })
    } catch (e) {
      console.log({e})
    }
  }
}


// Verified Works with new API
export const addFavorite = (meal) => {
  var body = { Meal: meal }
  return async (dispatch) => {
    try {
      await api.post(
        '/favorites/meal/',
        body
      ).then((response) => {
        dispatch(getFavorites())
      })
    } catch (error) {
      console.log(error.message)
    }
  }
}


export const addMeal = (pantry, plan, meal, servings, newIngredients) => {
  if (plan !== undefined && meal !== undefined) {
    var tempPlan = plan
    var ingredients = []
    for (var i = 0; i < newIngredients.length; i++) {
      ingredients.push({
        ID: newIngredients[i]['ID'],
        FamilyID: newIngredients[i]['FamilyID'],
        Header: newIngredients[i]['Header'],
        PrepOptions: newIngredients[i]['PrepOptions'] ? newIngredients[i]['PrepOptions'] : false,
        Name: newIngredients[i]['Name'],
        Display: newIngredients[i]['Display'],
        Message: newIngredients[i]['Message'],
        UsMeasurements: newIngredients[i]['UsMeasurements'],
        UsStandardAmount: newIngredients[i]['UsMeasurements'][0]['Amount'],
        UsStandardUnit: newIngredients[i]['UsMeasurements'][0]['Unit'],
        MetricWeight: newIngredients[i]['MetricWeight'],
        MetricVolume: newIngredients[i]['MetricVolume'],
        Category: newIngredients[i]['Category']
      })
    }
    tempPlan[meal['ID']] = {
      ChefName: meal['ChefName'],
      ChefID: meal['ChefID'],
      ID: meal['ID'],
      Name: meal['Name'],
      Image: meal['Image'],
      RecipeServings: meal['Servings'],
      Servings: servings,
      Ingredients: ingredients
    }
    tempPlan = checkPantry(pantry, tempPlan)
    ReactGA.event({category: 'plan', action: 'add_meal_to_cart'})
    return async (dispatch) => {
      dispatch(setCurrentPlan(tempPlan))
    }
  } else {
    return async (dispatch) => {
      dispatch(setCurrentPlan(plan))
    }
  }
}

export const forceUpdate = (pantry, plan) => {
  var tempPlan = checkPantry(pantry, plan)
  return async (dispatch) => {
    dispatch(setCurrentPlan(tempPlan))
  }
}

function checkPantry(pantry, plan) {
  // Reset Everything First
  for (var mealid in plan) {
    for (var x = 0; x < plan[mealid]['Ingredients'].length; x++) {
      if (plan[mealid]['Ingredients'][x]['Message'] !== 'In Cart') {
        plan[mealid]['Ingredients'][x]['Display'] = false
        plan[mealid]['Ingredients'][x]['Message'] = ''
      }
    }
  }
  //Might need to loop through pantry and get aggregate pantry amount first
  var pantryAggregate = {}
  for (var y = pantry.length - 1; y >= 0; y--) {
    if (pantry[y]['FamilyID'] in pantryAggregate){
      pantryAggregate[pantry[y]['FamilyID']] += pantry[y]['MetricWeight']
    }
    else {
      pantryAggregate[pantry[y]['FamilyID']] = pantry[y]['MetricWeight']
    }
  }
  for (var key in pantryAggregate) {
    var pantryAmount = parseFloat(pantryAggregate[key])
    var indexes = []
    for (var meal in plan) {
      for (x = 0; x < plan[meal]['Ingredients'].length; x++) {
        if (plan[meal]['Ingredients'][x]['FamilyID'] === key) {
          indexes.push({ mealid: meal, index: x })
          pantryAmount -=
            (plan[meal]['Servings'] / plan[meal]['RecipeServings']) *
            parseFloat(plan[meal]['Ingredients'][x]['MetricWeight'])
        }
      }
      for (var z = 0; z < indexes.length; z++) {
        if (
          pantryAmount < 0 &&
          plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Message'] !== 'Removed'
        ) {
          plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Display'] = true
          plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Message'] = 'May Need More'
        } else if (plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Message'] !== 'Removed') {
          plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Display'] = true
          plan[indexes[z]['mealid']]['Ingredients'][indexes[z]['index']]['Message'] = 'In Pantry'
        }
      }
    }
  }

  return plan
}

export const updateDisplay = (plan, familyID, display, message) => {
  for (var id in plan) {
    for (var i = 0; i < plan[id]['Ingredients'].length; i++) {
      if (plan[id]['Ingredients'][i]['FamilyID'] === familyID) {
        plan[id]['Ingredients'][i]['Display'] = display
        plan[id]['Ingredients'][i]['Message'] = message
      }
    }
  }
  return async (dispatch) => {
    dispatch(setCurrentPlan(plan))
  }
}

export const updateServings = (pantry, plan, mealid, newServings) => {
  var tempPlan = JSON.parse(JSON.stringify(plan))
  if (newServings !== undefined) {
    tempPlan[mealid]['Servings'] = newServings
  }
  tempPlan = checkPantry(pantry, tempPlan)
  return async (dispatch) => {
    dispatch(setCurrentPlan(tempPlan))
  }
}
export const deleteMeal = (pantry, plan, mealid) => {
  var tempPlan = plan
  delete tempPlan[mealid]
  tempPlan = checkPantry(pantry, plan)
  ReactGA.event({category: 'plan', action: 'remove_meal_from_cart'})

  return async (dispatch) => {
    dispatch(setCurrentPlan(tempPlan))
  }
}

export const setCurrentPlan = (userPlan) => {
  try {
    localStorage.setItem('userPlan', JSON.stringify(userPlan))
    return async (dispatch) => {
      dispatch(getCurrentPlan())
    }
  } catch (error) {
    console.log(error)
  }
  return (-1)
}

export const getCurrentPlan = () => {
  try {
    var userPlan = JSON.parse(localStorage.getItem('userPlan'))
    if (!userPlan){
      return async (dispatch) => {
        dispatch(setCurrentPlan({}))
      }
    }
    else {
      return async (dispatch) => {
        dispatch(receiveCurrentPlan(userPlan))
      }
    }
  } catch (e) {
    console.log(e)
  }
  return (-1)
}


// Verified Works with new API
export const postOrders = (order) => {
  return (dispatch) => {
    try {
      api.post(
        '/history',
        order
      ).then((response) => {
        dispatch(getOrders())
      })
    } catch (error) {
      console.log(error)
    }
  }
}


// Verified Works with new API
export const getOrders = (pageNumber = 1) => {
  return async (dispatch) => {
    try {
      await api.get('/history?pageNumber=1', {})
      .then(data => data.data.History)
      .then((history) => {
        if (history === null) {
          dispatch(receiveOrders({}))
        } else {
          var sorted = {}
          for (var i = 0; i < history.length; i++) {
            var date = Date.parse(history[i]['CreatedDateTime'])
            var foundMatch = false
            for (var time in sorted) {
              //orders within a one minute window are in the same order.
              if (time + 60000 > date && time - 60000 < date) {
                foundMatch = true
                sorted[time].push(history[i])
              }
            }
            if (!foundMatch) {
              sorted[date] = [history[i]]
            }
          }
          dispatch(receiveOrders(sorted))
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const resetPlan = () => {
  return async (dispatch) => {
    dispatch(setCurrentPlan({}))
  }
}
