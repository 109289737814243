import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'

import SearchInput from './SearchInput'
import FilterButton from '../FilterButton'
import { DifficultyEnum, MealCategoriesEnum } from '../../helpers/helpers'
import { AiOutlineFire } from 'react-icons/ai'
import { useTheme } from '@mui/material/styles'
import { IoMdHeartEmpty } from 'react-icons/io'
import { GiForkKnifeSpoon } from 'react-icons/gi'
import { BsBook, BsPerson } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded'
import PantryModal from '../PantryModal'

const SearchBar = ({handleQuerySubmit, handleDifficulty, selectedDifficulty, handleCourse, selectedCourse, selectedMode, handleMode, selectedFollowing, handleFollowing}) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const following = useSelector((state) => state.user.following ?? [])
    const auth = useSelector((state) => state.auth.authenticated)
    const [pantryModal, setPantryModal] = useState(false)

    const handlePantry = () => {
        if(auth){
            setPantryModal(true)
        }
        else{
            navigate('/login')
        }
    }

    return(
        <Grid container>
            <Grid item xs={11} md={12} sx={{mb: 3, marginLeft:'auto', marginRight:'auto'}}>
                <SearchInput handleQuerySubmit={handleQuerySubmit}/>
            </Grid>
            <Grid className='noscroll' item xs={12} sx={{paddingLeft: {xs: '4.13%', md:0 }}} style={{flexWrap: 'nowrap', margin: 'auto', display: 'flex', flexGrow: 1, overflowX: 'scroll' }}>
                <FilterButton title={'Pantry'} onToggleOn={handlePantry} icon={<KitchenRoundedIcon style={{color: theme.palette.primary.main, fontSize: 16}} /> } />
                <FilterButton title={'Course'} icon={<GiForkKnifeSpoon style={{color: theme.palette.primary.main, fontSize: 16}} />} menu={true} items={MealCategoriesEnum} setSelectedItems={handleCourse} selectedItems={selectedCourse} isSelected={selectedCourse.length}/>
                <FilterButton title={'Is Favorite'} isSelected={selectedMode === 'Favorites'} onToggleOn={()=>{handleMode('Favorites')}} onToggleOff={()=>{handleMode('')}} icon={<IoMdHeartEmpty style={{color: theme.palette.primary.main, fontSize: 16}} /> } />
                <FilterButton title={'My Cookbook'} isSelected={selectedMode === 'Cookbook'} onToggleOn={()=>{handleMode('Cookbook')}} onToggleOff={()=>{handleMode('')}} icon={<BsBook style={{color: theme.palette.primary.main, fontSize: 16}} /> } />
                <FilterButton title={'Chefs'} disabled={!following.length} icon={<BsPerson style={{color: theme.palette.primary.main, fontSize: 16}}/>} menu={true} items={following.map(a => a.Username)} selectOne={true} setSelectedItems={handleFollowing} selectedItems={selectedFollowing} isSelected={selectedFollowing.length}/>
                <FilterButton title={'Difficulty'} icon={<AiOutlineFire style={{color: theme.palette.primary.main, fontSize: 16}}/>} menu={true} items={DifficultyEnum} setSelectedItems={handleDifficulty} selectedItems={selectedDifficulty} isSelected={selectedDifficulty.length}/>
            </Grid>
            <PantryModal open={pantryModal} onClose={()=>{setPantryModal(false)}}/>
      </Grid>
    )
}
export default SearchBar

/*
    import DifficultyTag from '../components/DifficultyTag'
    <DifficultyTag label='Beginner' selected={true} color={'rgba(2,192,118,1)'} lightColor={'rgba(2,192,118,0.2)'} />
    <DifficultyTag label='Intermediate' selected={false} color={'rgba(240,185,11,1)'} lightColor={'rgba(240,185,11,0.2)'}/>
    <DifficultyTag label='Advanced' selected={true} color={'rgba(217,48,78,1)'} lightColor={'rgba(217,48,78,0.2)'}/>
*/