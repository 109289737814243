import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import {Grid, CircularProgress} from '@mui/material'
import App from './App'
import ProtectedRoute from './components/Login/ProtectedRoute'
import ManagementRoute from './components/Login/ManagementRoute'

import Login from './components/Login/Login'
import Planner from './components/Planner/Planner'
import MyMeals from './components/Account/MyMeals'
import Favorites from './components/Account/Favorites'
import Search from './components/Search/Search'
import Stats from './components/Account/Stats'
import CookPage from './components/CookPage'
import Theme from './theme'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import reducers from './actions/reducers'
import { PostAuthenticate } from './components/Login/PostAuthenticate'
import OnBoarding from './components/Login/OnBoarding'

import env_config from './env_config'
import ReactGA from 'react-ga4'

ReactGA.initialize(env_config.ga.id)

const ErrorPage = React.lazy(() => import('./components/Error' /* webpackChunkName: "Recipe" */))

const Recipe = React.lazy(() => import('./components/Recipe' /* webpackChunkName: "Recipe" */))
const Orders = React.lazy(() => import('./components/Account/Orders' /* webpackChunkName: "Orders" */))
const HelpCenter = React.lazy(() => import('./components/Account/HelpCenter' /* webpackChunkName: "HelpCenter" */))
const TokenPage = React.lazy(() => import('./components/TokenPage' /* webpackChunkName: "TokenPage" */))

const AddIngredient = React.lazy(() => import('./components/Account/AddIngredient' /* webpackChunkName: "AddIngredient" */))
const EditProfile = React.lazy(() => import('./components/Account/EditProfile' /* webpackChunkName: "EditProfile" */))
const EditChefPage = React.lazy(() => import('./components/Account/EditChefPage' /* webpackChunkName: "EditChefPage" */))
const ChefPage = React.lazy(() => import('./components/ChefPage' /* webpackChunkName: "ChefPage" */))

const TermsOfService = React.lazy(() => import('./components/TermsOfService' /* webpackChunkName: "TermsOfService" */))
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */))
const Pantry = React.lazy(() => import('./components/Pantry' /* webpackChunkName: "Pantry" */))
const CreateMeal = React.lazy(() => import('./components/Account/CreateMeal' /* webpackChunkName: "CreateMeal" */))
const Landing = React.lazy(() => import('./components/Landing/Landing' /* webpackChunkName: "Landing" */))
const Dashboard = React.lazy(() => import('./components/Management/Dashboard' /* webpackChunkName: "Dashboard" */))
const Management = React.lazy(() => import('./components/Management/Management' /* webpackChunkName: "Management" */))


const store = createStore(reducers, {}, applyMiddleware(ReduxThunk))

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <Suspense fallback={<Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}><CircularProgress color='primary'/></Grid>}>
        <BrowserRouter>
          <Routes>
            <Route path='/management/' element={<ManagementRoute><Dashboard/></ManagementRoute>}>
              <Route path='meals' element={<MyMeals basePath='/management/' getMealsEndpoint='/meal' />} />
              <Route path='meals/edit/:mealID' element={<ProtectedRoute><CreateMeal getMealsEndpoint='/meal'/></ProtectedRoute>} />
              <Route path='ingredients' element={<Management/>} />
              <Route path='*' element={<ErrorPage />} />
            </Route>
            <Route path='/authenticate' element={<PostAuthenticate />} />
            <Route path='/' element={<App />}>
              <Route path='' element={<Landing />} />
              <Route path='login' element={<Login />} />
              <Route path='welcome' element={<OnBoarding />} />
              <Route path='token' element={<TokenPage />} />
              <Route path='search' element={<Search />} />
              <Route path='plan' element={<Planner />} />
              <Route path='termsofservice' element={<TermsOfService />} />
              <Route path='privacypolicy' element={<PrivacyPolicy />} />
              <Route path='recipe/:Name/:ID' element={<Recipe />} />
              <Route path='chef/:chefName' element={<ChefPage />} />
              <Route path='help' element={<HelpCenter />} />
              <Route path='cook' element={<ProtectedRoute><CookPage /></ProtectedRoute>} />
              <Route path='profile' element={<ProtectedRoute><EditChefPage /></ProtectedRoute>} />
              <Route path='meals' element={<ProtectedRoute><MyMeals basePath='/' getMealsEndpoint='/meal'/></ProtectedRoute>} />
              <Route path='meals/create' element={<ProtectedRoute><CreateMeal getMealsEndpoint='/meal'/></ProtectedRoute>} />
              <Route path='meals/edit/:mealID' element={<ProtectedRoute><CreateMeal getMealsEndpoint='/meal'/></ProtectedRoute>} />
              <Route path='meals/drafts/edit/:draftID' element={<ProtectedRoute><CreateMeal getMealsEndpoint='/usermeal'/></ProtectedRoute>} />
              <Route path='stats' element={<ProtectedRoute><Stats /></ProtectedRoute>} />
              <Route path='orders' element={<ProtectedRoute><Orders/></ProtectedRoute>} />
              <Route path='favorites' element={<ProtectedRoute><Favorites/></ProtectedRoute>} />
              <Route path='settings' element={<ProtectedRoute><EditProfile/></ProtectedRoute>} />
              <Route path='pantry' element={<ProtectedRoute><Pantry/></ProtectedRoute>} />
              <Route path='pantry/editor' element={<ProtectedRoute><AddIngredient/></ProtectedRoute>} />
              <Route path='*' element={<ErrorPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
reportWebVitals()
