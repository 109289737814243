import React from 'react'
import ButtonUnstyled from '@mui/core/ButtonUnstyled'
import { styled } from '@mui/system'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { Avatar } from '@mui/material'
import env_config from './../env_config'

const CustomButtonRoot = styled('button')`
  display: flex;
  background-color: white;
  align-items: center;
  border: 1px solid #d8daec;
  border-radius: 21px;
  color: #adb5c1;
  padding: 5px 5px 5px 12px;
  cursor: pointer;
  transition: 0.2s ease;
  -webkit-transition: box-shadow 0.2s ease;
  &:hover {
    // background-color: #e87b5133;
    box-shadow: 0 2px 4px rgba(0,0,0,0.18);
  }
`

function CustomButton(props) {
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />
}

export default function AccountButton(props) {
  const theme = useTheme()
  const username = useSelector((state) => (state.user.account.Username === undefined ? '' : state.user.account.Username))
  const userID = useSelector((state) => state.user.account.ID)
  const avatarHash = useSelector((state) => (state.user.avatarHash === undefined ? '' : state.user.avatarHash))

  return (
    <CustomButton onClick={props.onClick} style={{...props.active && {boxShadow: '0 2px 4px rgba(0,0,0,0.18)'}}}>
        <MenuIcon style={{fontSize: 18, marginRight: 8}}/>
        <Avatar
          style={{ height: 30, width: 30, backgroundColor: theme.palette.primary.main }}
          alt={username}
          src={userID === undefined ? '' : `https://${env_config.s3.BUCKET}.s3.amazonaws.com/protected/${userID}/avatar.jpg?${avatarHash}`}
        />
    </CustomButton>
  )
}
