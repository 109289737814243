export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS'
export const FETCH_MEAL_SUCCESS = 'FETCH_MEAL_SUCCESS'
export const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS'
export const SET_MEAL_SUCCESS = 'SET_MEAL_SUCCESS'
export const ON_MEAL_LOAD = 'ON_MEAL_LOAD'
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS'
export const ON_MEAL_REMOVE = 'ON_MEAL_REMOVE'
export const ON_MEAL_ADD = 'ON_MEAL_ADD'
export const FETCH_AUTH_STATE = 'FETCH_AUTH_STATE'
export const RESET = 'RESET'
export const FETCH_PANTRY_SUCCESS = 'FETCH_PANTRY_SUCCESS'
export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO'
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR'

export const FETCH_IMAGE_SUCCESS = 'FETCH_IMAGE_SUCCESS'
export const FETCH_IMAGE_ERROR = 'FETCH_IMAGE_ERROR'

export const AUTH_USER = 'AUTH_USER'

export const UNAUTH_USER = 'UNAUTH_USER'
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const CLEARDOWN = 'CLEARDOWN'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_IN_PROGRESS = 'FORGOT_PASSWORD_IN_PROGRESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
