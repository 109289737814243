import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Typography,
  Grid,
  Button,
  Modal,
  IconButton,
  Drawer,
  Divider,
  Menu,
  MenuItem,
  Avatar,
  Badge
} from '@mui/material'
import Logo from '../media/OrangeLogo.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { truncateText } from '../helpers/helpers'
import { logout } from '../actions'
import ConfirmActionModal from './ConfirmActionModal'
import { useTheme } from '@mui/material/styles'
import { BsBasketFill, BsSearch, BsGear, BsChatSquareDots, BsReceiptCutoff, BsDoorClosed, BsBook} from 'react-icons/bs'
import { BiBookHeart, BiIdCard, BiStats } from 'react-icons/bi'
import { ImSpoonKnife } from 'react-icons/im'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded'
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined'
import AccountButton from './AccountButton'
import ExpandableSearchBar from '../components/ExpandableSearchBar'
import env_config from '../env_config'

const iconStyle = {
  color: '#232323DD',
  fontSize: 20
}

const StyledMenuItem = (props) => {
  return (
    <MenuItem
      selected={props.selected}
      style={{ padding: '12px 15px', borderRadius: 15, margin: '0px 10px'}}
      disabled={props.disabled}
      dense
      onClick={() => {
        props.onClick()
        props.onClose()
      }}
      onClose={props.onClose}
    >
      {props.icon} <Typography variant='body1' sx={{ pl: 3}}>{props.title}</Typography>
      <sup style={{ color: '#3f9e83', fontSize: 12, position: 'relative', bottom: 5, left: 2.5 }}>{props.sup}</sup>
    </MenuItem>
  )
}

export default function NavBar() {
  const [modal, setModal] = useState(false)
  const [menu, setMenu] = useState(null)
  const [logoutModal, setLogoutModal] = useState(false)
  const [color, setColor] = useState('primary')
  const theme = useTheme()


  const changeNavbarColor = () => {
    if (window.scrollY >= 65) {
      setColor('secondary')
    } else {
      setColor('primary')
    }
  }

  window.addEventListener('scroll', changeNavbarColor)
  const auth = useSelector((state) => state.auth.authenticated)
  const group = useSelector((state) => state.user.group)
  const mealCount = useSelector((state) => Object.keys(state.planner.plan ? state.planner.plan : {}).length)
  const username = useSelector((state) => (state.user.account.Username === undefined ? '' : state.user.account.Username))
  const avatarHash = useSelector((state) => (state.user.avatarHash === undefined ? '' : state.user.avatarHash))
  const userID = useSelector((state) => state.user.account.ID)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const uri = window.location.pathname

  const renderMenuContents = () => {
    return (
      <span>
       {group && group.includes('admin') && (
            <StyledMenuItem
              dense
              icon={<HandymanOutlinedIcon style={iconStyle}/>}
              selected={uri === '/management/ingredients'}
              onClick={() => {
                navigate('/management/ingredients')
              }}
              onClose={() => {
                setMenu(null)
              }}
              title='Management'
            />
          )}
          <StyledMenuItem
            dense
            icon={<BsSearch style={iconStyle}/>}
            selected={uri === 'search'}
            onClick={() => {
              navigate('/search')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Search'
          />
          <StyledMenuItem
            dense
            icon={<BsBasketFill style={iconStyle}/>}
            selected={uri === '/plan'}
            onClick={() => {
              navigate('/plan')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Plan'
          />
          <StyledMenuItem
            dense
            icon={<ImSpoonKnife style={iconStyle}/>}
            selected={uri === '/cook'}
            onClick={() => {
              navigate('/cook')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Cook'
          />
          <Divider />
          <StyledMenuItem
            icon={<BsBook style={iconStyle}/>}
            dense
            selected={uri === '/meals'}
            onClick={() => {
              navigate('/meals')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='My Meals'
          />
          <StyledMenuItem
            icon={<RateReviewOutlinedIcon style={iconStyle}/>}
            dense
            selected={uri === '/meals/create'}
            onClick={() => {
              navigate('/meals/create')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Create a meal'
          />
          <StyledMenuItem
            dense
            icon={<BiIdCard style={iconStyle}/>}
            selected={uri === '/profile'}
            onClick={() => {
              navigate('/profile')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Design your profile'
            sup='New'
          />
          <StyledMenuItem
            dense
            icon={<BiStats style={iconStyle}/>}
            selected={uri === '/stats'}
            onClick={() => {
              navigate('/stats')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Stats'
          />
          <Divider />
          <StyledMenuItem
            dense
            icon={<KitchenRoundedIcon style={iconStyle}/>}
            selected={uri === '/pantry'}
            onClick={() => {
              navigate('/pantry')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Pantry'
          />
          <StyledMenuItem
            dense
            icon={<BiBookHeart style={iconStyle}/>}
            selected={uri === '/favorites'}
            onClick={() => {
              navigate('/favorites')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Favorites'
          />
          <StyledMenuItem
            icon={<BsReceiptCutoff style={iconStyle}/>}
            dense
            selected={uri === '/orders'}
            onClick={() => {
              navigate('/orders')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Past Orders'
          />
           <StyledMenuItem
            icon={<BsGear style={iconStyle}/>}
            selected={uri === '/settings'}
            dense
            onClick={() => {
              navigate('/settings')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Settings'
          />
          <Divider />
          <StyledMenuItem
            icon={<BsChatSquareDots style={iconStyle}/>}
            selected={uri === '/help'}
            dense
            onClick={() => {
              navigate('/help')
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Help'
          />
          <StyledMenuItem
            icon={<BsDoorClosed style={iconStyle}/>}
            dense
            onClick={() => {
              setLogoutModal(true)
            }}
            onClose={() => {
              setMenu(null)
            }}
            title='Sign Out'
          />
          <Divider />
          <Grid style={{ display: 'flex', alignItems: 'center', padding: '8px 20px', paddingBottom: 20}}>
            <Typography
              component={Link}
              to='/privacypolicy'
              onClick={() => {
                setMenu(null)
              }}
              variant='body2'
              style={{ fontSize: 13, cursor: 'pointer', textDecoration: 'none' }}
            >
              Privacy
            </Typography>
            <Typography
              component={Link}
              to='/termsofservice'
              onClick={() => {
                setMenu(null)
              }}
              variant='body2'
              style={{ fontSize: 13, marginLeft: 16, cursor: 'pointer', textDecoration: 'none' }}
            >
              Terms
            </Typography>
          </Grid>
        </span>
      )
  }
  return (
    <Grid
      container
      style={{
        position: 'fixed',
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: 999,
        backgroundColor: 'white',
        borderBottom: '1px solid #E6EAEA'
      }}
    >
      <Grid
        item
        xs={11}
        md={10}
        lg={9}
        style={{
          height: 65,
          maxHeight: 65,
          minHeight: 65,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 'auto'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to='/'
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', position: 'relative', top: 2 }}
          >
            <Grid sx={{display: {xs: 'none', sm: 'none'}}}>
              <img
                alt='SaltnPrep Logo'
                src={Logo}
                style={{ width: 40, paddingRight: 5, position: 'relative', bottom: 4 }}
              />
            </Grid>
            <Typography variant='h1' style={{ fontSize: 32, color: theme.palette.primary.main }}>
            Salt'nPrep
            </Typography>
          </Link>
        </div>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Grid sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <ExpandableSearchBar/>
            <div onClick={()=>{navigate('/plan')}} size='medium' style={{ display:'flex', position:'relative', marginLeft: 20, marginRight: 20}}>
              <Button size='large' style={{...{position: 'relative', top: 2, height: 42, fontSize: 17, color: '#232323'}}}
                onClick={() => {
                  navigate('/plan')
                }}
              >
              Plan
              </Button>
              <Badge
                style={{ position: 'absolute', right: 10, top: 10, zIndex: -2}}
                badgeContent={mealCount}
                color='primary'
                sx={{ '& .MuiBadge-badge': { color: 'white' } }}
              />
            </div>
            <Button size='large' style={{...{position: 'relative', top: 2, height: 42, fontSize: 17, color: '#232323'}}}
              onClick={() => {
                navigate('/cook')
              }}
            >
            Cook
            </Button>
          </Grid>
          <Grid sx={{ display: { xs: 'flex', md: 'none' } }}>
           <IconButton component={Link} to='/search' sx={{ ml: 2 }} size='medium' style={{ fontSize: 20 }}>
              <BsSearch color='#232323DD' />
          </IconButton>
            <IconButton sx={{ ml: {xs: 1} }} component={Link} to='/plan' size='medium' style={{ fontSize: 20 }}>
              <BsBasketFill color='#232323DD' />
              <Badge
                style={{ position: 'absolute', right: 7.5, top: 7.5 }}
                badgeContent={mealCount}
                color='primary'
                sx={{ '& .MuiBadge-badge': { color: 'white' } }}
              />
            </IconButton>
          </Grid>
        <Grid sx={{ml: { xs: 2, sm: '20px' }}}>
          {auth ? (
            <AccountButton active={menu} onClick={(e) => { setMenu(e.currentTarget)}} />
          ) : (
            <Button
              component={Link}
              to='/login'
              disableElevation
              size='medium'
              variant='contained'
              color={color}
              style={{...color === 'secondary' ? { color: 'black' } : {}, ...{maxWidth: 75, height: 42, whiteSpace: 'nowrap'}}}
            >
              Try&nbsp;It&nbsp;Free
            </Button>
          )}
          </Grid>
        </div>
      </Grid>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false)
        }}
        sx={{ pt: '65px' }}
      >
        <Grid item xs={12} sm={8} sx={{ backgroundColor: 'white', margin: 'auto', borderRadius: 2, p: 1 }}>
          <Typography id='modal-modal-title' variant='body2'>
            Perform the search functionality here......
          </Typography>
        </Grid>
      </Modal>

      <Menu
        transitionDuration={200}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => {
          setMenu(null)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 15}}
        sx={{ display: { xs: 'none', sm: 'none' } }}
      >
        <Grid
          onClick={() => {
            navigate('/settings')
            setMenu(null)
          }}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            height: 65,
            maxHeight: 65,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 20,
            minWidth: 180,
            cursor: 'pointer'
          }}
        >
          <Avatar
            style={{ height: 34, width: 34, backgroundColor: theme.palette.primary.main }}
            alt={username}
            key={avatarHash}
            src={userID === undefined ? '' : `https://${env_config.s3.BUCKET}.s3.amazonaws.com/protected/${userID}/avatar.jpg?${avatarHash}`}
          />
          <Typography
            variant='body1'
            style={{ fontFamily: 'Recoleta', fontWeight: 600, textAlign: 'center', paddingLeft: 8 }}
          >
            @{truncateText(username, 14)}
          </Typography>
        </Grid>
        <Divider />
        {renderMenuContents()}
      </Menu>
      <Drawer
        open={Boolean(menu)}
        onClose={() => {
          setMenu(null)
        }}
        anchor='right'
        sx={{ display: { xs: 'flex', sm: 'flex' } }}
      >
        <Grid
          onClick={() => {
            navigate('/chef/' + username)
            setMenu(null)
          }}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            height: 65,
            maxHeight: 65,
            minHeight: 65,
            paddingLeft: 20,
            paddingRight: 20,
            minWidth: 180,
            cursor: 'pointer'
          }}
        >
          <Avatar
            style={{ height: 34, width: 34, backgroundColor: theme.palette.primary.main }}
            alt={username}
            key={avatarHash}
            src={userID === undefined ? '' : `https://${env_config.s3.BUCKET}.s3.amazonaws.com/protected/${userID}/avatar.jpg?${avatarHash}`}
          />
          <Typography
            variant='body1'
            style={{ fontFamily: 'Recoleta', fontWeight: 600, textAlign: 'center', paddingLeft: 8 }}
          >
            @{truncateText(username, 14)}
          </Typography>
        </Grid>
        <Divider />
       {renderMenuContents()}
      </Drawer>

      <ConfirmActionModal
        title='Logout?'
        description='Are you sure you want to logout?'
        onConfirmText='Yes'
        onCloseText='Cancel'
        open={logoutModal}
        onConfirm={() => {
          setLogoutModal(false)
          dispatch(logout())
          navigate('/')
        }}
        onClose={() => {
          setLogoutModal(false)
        }}
      />
    </Grid>
  )
}