import React, { useState } from 'react'
import { Button } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import { useDispatch } from 'react-redux'
import { socialSignOn } from '../../actions'

export default function FacebookButton({title, isLoading, setIsLoading}) {
  const dispatch = useDispatch()

  const handleClick = (event) => {
    event.preventDefault()
    setIsLoading(true)
    dispatch(socialSignOn({connection:'facebook'}))
  }

  return (
    <Button
      size='small'
      variant='outlined'
      style={{
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: 'auto',
        padding: 10,
        width: '80%',
        textAlign: 'center'
      }}
      onClick={handleClick}
      disabled={isLoading}
      startIcon={<FacebookIcon style={{...{fontSize: 24 }, ... isLoading ? {color: '#ccc'} : {color: '#3b5998'}}} />}
    >
      {title}
    </Button>
  )
}

/*
function waitForInit() {
  return new Promise((res) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res()
      } else {
        setTimeout(hasFbLoaded, 300)
      }
    }
    hasFbLoaded()
  })
}
*/

  /*
  async componentDidMount() {
    this.loadFacebookSDK()

    await waitForInit()
    this.setState({ isLoading: false })
  }

  loadFacebookSDK() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: config.social.FB,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.1'
      })
    }

    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      this.handleResponse(response.authResponse)
    } else {
      this.handleError(response)
    }
  }

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback)
  }

  handleClick = () => {
    window.FB.login(this.checkLoginState, { scope: 'public_profile,email' })
  }

  handleError(e) {
    console.log(e)
  }

  async handleResponse(data) {
    const { accessToken, expiresIn } = data
    const date = new Date()
    const expires_at = expiresIn * 1000 + date.getTime()
    if (!accessToken) {
      return
    }

    this.setState({ isLoading: true })

    window.FB.api('/me', { fields: 'name,email,picture' }, (response) => {
      // console.log('FB Reponse', response)
      const user = { name: response.name, email: response.email }
      console.log({user})
      // console.log('User Object', user)
      // Auth.federatedSignIn('facebook', { token: accessToken, expires_at }, user).then(() => {
        // this.props.onLogin(user)
      // })
    })
  }
  */