import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    handleAuthentication,
    getFavorites,
    getAccount,
    getPantry,
    getOrders } from '../../actions'
import { Grid, Dialog, CircularProgress, Typography } from '@mui/material'

const funFacts = [
'Potatoes are 80% Water',
'Cotten Candy was invented by a dentist',
'You can bounce ripe cranberries',
'Some Wasabi is actually horseradish',
'nutmeg can make you hallucinate',
'carrots are sweeter in the winter',
'Lemons float but limes sink',
'Almonds are seeds, not nuts',
'rhubarb grows so fast, you can hear it'
]

export const PostAuthenticate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const auth = useSelector((state) => state.auth.authenticated)
    const responseCode = useSelector((state) => state.user.response)
    let random = Math.floor(Math.random() * funFacts.length)

    useEffect(()=>{
        if(responseCode === 500 || responseCode === 403){
          navigate('/welcome')
        }
        if(responseCode){
          navigate('/plan')
        }
      }, [responseCode])

    useEffect(() => {
        if (auth) {
          dispatch(getAccount())
          dispatch(getPantry())
          dispatch(getFavorites())
          dispatch(getOrders())
        }
      }, [auth])

    useEffect(()=>{
        if(location.hash){
            dispatch(handleAuthentication())
        }
    },[])

    return(
        <Dialog
            BackdropProps={{ style: { backgroundColor: '#FFFFFFF2' } }}
            open={true}
            fullWidth
            fullScreen={true}
            PaperProps={{
                style: {
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                height: '100%',
                paddingBottom: 0,
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 0
                }
            }}
            >
            <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                <Typography variant='h5' style={{ textAlign: 'center', textTransform: 'capitalize', paddingBottom: 15}}>
                    Loading Your Profile! Did you know
                </Typography>
                    <Typography variant='h5' style={{ textAlign: 'center', textTransform: 'capitalize'}}>
                    {funFacts[random]}
                    </Typography>
                <Grid sx={{display: 'flex', justifyContent: 'center', paddingTop: 5}}>
                    <CircularProgress size={30}/>
                </Grid>

            </Grid>
        </Dialog>
    )
}