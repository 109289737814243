import React, { useState, useEffect } from 'react'
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Alert
} from '@mui/material'
import MealTemplate from '../../components/MealTemplate'
import { useSelector } from 'react-redux'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import PageWrapper from '../../components/PageWrapper'
import UpgradeModal from '../../components/UpgradeModal'

import { getDraftMeals, getMealsByQuery } from '../../helpers/API'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const Label = ({ count, title }) => {
  return <Typography variant='body2'>{title}</Typography>
}

function MyMeals(props) {
  const location = useLocation()
  const username = useSelector((state) => state.user.account.Username)
  const [value, setValue] = useState(0)
  const [meals, setMeals] = useState([])
  const [drafts, setDrafts] = useState([])
  const [isLoadingMeals, setIsLoadingMeals] = useState(true)
  const [isLoadingDrafts, setIsLoadingDrafts] = useState(true)
  const [displayUpgrade, setDisplayUpgrade] = useState(false)
  const group = useSelector((state) => state.user.group)

  const navigate = useNavigate()

  useEffect(() => {
    if (username) {
      getChefContent()
    }
  }, [ username ])

  useEffect(() => {
    if (group.includes('chef')) {
      setDisplayUpgrade(false)
    }
  }, [ group ])

  const getChefContent = async () => {
    getMealsByQuery({chef: username}, 0)
      .then((resp) => {
        setMeals(resp)
        setIsLoadingMeals(false)
      })
      .catch((error) => {
        console.log('Failed to download usermeals:', error)
        setIsLoadingMeals(false)
      })
    getDraftMeals()
      .then((resp) => {
        setDrafts(resp)
        setIsLoadingDrafts(false)
      })
      .catch((error) => {
        console.log({error})
        setIsLoadingDrafts(false)
      })
  }
  const renderRecipes = () => {
    var mymeals = []
    var isLoading = true
    var noMealMessage = ''
    var mode = false

    if (value === 0) {
      mymeals = meals
      isLoading = isLoadingMeals
      noMealMessage = 'You have not created any recipes'
      mode = 'update'
    } else if (value === 1) {
      mymeals = drafts
      isLoading = isLoadingDrafts
      noMealMessage = 'You have no saved drafts'
      mode = 'draft'
    }
    var basePath = props.basePath ? props.basePath : '/'

    if (isLoading) {
      return <CircularProgress color='secondary' style={{ marginTop: 15 }} />
    }
    return (
      <Grid container style={{ paddingTop: 15 }}>
        {mymeals.length ? (
          mymeals
            .slice(0)
            .reverse()
            .map((meal, index) => {
              return (
                <MealTemplate
                  key={index}
                  handleTemplateClick={
                    value === 0
                      ? () => navigate(`/recipe/${meal['Name']}/${meal['ID']}`, {state: { from: location.pathname}})
                      : () => navigate(`${basePath}meals/drafts/edit/${meal['ID']}`)
                  }
                  handleIconButton={
                    value === 0
                      ? () => navigate(`${basePath}meals/edit/${meal['ID']}`)
                      : () => navigate(`${basePath}meals/drafts/edit/${meal['ID']}`)
                  }
                  mealBucket={value !== 0 && '/draftmeals'}
                  icon={<EditOutlinedIcon style={{ fontSize: 18, color: 'white' }} />}
                  chefID={meal['ChefID']}
                  name={meal['Name']}
                  mealid={meal['ID']}
                  bucket={mode === 'update' ? 'meals' : 'draftmeals'}
                  hash={true}
                />
              )
            })
        ) : (
          <Typography variant='body2'>{noMealMessage}</Typography>
        )}
      </Grid>
    )
  }
  return (
    <PageWrapper>
      <Grid item xs={12} md={8} style={{ paddingTop: 40, paddingBottom: 100 }}>
        <Grid
          container
          style={{ margin: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant='h4'>Your Recipe Book</Typography>
          <Button component={Link} to='/meals/create' size='small' color='primary' variant='contained' disableElevation>
            Create Recipe
          </Button>
        </Grid>
        {!group.includes('chef') &&
          <Alert severity='warning' style={{marginTop: 15}}
           iconMapping={{
            warning: <WarningAmberIcon fontSize='inherit' style={{color: 'rgb(255, 167, 38)'}} />
          }}
            action={
            <Button onClick={()=>{setDisplayUpgrade(true)}} color='inherit' size='small' variant='text' style={{color: 'rgb(255, 167, 38)'}}>
              Upgrade
            </Button>
            }
          >
            Only you and your followers can see your recipes. Make your recipes public by upgrading your account.
          </Alert>
        }
        <UpgradeModal open={displayUpgrade} onClose={()=>{setDisplayUpgrade(false)}}/>
        <Tabs
          style={{ marginTop: 15, borderWidth: 0, borderBottomWidth: 1, borderStyle: 'solid', borderColor: '#e6e6e6' }}
          value={value}
          variant='scrollable'
          scrollButtons={false}
        >
          <Tab
            value={0}
            onClick={() => {
              setValue(0)
            }}
            label={<Label title={`Shared (${meals.length})`} />}
            aria-label='Shared'
          />
          <Tab
            value={1}
            onClick={() => {
              setValue(1)
            }}
            label={<Label title={`Drafts (${drafts.length})`} />}
            aria-label='Drafts'
          />
        </Tabs>
        {renderRecipes()}
      </Grid>
    </PageWrapper>
  )
}
export default MyMeals
