import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, IconButton, Divider, CircularProgress } from '@mui/material'
import { TitleComponent } from '../TitleComponent'
import { useDispatch, useSelector } from 'react-redux'
import FacebookAuth from './FacebookAuth'
import GoogleAuth from './GoogleAuth'
import { Link, useNavigate } from 'react-router-dom'
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp'
import CloseIcon from '@mui/icons-material/Close'

export default function LoginPage(props) {
  const account = useSelector((state) => state.user.account)
  const auth = useSelector((state) => state.auth.authenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('Sign In')

  useEffect(() => {
    if (Object.keys(account).length) {
      navigate('/plan')
    }
    else if (name && !auth){
      let username = name.replace(/[-\s]+/g, '')
      let body = {
        Username: username,
        Name: name,
        Email: email,
        Birthday: '',
        Zipcode: '',
        Newsletter: true,
        Allergy: [],
        Appliance: [],
        Link: [],
        Bio: ['', 'Meal Plan With My Recipes!', 'Click any meal to shop the same ingredients I used']
      }
      props.setFurtherInfo(body, username)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, auth])

  /*
  const handleSocialLogin = (userObject) => {
    console.log({userObject})
    setIsLoading(()=>true)
    dispatch(getAccount(false))
    console.log('finished dispatch')
    setName(userObject.name)
    setEmail(userObject.email)
  }
*/
  return (
    <Grid
      container
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
      }}
    >
      <TitleComponent
        title='Login'
        description='Login to access advanced features such as pantry tracking, recommended meals, and a customizable meal planning experience.'
      />

      <Grid
        item
        xs={11}
        sm={10}
        style={{ width: '100%', position: 'absolute', top: 10, display: 'flex', alignItems: 'flex-end' }}
      >
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <CloseIcon size={32} style={{ color: 'black' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>
          Hello
        </Typography>
      </Grid>

      {!isLoading &&
        <Grid item xs={10} sm={7} style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Button
            variant='outlined'
            size='small'
            startIcon={<MailOutlineSharpIcon style={{ color: '#317b6a', fontSize: 24 }} />}
            style={{
              borderRadius: 25,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: 10,
              width: '80%',
              textAlign: 'center',
              margin: 'auto',
              marginBottom: 15
            }}
            onClick={() => {
              props.setStep('Sign In')
            }}
          >
            Continue with Email
          </Button>
        <FacebookAuth title={`Continue with Facebook`} setIsLoading={setIsLoading} isLoading={isLoading}/>
        <GoogleAuth title={`Continue with Google`} setIsLoading={setIsLoading} isLoading={isLoading}/>
        <Divider style={{paddingTop: 15}}><Typography variant='body2'>or</Typography></Divider>
        <Button
          variant='outlined'
          size='small'
          style={{
            borderRadius: 25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: 10,
            width: '80%',
            textAlign: 'center',
            margin: 'auto',
            marginTop: 15
          }}
          onClick={props.handleGuest}
        >
          Continue as a Guest
        </Button>
          <Typography
            style={{ fontSize: 16, cursor: 'pointer', textAlign: 'center', paddingTop: 25 }}
            onClick={() => {
              props.setStep('Sign Up')
            }}
            disableRipple
          >
            {
            mode === 'Sign Up' ?
            <React.Fragment>Already have an account? <span style={{color: '#E87B51'}}> Sign In</span></React.Fragment>
            :
            <React.Fragment> Need an account? <span style={{color: '#E87B51'}}> Sign Up</span></React.Fragment>
          }
          </Typography>
      </Grid>

    }
      {isLoading &&
        <Grid item xs={10} sm={7} style={{margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color='primary' size={30}/>
        </Grid>
      }

      <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
        <Typography variant='body2' style={{ color: '#757575', textAlign: 'center' }}>
          Click "Continue" to agree to SaltnPrep's <Link to='/termsofservice'>Terms of Service</Link> and acknowledge
          that SaltnPrep's <Link to='/privacypolicy'>Privacy Policy</Link> applies to you.
        </Typography>
      </Grid>
    </Grid>
  )
}
