import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Drawer,
  Grid
} from '@mui/material';
import { TitleComponent } from '../../components/TitleComponent';
import MenuMeal from '../../components/MenuMeal';
import PlanMenuButton from './PlanMenuButton';
import PinnedButton from '../../components/PinnedButton';
// import CompactPlan from './CompactPlan';
import Order from '../../components/Checkout/Order';
import '../../styles/helpers.css';
import HorizontalMeal from '../../components/HorizontalMeal';
import ReactGA from 'react-ga4'


export default function Planner() {
  const username = useSelector((state) => (state.user.account.Username ?? ''));
  const plan = useSelector((state) => (state.planner.plan ?? {}));
  /*
  const [
    isHeadingInView,
    setIsHeadingInView
  ] = useState(true);
  */
  const [orderDrawer, setOrderDrawer] = useState(false);
  const [drawerStyle, setDrawerStyle] = useState({});
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  /*
  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 250) {
        setIsHeadingInView(false);
      } else {
        setIsHeadingInView(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  },[]);
  */

  useEffect(() => {
    setOrderDrawer(false);
    if (!isLargeScreen){
      setDrawerStyle({
        boxShadow: 'none',
        margin: 0,
        marginBottom: 0,
        paddingBottom: 0,
        height: '100%',
        width: '100%',
        fontFamily: 'Roboto, "sans-serif"'
      });
    } else {
      setDrawerStyle({});
    }
  }, [isLargeScreen]);

  const goToCart = () => {
    setOrderDrawer(true)
    ReactGA.event({category: 'checkout', action: 'view_cart'})
  }
  
  const renderPlan = () => {
    let meals = []
    if(isLargeScreen){
      for (let ID in plan) {
        meals.push(
          <MenuMeal
            key={plan[ID]['ID']}
            servings={plan[ID]['Servings']}
            mealID={plan[ID]['ID']}
            chefID={plan[ID]['ChefID']}
            image={plan[ID]['ID']}
            name={plan[ID]['Name']}
          />
        )
      }
    }
    else{
      for (let ID in plan) {
        meals.push(
          <HorizontalMeal
            key={ID}
            servings={plan[ID]['Servings']}
            mealID={plan[ID]['ID']}
            chefID={plan[ID]['ChefID']}
            name={plan[ID]['Name']}
          />
        )
      }
    }
    return meals;
  }

  let mealCount = Object.keys(plan).length;

  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: 'calc(100vh - 60px)'
      }}
      sx={{py: { xs: '35px', md: '50px'}, overflowX: { xs: 'hidden', md: 'visible'}}}
    >
      <TitleComponent
        title='Plan Your Meals'
        description='Take control of your meal plan. Add unlimited meals and follow professional steps to make the same meals as your favorite chef!'
      />
      <Grid
        container
        style={{
          position: 'relative'
        }}
      >
        <Grid
          item
          xs={11}
          md={10}
          lg={9}
          sx={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h5' color='primary'>
              Hello{username === '' ? '' : ' ' + username},
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant='h5'>You have</Typography>
              <Typography variant='h4' color='primary'>
                {' '}&nbsp;{mealCount}&nbsp;
              </Typography>
              <Typography variant='h5'>{mealCount === 1 ? 'meal' : 'meals'} planned!</Typography>
            </div>
          </div>
          <PlanMenuButton
            disabled={mealCount === 0}
            openOrderDrawer={goToCart}
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={11}
            md={10}
            lg={9}
            sx={{
              mx: 'auto', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, pt:{xs: 2, md:0}
            }}
          >
            { renderPlan() }
            <SearchRecipesButton link='/search'/>
          </Grid>
        </Grid>
        {Object.keys(plan).length > 0 && (
        <Grid item xs={12} sm={11} md={10} lg={9} sx={{ margin: 'auto', display: { xs: 'none', sm: 'block' }, pt: 8 }}>
          <Order
            onClose={() => {
              setOrderDrawer(false)
            }}
          />
        </Grid>
      )}
      </Grid>

      {
      /*<Slide in={(!isHeadingInView && isLargeScreen) | !isLargeScreen && mealCount}
             sx={{height: 100, width: '100%'}}
             direction={isLargeScreen ? 'down' : 'up'}
             timeout={300}
      >
        <div style={isLargeScreen ? {
          position: 'fixed',
          zIndex: 1000,
          right: 0,
          left: 0,
          top: 60
        } : {
          position: 'fixed',
          zIndex: 1000,
          bottom: 10,
          right: 10,
          left: 10
        }}>
          <CompactPlan openOrderDrawer={() => setOrderDrawer(true)}/>
        </div>
      </Slide>
      */
      }
      <PinnedButton onClick={goToCart} disabled={Object.keys(plan).length === 0} title={'Go to Cart'}/>
      <Drawer
        scroll='paper'
        open={orderDrawer}
        anchor={isLargeScreen ? 'right' : 'bottom'}
        onClose={() => setOrderDrawer(false)}
        PaperProps={{ style: drawerStyle }}>
        <Order onClick={() => setOrderDrawer(false)}
               onClose={() => setOrderDrawer(false)}/>
      </Drawer>
    </Grid>
  )
}


function SearchRecipesButton({ link, isLargeScreen }) {
  const navigate = useNavigate();
    return(
      <React.Fragment>
        <Grid
          onClick={()=> navigate(link)}
          className='recipe-card'
          sx={{
            backgroundColor: '#FFF',
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: 160, sm: 180, md: 200 },
            minWidth: { xs: 160, sm: 180, md: 200 },
            minHeight: 175,
            mr: 2,
            mt: 2,
            borderRadius: '16px',
            overflow: 'hidden',
            cursor: 'pointer',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
            transitionDuration: '0.3s',
            willChange: 'transform'
          }}
        >
          <AddCircleOutline style={{ fontSize: 26 }} />
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            Search Recipes
          </Typography>
        </Grid>
        <Grid
        sx={{ display: { xs: 'flex', sm: 'none' } }}
        onClick={()=> navigate(link)}
        className='hoverNoRaise'
        style={{
          color: 'black',
          textDecoration: 'none',
          paddingTop: 70,
          paddingBottom: 80,
          width: '100%',
          height: '100px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <AddCircleOutline style={{ fontSize: 26 }} className='hoverText' />
        <Typography variant='body1' className='hoverText' style={{ fontWeight: 'bold' }}>
          Browse Recipes
        </Typography>
      </Grid>
     </React.Fragment>
    )
}

/*
    <TrendingFeed description={'Most popular recipes everyone is cooking right now'} title={'Trending Now'} avatar={false} noWrap={true}/>
*/