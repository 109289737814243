import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Button,
  Snackbar,
  SnackbarContent
} from '@mui/material'
import CompactMeal from './CompactMeal'
import { useTheme } from '@mui/material/styles'
import {BsBasketFill} from 'react-icons/bs'
import useMediaQuery from '@mui/material/useMediaQuery'

import _ from 'lodash';
import '../../styles/helpers.css';


function PlanSnackBar() {
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('md'))

    const navigate = useNavigate()
    const plan = useSelector((state) => (state.planner.plan ?? {}));
    const [snack, setSnack] = useState(false)
    const [first, setFirst ] = useState(true)

    const handleClick = () =>{
        navigate('/plan')
    }

    useEffect(()=>{
        if (!first && Object.keys(plan).length){
            setSnack(true)
        }
        else if(Object.keys(plan).length){
            setFirst(false)
        }
    },[plan])

    useEffect(() => {
        const onScrolledBottom = (e) => {
            var element = e.target.documentElement
            if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
                setSnack(false);
            }
        };
        window.addEventListener('scroll', onScrolledBottom);
        return () => window.removeEventListener('scroll', onScrolledBottom);
    },[]);


    return (
        <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={()=>{setSnack(()=>false)}}
            anchorOrigin={ isLarge ? { vertical: 'bottom', horizontal: 'right' } : {vertical: 'bottom', horizontal: 'center'}}
            sx={{paddingRight: {xs:0, md: '6.33%', lg: '11.25%'}, paddingTop: {xs: 0, md: '0px'}}}
        >
            <SnackbarContent sx={{backgroundColor: 'transparent'}}
            message={
                <Box sx={{
                backgroundColor: 'rgba(232, 123, 81, 0.95)',
                borderRadius: 25,
                overflow: 'hidden'
                }}
                >
                <Grid container>
                    <Grid
                    className='noscroll'
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        p: 0.75,
                        pl:0,
                        ml:0
                    }}
                    >
                    <Box sx={{
                        pl: 0.75,
                        flexGrow: 4,
                        overflowX: 'scroll',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    {
                        _.map(plan, (meal, id) => (
                        <CompactMeal
                            name={meal['Name']}
                            mealID={meal['ID']}
                            servings={meal['Servings']}
                            chefID={meal['ChefID']}
                            image={meal['ID']}
                            key={meal['ID']}
                        />
                        ))
                    }
                    </Box>

                    <Box sx={{height: '100%', backgroundColor: 'transparent'}}>
                        <Button
                            className='btn-light'
                            disableElevation
                            onClick={handleClick}
                            variant='contained'
                            color='primary'
                            size='large'
                            fullWidth
                            sx={{
                                minHeight: '2.7rem',
                                whiteSpace: 'nowrap',
                                minWidth:'max-content',
                                backgroundColor:  'white',
                                color: theme.palette.primary.main
                            }}
                        >
                        <BsBasketFill style={{paddingRight:7.5}}/>
                        View Plan
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
     }
     />
   </Snackbar>
  )
}

export default PlanSnackBar;