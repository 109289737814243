import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getAuthState } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

export default function ProtectedRoute(props) {
  const auth = useSelector((state) => state.auth.authenticated)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchAuth() {
      await dispatch(getAuthState())
      setIsLoading(false)
    }
    if (!auth) {
      fetchAuth()
    }
  }, [auth, dispatch])

  if (!isLoading && !auth) {
    return <Navigate to='/login' replace />
  }
  if (auth){
    return props.children
  }
  return <div/>
}
