import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, TextField, InputAdornment, IconButton, CircularProgress, Alert } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { TitleComponent } from '../../components/TitleComponent'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signinUser, forgotPassword, resetErrorMsg } from '../../actions'
import { validateEmail, validateLowerCase, validateUpperCase, validateNumber, validateLength, validateSpecial} from '../../helpers/helpers'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error';


export default function LoginEmail(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [bannerText, setBannerText] = useState('')
  const [passwordReset, setPasswordReset] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [didReset, setDidReset] = useState(false)
  const errorError = useSelector((state) => state.auth.error)
  const forgotError = useSelector((state) => state.forgot.error)

  useEffect(()=>{
    setisLoading(()=>false)
  },[errorError, forgotError, bannerText])

  const changeText = async(event) => {
    if (event.target.id === 'password') {
      setPassword(event.target.value)
    }
    else if (event.target.id === 'email') {
      setEmail(event.target.value)
    }
  }

  const handlePasswordReset = async () => {
    setisLoading(()=>true)
    try {
      dispatch(forgotPassword({email},()=>{setBannerText('Password reset mail sent. Please check your inbox')}))
    } catch (e) {
      console.log(e)
      setBannerText(e.message)
    }
    setDidReset(()=>true)
    setisLoading(()=>false)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const getConditionsMet = () => {
    const conditions = [ validateLowerCase(password), validateNumber(password), validateUpperCase(password), validateSpecial(password)]
    return conditions.filter((e) => e === true).length
  }

  const handleContinueDisabled = () => {
      return !(validateEmail(email) && validateLength(password) && getConditionsMet() >= 3 )
  }

  const handleLogin = () => {
    setisLoading(true)
    dispatch(signinUser({ email, password }))
  }

  const renderEmailInput = () => {
    return(
      <TextField
        color='primary'
        autoComplete='email'
        onChange={changeText}
        fullWidth
        required
        size='small'
        id='email'
        label='Email'
        type='text'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <MailOutlineSharpIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          )
      }}
    />
    )
  }

  const renderPasswordInput = () => {
    return(
      <TextField
        color='primary'
        size='small'
        onChange={changeText}
        fullWidth
        required
        id='password'
        type={showPassword ? 'text' : 'password'}
        value={password}
        autoComplete='password'
        label='Password'
        margin='normal'
        InputProps={{
          style: { borderRadius: 25} ,
          startAdornment: (
            <InputAdornment position='start'>
              <LockOutlinedIcon style={{ color: '#BBB' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>{' '}
            </InputAdornment>
          )
        }}
      />
    )
  }

  return (
    <Grid
      container
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
      }}
    >
      <TitleComponent
        title='Login'
        description='Login to access advanced features such as pantry tracking, recommended meals, and a customizable meal planning experience.'
      />
      <Grid
        item
        xs={11}
        sm={10}
        style={{ width: '100%', position: 'absolute', top: 10, display: 'flex', alignItems: 'flex-end' }}
      >
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <CloseIcon size={32} style={{ color: 'black' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>
          Welcome Back
        </Typography>
      </Grid>

      <Grid
        container
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
         <Grid item xs={8} md={4} style={{ width:'100%', paddingTop:15, paddingBottom:15 }}>
            {bannerText && <Alert severity='info' color='info'>{bannerText}</Alert>}
            {errorError && <Alert icon={<ErrorIcon style={{color:'#f44336'}}/>} severity='error' color='error'>{errorError}</Alert>}
            {forgotError && <Alert icon={<ErrorIcon style={{color:'#f44336'}}/>} severity='error' color='error'>{forgotError}</Alert>}
        </Grid>

        <Grid item xs={8} md={4} style={{ margin: 'auto', width: '100%', display: 'flex', flexDirection: 'column' }}>
            {passwordReset &&
            <Typography variant='h6' style={{ textAlign: 'center', paddingBottom: 10 }}>
             Reset Password
            </Typography>
            }
            {renderEmailInput()}
            {passwordReset ? (
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                      variant='text'
                      size='small'
                      disabled={!validateEmail(email) || !didReset}
                      onClick={handlePasswordReset}
                    >
                      Resend Code
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      style={{ marginTop: 5, padding: 7, paddingLeft: 30, paddingRight: 30, color: 'white' }}
                      size='small'
                      disabled={!validateEmail(email) || didReset}
                      onClick={handlePasswordReset}
                    >
                      {isLoading ? 'Confirming' : 'Reset'}
                    </Button>
                  </Grid>
              ) : (
                <React.Fragment>
                    {renderPasswordInput()}
                    <Button
                      disabled={handleContinueDisabled() || isLoading}
                      onClick={handleLogin}
                      variant='contained'
                      color='primary'
                      fullWidth
                      size='small'
                      disableElevation
                      style={{ padding: 10, marginTop: 15, marginBottom: 15, borderRadius: 25, color: 'white' }}
                    >
                      {isLoading ? <CircularProgress size={20}/> : 'Sign In'}
                    </Button>

                      <Button
                        size='small'
                        onClick={() => {
                          dispatch(resetErrorMsg())
                          setPasswordReset(true)
                        }}
                    >
                  Forgot Password?
                  </Button>
                </React.Fragment>
              )}
                <Grid
                    style={{
                      paddingTop: 25,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <ChevronLeftIcon
                      onClick={() => {
                        props.setStep('login')
                      }}
                      size={20}
                      style={{ color: '#E87B51', cursor: 'pointer' }}
                    />
                    <Typography
                      style={{ fontSize: 16, color: '#E87B51', cursor: 'pointer' }}
                      onClick={() => {
                        props.setStep('login')
                      }}
                      disableRipple
                    >
                       All Sign In Options
                    </Typography>

                  </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
        <Typography variant='body2' style={{ color: '#757575', textAlign: 'center' }}>
          Click "Continue" to agree to SaltnPrep's <Link to='/termsofservice'>Terms of Service</Link> and acknowledge
          that SaltnPrep's <Link to='/privacypolicy'>Privacy Policy</Link> applies to you.
        </Typography>
      </Grid>
    </Grid>
  )
}
