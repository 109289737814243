import React, {useEffect, useState} from 'react'
import {
  Grid,
  Dialog,
  IconButton,
  InputBase,
  Button,
  Divider,
  ListItemText,
  ListItemIcon,
  List,
  ListSubheader
} from '@mui/material'
import { getFoundationIngredients } from '../helpers/API'
import { useSelector, useDispatch } from 'react-redux'
import { postPantry, delPantry } from '../actions'
import { useTheme } from '@mui/material/styles'
import { BsSearch, BsX } from 'react-icons/bs'
import { toTitleCase } from '../helpers/helpers'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GiShinyApple } from 'react-icons/gi'
import { styled } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import Highlighter from 'react-highlight-words'
import Tag from '../components/Tag'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'

import '../styles/helpers.css'


const TransitionLarge = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction='up' ref={ref} {...props} />;
});

const TransitionSmall = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MAX_INGREDIENTS = 6

const CustomListItemButton = styled('ListItemButton')`
  display: flex;
  padding: 0.5rem 1rem;
  border: 1px solid #fbfbfc;
  border-bottom-color: rgba(0,0,0,0.025);
  align-items: center;
  border-radius: 7.5px;
  color: #adb5c1;
  font-size: 0.85rem;
  cursor: pointer;
  transition: 0s;
  &:hover {
    background-color: #e87b5111;
    border: 1px solid #e87b51;
  }
`

const Item = ({title, query, icon, action})=> {
  const theme = useTheme()
  return (
      <CustomListItemButton className='custom_list' onClick={action}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText>
         <Highlighter
            searchWords={[query]}
            textToHighlight={title}
            highlightStyle={{textDecoration: 'underline', backgroundColor: 'transparent'}}
          />
        </ListItemText>
        <AddIcon className='icon' style={{color: theme.palette.primary.main, transform: 'scale(-1'}}/>
      </CustomListItemButton>
  )
}

export default function PantryModal({open, onClose}) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const displayMode = useMediaQuery(theme.breakpoints.up('md'))
    const pantry = useSelector((state) => state.planner.pantry)
    // Need a temp pantry to get rid of lag + stop weird rendering changes.
    const [startingPantry, setStartingPantry] = useState([])
    const [list, setList] = useState([])
    const [listError, setListError] = useState(true)
    const [query, setQuery] = useState('')
    const [removedItems, setRemovedItems] = useState([])

    useEffect(()=>{
      if(!startingPantry.length && pantry.length){
        var tempPantry = [...pantry].reverse()
        setStartingPantry(tempPantry)
      }
    },[ pantry, open])

    useEffect(() => {
        if (query){
            const pageNumber = 1
            const name = toTitleCase(query.replace(/\s+/g, ' ').trim()).replace(/ /g, '+')
            var q = { pageNumber, name }
            getFoundationIngredients(q)
            .then((data)=>{
                setList(data.slice(0, MAX_INGREDIENTS))
                setListError(!data.length)
            })
            .catch((error)=>{
              console.log({error})
            })
        }
        else {
            setListError(()=>true)
            setList(()=>[])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

  const handleQueryChange = (e) =>{
    var tempQuery = e.target.value
    tempQuery = toTitleCase(tempQuery)
    setQuery(tempQuery)
  }

  const onAddNewPantry = (ing) => {
    onAddPantry(ing)
    var tempStartingPantry = [...startingPantry]
    tempStartingPantry.unshift(ing)

    setStartingPantry(tempStartingPantry)
  }

  const onAddPantry = async (ing) => {
    var postPantryBody = []
    var ingredient = {
        Category: ing['Category'],
        FDIC: ing['FDIC'],
        FamilyID: ing['FamilyID'],
        ID: ing['ID'],
        MetricVolume: ing['MetricVolume'],
        MetricWeight: ing['MetricWeight'],
        Name: ing['Name'],
        UsMeasurements: ing['UsMeasurements'],
        DateAdded: Date.now()
    }
    postPantryBody.push(ingredient)
    var tempRemovedItems = [...removedItems].filter(e => e !== ing['ID'])
    setRemovedItems(()=>tempRemovedItems)
    setQuery(()=>'')
    await dispatch(postPantry(postPantryBody))
  }

  const onRemovePantry = async (ing) => {
    var tempItems = [...removedItems]
    tempItems.push(ing['ID'])
    setRemovedItems(()=>tempItems)
    dispatch(delPantry(ing['ID']))
  }

  return (
    <Dialog open={open} maxWidth={'sm'} fullScreen={!displayMode} fullWidth={true} onClose={onClose} TransitionComponent={displayMode ? TransitionLarge : TransitionSmall} sx={{...displayMode ? {'&  .MuiDialog-paper': { height: '60vh', backgroundColor: '#fbfbfc'} } : {'&  .MuiDialog-paper': {backgroundColor: '#fbfbfc'}}}}>
      <Grid style={{position: 'relative'}}>
      <Grid container style={{position: 'sticky', top: 0, zIndex: 40, padding: 10, paddingLeft: 15, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: 'transparent', borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 1, borderColor: 'rgba(0,0,0,0.08)'}}>
          <BsSearch style={{fontSize: 17, color: '#999c9c', paddingRight: 5}}/>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Add ingredients to your pantry'
            inputProps={{ 'aria-label': 'Add ingredients to your pantry' }}
            id='query'
            value={query}
            onChange={handleQueryChange}
          />
            <IconButton onClick={()=>{setQuery('')}} size='small'>
              <BsX size={16} />
            </IconButton>
            <Divider sx={{ height: 25, m: 0.5 }} orientation='vertical' />
            <Button size='small' variant='text' onClick={onClose} color='secondary'>Close</Button>
        </Grid>

        <Grid style={{flex: 1, padding: 15, paddingTop: 0, position: 'relative'}}>
          <List>
          {!listError &&
            <React.Fragment>
              <ListSubheader disableGutters={true} style={{ top: 50, backgroundColor: 'transparent'}}>Add Ingredients</ListSubheader>
              {list.map((i) => {
                return (
                  <Item
                    action={()=>{onAddNewPantry(i)}}
                    icon={<GiShinyApple/>}
                    key={i['ID']}
                    title={i['Name']}
                    query={query}
                  />
                )
              })}
            </React.Fragment>
          }
            <ListSubheader disableGutters={true} style={{marginTop: 10, top: 50, backgroundColor: 'transparent'}}>Your Pantry {(startingPantry.length === 0 && removedItems.length === 0) && ' is Empty'}</ListSubheader>
            {startingPantry.map((ing, index) => {
              var inPantry = !removedItems.includes(ing.ID)
              return (
                <Tag
                  key={index}
                  label={ing.Name}
                  size='small'
                  selected={inPantry}
                  handleClick={inPantry ? ()=>{onRemovePantry(ing)} : ()=>{onAddPantry(ing)}}
                />)}
            )}

        </List>

      </Grid>
      </Grid>
    </Dialog>
    )
}
