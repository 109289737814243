import axios from 'axios'

import env_config from './../../env_config'

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_SUCCESS,
  CLEARDOWN
} from './types'


export function forgotPassword({ email }, callback) {

  return function(dispatch) {

    dispatch({ type: FORGOT_PASSWORD_IN_PROGRESS });

    console.log(`sending password reset for: ${email}`);

    const values = {
      client_id: env_config.auth0.clientId,
      email: email,
      connection: env_config.auth0.realm
    };

    axios
      .post(`https://${env_config.auth0.domain}/dbconnections/change_password`, values)
      .then(() => {
        setTimeout(() => {
          dispatch({
            type: FORGOT_SUCCESS,
            message: 'Password reset mail sent. Please check your inbox'
          }, 1000);
        })
        dispatch({
          type: FORGOT_PASSWORD
        });
        return callback();
      })
      .catch(error => {
        return dispatch(forgotPasswordError(error.message || 'Error occurred'));
      });

    }

}

export function cleardown() {
  return {
    type: CLEARDOWN
  };
}


export function forgotPasswordError(error) {
  const timestamp = Date.now();
  return {
    type: FORGOT_PASSWORD_ERROR,
    error,
    timestamp
  };
}