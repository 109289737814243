import React, { useEffect, useState } from 'react'
import { IoIosSearch } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { IconButton, InputBase } from '@mui/material'

import '../styles/helpers.css'

const inputStyle = {
  width: '250px',
  display: 'flex',
  fontSize: 17,
  fontWeight: '400',
  background: 'rgb(241, 245, 246)',
  borderRadius: '25px',
  color: '#20262d',
  height: 42,
  transition: 'width 300ms ease'
}

const ExpandableSearchBar = ()=>{
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const uri = window.location.pathname

  useEffect(()=>{
    if (uri !== '/search' && query.length) {
      navigate('/search')
    }
  },[query])

  const changeText = (event) => {
    setQuery(event.target.value)
  }
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      navigate('/search', {state: {query: query}})
      setQuery(()=>'')
    }
 }

  return(
    <div
        style={inputStyle}
      >
      <IconButton sx={{ pl: '15px', cursor: 'pointer' }} aria-label='menu' onClick={()=>{navigate('/search', {state: {query: query}});setQuery(()=>'')}}>
        <IoIosSearch style={{ fontSize: 24, color: '#999c9c' }} />
      </IconButton>
      <InputBase
        value={query}
        onChange={changeText}
        onKeyDown={keyPress}
        placeholder='Search...'
        inputProps={{ 'aria-label': 'Search...' }}
      />
    </div>
  )
}
export default ExpandableSearchBar