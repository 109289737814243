import React from 'react'
import { Typography, Grid } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import DynamicImage from './DynamicImage'
import placeholder from '../media/placeholder.jpg'
import Restaurant from '@mui/icons-material/Restaurant'

import '../styles/helpers.css'

const HorizontalMeal = ({servings, mealID, chefID, name}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const goToRecipe = () => {
    navigate(`/recipe/${name}/${mealID}`, {state: { from: location.pathname}})
  }

  return (
        <Grid
          onClick={goToRecipe}
          className='hoverNoRaise'
          item
          xs={12}
          style={{
            display: 'flex',
            padding: '10px 4.13%',
            borderWidth: 0,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderColor: '#eff4f3',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
        >
          <Grid item xs={4} style={{ paddingRight: 10}}>
            <div style={{ overflow: 'hidden', borderRadius: 5 }}>
              <DynamicImage
                style={{ display: 'table', height: '100%', width: '100%', minWidth: 75 }}
                alt={name}
                placeholder={placeholder}
                bucket={`protected/${chefID}`}
                url={mealID + '300x200'}
              />
            </div>
          </Grid>
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column'}}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                className='hoverText'
                noWrap={true}
                style={{
                  whiteSpace: 'noWrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  color: '#000000',
                  textTransform: 'capitalize',
                  fontWeight: 'bold'
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 10 }}
            >
              <Typography variant='body1' style={{ fontSize: 17, fontWeight: 'bold' }}>
                {servings}
              </Typography>
              <Restaurant style={{ fontSize: 14, color: 'black' }} />
            </Grid>
          </Grid>
        </Grid>
  )
}
export default HorizontalMeal