import configureAxios from './configureAxios';
import { createDebugLogger } from './logging';
import { IMAGE_SIZES } from '../helpers/helpers'
import ReactGA from 'react-ga4'
import jsonp from 'jsonp'
import reqwest from 'reqwest'
import env_config from '../env_config';

const debug = createDebugLogger('helpers/API.js', true);

// TODO: will want to do this dynamically to add auth headers after user signs in
const api = configureAxios({});


export const handleMailChimpSubscription = (email, name, status) => {
  if (env_config.env !== 'production') {
    return;
  }
  else{
    if(status === 'unsubscribe'){
      reqwest({
        method: 'get',
        type: 'jsonp',
        contentType: 'application/json',
        url: 'https://saltnprep.us17.list-manage.com/' + status + '/post?u=70f2a47c38ecfce942c38eece&id=1fc07d0754',
        data: { EMAIL: email, FNAME: name, STATUS: status + 'd', MMERGE6: 'EditProfile'},
        jsonpCallback: 'c'
      })
    }
    else{
      try {
        var query = new URLSearchParams({EMAIL: email, FNAME: name, MMERGE6: 'EditProfile', STATUS: status + 'd'}).toString()
        jsonp(
          `https://saltnprep.us17.list-manage.com/${status}/post-json?u=70f2a47c38ecfce942c38eece&id=1fc07d0754&${query}`,
          {
            param: 'c',
          },
          (err, data) => {
            if(err || data.result !== 'success'){
              return(-1)
            }
            else{
              return(1)
            }
          }
        )
      }
      catch (e){
        debug.log(`Error trying to ${status} to Newsletter`, e)
      }
    }
  }
}

// Verified Works with new API
export const getMeals = (query = {}, pageNumber = 1) => {
  return api.get('/meal', {
    params: {
      pageNumber,
      ...query,
    }
  }).then((data) => {
    return Promise.resolve(data.data.Meal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}


// Verified Works with new API
export const getUserProfile = (username) => {
  return api.get(`/userProfile/${username}`)
    .then(response => response.data.UserProfile)
    .then((data) => {
      data['ID'] = data['ID'] ?? '';
      data['Username'] = data['Username'] ?? '';
      data['Name'] = data['Name'] ?? '';
      data['Email'] = data['Email'] ?? '';
      data['Link'] = data['Link'] ?? [];
      data['Tags'] = data['Tags'] ?? [];
      data['Bio'] = data['Bio'] ?? [];
      // data['following'] = data['Following'] ?? []
      // data['followers'] = data['Followers'] ?? []
      return Promise.resolve(data);
    })
    .catch((error)=>{
      return Promise.reject(error)
    })
}


// Verified Works with new API
export const getOfficialMeals = (query = {}, pageNumber = 1) => {
  ReactGA.event({category: 'plan', action: `get_official_meals`})
  return api.get('/meal/official', {
    params: {
      pageNumber,
      ...query,
    }
  }).then((data) => {
    return data.data.Meal;
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}

// Verified Works with new API
export const getDraftMeals = (query = {}, pageNumber = 0) => {
  return api.get('/draftmeal', {
    params: {
      pageNumber,
      ...query,
    }
  }).then((data) => {
    // TO DO: Remove check once backend changes return keys
    if(!('DraftMeal' in data['data'])){
      return Promise.resolve([]);
    }
    return Promise.resolve(data.data.DraftMeal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}

// Verified Works with new API
export const getDraftMealById = (ID) => {
  return api.get(`/draftmeal/${ID}`)
    .then((data) => {
      return Promise.resolve(data.data.DraftMeal);
    }).catch((error) => {
      debug.error(error);
      return Promise.reject(error);
    });
}

// Verified Works with new API
export const postDraftMeal = (meal) => {
  return api.post('/draftmeal',
    meal
  ).then((data) => {
    return Promise.resolve(data.data.DraftMeal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}

// Verified Works with new API
export const putDraftMealById = (ID, meal) => {
  return api.put(`/draftmeal/${ID}`,
    meal
  ).then((data) => {
    return Promise.resolve(data.data.DraftMeal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}


// Verified Works with new API
export const postMeal = (meal) => {
  return api.post('/meal',
    meal
  ).then((data) => {
    return Promise.resolve(data.data.Meal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}


// Verified Works with new API
export const putMealById = (ID, meal) => {
  return api.put(`/meal/${ID}`,
    meal
  ).then((data) => {
    return Promise.resolve(data.data.Meal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}


// Verified Works with new API
export const getMealsByQuery = (query = {}, pageNumber = 1, ) => {
  ReactGA.event({category: 'plan', action: `get_meals_by_query`})
  return api.get('/meal', {
    params: {
      pageNumber,
      ...query
    }
  }).then((data) => {
    return Promise.resolve(data.data.Meal);
  }).catch((error) => {
    debug.error(error);
    return Promise.reject(error);
  });
}

// Verified Works with new API
export const deleteDraftMealById = (ID) => {
  return api.delete(`/draftmeal/${ID}`,{})
    .then((response) => {
      return Promise.resolve(response);
    }).catch((error) => {
      debug.error({error});
      return Promise.reject(-1);
    });
}

// Verified Works with new API
// TODO: Test the "admin" delete...
export const deleteMealById = (ID, endpoint = '/meal') => {
  return api.delete(`${endpoint}/${ID}`,{})
    .then((response) => {
      return Promise.resolve(response);
    }).catch((error) => {
      debug.error(error);
      return Promise.reject(-1);
    });
}

// Verified Works with new API
export const getMealByID = (ID) => {
  ReactGA.event({category: 'plan', action: `view_meal_by_id`})
  return api.get(`/meal/${ID}`)
    .then((data) => {
      return Promise.resolve(data.data.Meal);
    }).catch((error) => {
      debug.log(error);
    });
}

// Verified Works with new API
export const usernameExists = async (username) => {
  return api.get(`/userAccount/${username}/check`)
    .then((response) => {
    return response.data.UserAccount !== 'username is available';
  }).catch((error) => {
    debug.log(error.response);
    return -1;
  });
}


// TODO: Convert backend before testing
export const upgradeToChef = (userID) => {
  return api.put(`/userProfile/upgrade/${userID}`)
    .then((response) => {
      debug.log(response);
      return Promise.resolve();
    }).catch((err) => {
      debug.log('Could Not Upgrade This Account At This Time', {err});
      return Promise.reject();
    });
}


// Verified Works with new API
export const sendUpdateEmail = (subject, body, email) => {
  return api.post('/mailfwd',
    {
      body: JSON.stringify(body),
      subject: JSON.stringify(subject),
      email: JSON.stringify(email)
    }
  )
  .then((response)=>{
    return Promise.resolve(1);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


// Verified Works with new APIs
// In Callbook form because the AsyncSelect component in CreateMealStep1.js component
export const searchFoundationIngredients = (query = {}, callback) => {
  if (!query) {
    return callback([])
  }
  let ret = []
  api.get(`/foundationIngredient`, {
    params: { ...query },
  })
  .then((data) => data.data.FoundationIngredient)
  .then((data) => {
    if (data === null) {
      callback([]);
    }
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i]['PrepOptions']) {
        for (let x = 0; x < data[i]['PrepOptions'].length; x++) {
          let tempIngredient = JSON.parse(JSON.stringify(data[i]));
          let tempOptions = [];
          tempOptions.push(data[i]['PrepOptions'][x]);
          tempIngredient['PrepOptions'] = tempOptions;
          ret.push({
            value: data[i]['ID'],
            label: data[i]['Name'] + ', ' + data[i]['PrepOptions'][x],
            ingredient: tempIngredient
          });
        }
      }
      data[i]['PrepOptions'] = [];
      ret.push({
        value: data[i]['ID'],
        label: data[i]['Name'],
        ingredient: data[i],
      });
    }
    callback(ret);
  });
}

// Verified Works with new APIs
export const getBrandIngredients = (query = {}) => {
  if (!query) {
    return Promise.resolve([]);
  }
  return api.get(`/brandIngredient`,{
    params: { ...query },
  })
  .then((data) => data.data.BrandIngredient)
  .then((data) => {
    if (data === null) {
      return Promise.resolve([]);
    }
   return Promise.resolve(data)
  });
}


// Verified Works with new APIs
export const postBrandIngredient = (data) => {
  return api.post(
    '/brandIngredient',
    data
  )
  .then((response)=>{
    return Promise.resolve(1);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


// Verified Works with new APIs
export const putBrandIngredient = (body, ID) => {
  return api.put(`/brandIngredient/${ID}`,
    body
  )
  .then((data) => data.data.BrandIngredient)
  .then((data)=>{
    return Promise.resolve(data);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


// Verified Works with new APIs
export const deleteBrandIngredient = (ID) => {
  return api.delete(`/brandIngredient/${ID}`)
  .then((data)=>{
    return Promise.resolve(1);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


// Verified Works with new APIs
export const searchBrandIngredients = (query = {}, callback) => {
  if (!query) return callback([]);

  api.get(`/brandIngredient`, {
        params: { ...query },
  })
  .then((data)=>data.data.BrandIngredient)
  .then((data) => {
    for (let i = data.length - 1; i >= 0; i--) {
      ret.push({
        category: data[i]['Category'],
        label: data[i]['Name'],
        value: data[i]['ID'],
        ingredient: data[i],
      });
    }
    callback(ret);
  }).catch((error) => {
    debug.log(error);
    callback([]);
  });
}

// Verified Works with new APIs
export const postFoundationIngredient = (data) => {
  return api.post(
    '/foundationIngredient',
    data
  )
  .then((response)=>{
    return Promise.resolve(1);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}

// Verified Works with new APIs
export const getFoundationIngredients = (query = {}) => {
  if (!query) {
    return Promise.resolve([]);
  }
  return api.get(`/foundationIngredient`,{
    params: { ...query },
  })
  .then((data) => data.data.FoundationIngredient)
  .then((data) => {
    if (data === null) {
      return Promise.resolve([]);
    }
   return Promise.resolve(data)
  });
}


// Verified Works with new APIs
export const putFoundationIngredient = (body, ID) => {
  return api.put(
    `/foundationIngredient/${ID}`,
    body
  )
  .then((data) => data.data.FoundationIngredient)
  .then((data)=>{
    return Promise.resolve(data);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


// Verified Works with new APIs
export const deleteFoundationIngredient = (ID) => {
  return api.delete(`/foundationIngredient/${ID}`)
  .then((data)=>{
    return Promise.resolve(1);
  })
  .catch((error) => {
    debug.error(error);
    return Promise.reject();
  });
}


  export const putMediaMeal = (filename, blob64) => {
    return api.put(`/media/meal/${filename}`, blob64, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((data)=>{
        return Promise.resolve(data)
      })
      .catch((e)=>{
        return Promise.reject(e);
      })
    }


export const putMultipleMealRename = async (from, to) => {
    Promise.all([
      putMediaMealRename(from + IMAGE_SIZES['original']['ext'], to + IMAGE_SIZES['original']['ext']),
      putMediaMealRename(from + IMAGE_SIZES['large']['ext'], to + IMAGE_SIZES['large']['ext']),
      putMediaMealRename(from + IMAGE_SIZES['medium']['ext'], to + IMAGE_SIZES['medium']['ext']),
      putMediaMealRename(from + IMAGE_SIZES['small']['ext'], to + IMAGE_SIZES['small']['ext'])
    ])
    .then((data)=>{
      return Promise.resolve(data)
    })
    .catch((e)=>{
      return Promise.reject(e);
    })
  }


export const putMediaMealRename = (from, to) => {
  return api.put(`/media/meal/${from}/${to}`)
    .then((data)=>{
      return Promise.resolve(data)
    })
    .catch((e)=>{
      return Promise.reject(e);
    })
  }


export const postAvatar = (blob64) => {
  return api.put(`/media/avatar`, blob64, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((data)=>{
      return Promise.resolve(data)
    })
    .catch((e)=>{
      return Promise.reject(e);
    })
  }

// NOT TESTED
export const setBrandmap = (ID, body, callback) => {
  if (!ID) return callback([]);
  api.post(`/brandMap/${ID}`,
    body
  ).then((response) => {
    this.getBrandMap(ID, callback);
  }).catch((error) => {
    debug.log(error);
    callback([]);
  });
}


// NOT TESTED
// TO DO: Is this meant to be POST?
export const getBrandMap = (ID, callback) => {
  if (!ID) return callback([]);
  api.post(`/brandMap/${ID}`).then((response) => {
    callback(response.BrandMap);
  }).catch((error) => {
    debug.log(error);
    callback([]);
  });
}


//NOT TESTED
export const getIngredientList = (list) => {
  // TODO: why is this a POST?
  return api.post('/brandMap', {
    data: Object.keys(list),
  }).then((data) => {
    return Promise.resolve(data.data.BrandMap);
  }).catch((error) => {
    return Promise.resolve({});
  })
}


//Function Not Used
// export const getUniqueUsername = (username) => {
//   if (username.length < 7){
//     username = `chef_${username}`
//   }
//   if (username.length < 7){
//     username = `${username}01`
//   }
//   return api.get(`/userAccount/${username}/check`)
//     .then((response) => {
//       if (response.UserAccount === 'username is available') {
//         return username;
//       } else {
//         let random = Math.floor((Math.random() * 10) + 1);
//         // TODO: maybe we just have the backend come up with a unique username
//         return getUniqueUsername(username + random);
//       }
//     }).catch((error) => {
//       debug.error(error.response);
//       return -1;
//     });
// }

// Function Not Used
// export const getUserAccount = () => {
//   return api.get('/userAccount')
//     .then((data) => {
//       debug.log('account:', data.data.UserAccount);
//       return Promise.resolve(data.data.UserAccount);
//     }).catch((error) => {
//       debug.error(error);
//       return Promise.reject(error);
//     });
// }

// Function Not Used
// export const getAccountExists = () => {
//   // TODO: we're mixing layers here
//   return async (dispatch) => {
//     try {
//       api.get('/userAccount'
//       });
//       return true;
//     } catch (e) {
//       return false;
//     }
//   }
// }
