import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Dialog } from '@mui/material'
import { TitleComponent } from '../../components/TitleComponent'
import GroceryList from './GroceryList'
import BuyNow from './BuyNow'
import AddToPantry from './AddToPantry'
import { getIngredientList } from '../../helpers/API'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReactGA from 'react-ga4'


const Order = (props) => {
  const [groceryList, setGroceryList] = useState({})
  const [screen, setScreen] = useState('GroceryList')
  const [purchasePayload, setPurchasePayload] = useState({})
  const [purchaseList, setPurchaseList] = useState({})
  const plan = useSelector((state) => (state.planner.plan ? state.planner.plan : {}))
  const theme = useTheme()

  // responsible for determining if popup Dialog or not. Change to 'null' to remove
  const isDialog = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    var aggregate = {}
    for (var MealId in plan) {
      for (var i = plan[MealId]['Ingredients'].length - 1; i >= 0; i--) {
        if (plan[MealId]['Ingredients'][i]['FamilyID'] in aggregate) {
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['AggregateMetricWeight'] +=
            (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) * plan[MealId]['Ingredients'][i]['MetricWeight']
          if (MealId in aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn']) {
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId]['Count']++
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId]['MetricAmount'] +=
              (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
              plan[MealId]['Ingredients'][i]['MetricWeight']
          } else {
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId] = {
              Count: 1,
              Name: plan[MealId]['Name'],
              MetricAmount:
                (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
                plan[MealId]['Ingredients'][i]['MetricWeight']
            }
          }
        } else {
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']] = JSON.parse(
            JSON.stringify(plan[MealId]['Ingredients'][i])
          )
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['AggregateMetricWeight'] =
            (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) * plan[MealId]['Ingredients'][i]['MetricWeight']
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'] = {}
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId] = {
            Count: 1,
            Name: plan[MealId]['Name'],
            MetricAmount:
              (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
              plan[MealId]['Ingredients'][i]['MetricWeight']
          }
        }
      }
    }
    setGroceryList(aggregate)
  }, [plan])

  useEffect(() => {
    setScreen('GroceryList')
  }, [isDialog])

  const onBuyNow = () => {
    ReactGA.event({category: 'checkout', action: 'Begin Checkout'})
    var list = JSON.parse(JSON.stringify(groceryList))
    for (var ID in list) {
      if (list[ID]['Message'] !== 'In Cart') {
        delete list[ID]
      }
    }
    if (Object.keys(list).length) {
      var BrandMap = getIngredientList(list)

      var tempPurchaseList = {}
      var tempPurchasePayload = []
      for (var FoundationalID in list) {
        if (BrandMap === [] && FoundationalID in BrandMap) {
          var minimalPurchaseQuantity = Math.ceil(
            list[FoundationalID]['AggregateMetricWeight'] / BrandMap[FoundationalID]['MetricWeight']
          )
          var FoundationalToBrandRatio =
            list[FoundationalID]['AggregateMetricWeight'] / BrandMap[FoundationalID]['MetricWeight']
          tempPurchaseList[FoundationalID] = list[FoundationalID]
          tempPurchaseList[FoundationalID]['MetricWeight'] *= minimalPurchaseQuantity
          tempPurchaseList[FoundationalID]['MetricVolume'] *= minimalPurchaseQuantity
          if (purchaseList[FoundationalID]['UsMeasurements']) {
            for (var i = tempPurchaseList[FoundationalID]['UsMeasurements'].length - 1; i >= 0; i--) {
              tempPurchaseList[FoundationalID]['UsMeasurements'][i]['Amount'] *= minimalPurchaseQuantity
            }
          }
          tempPurchasePayload.push({
            name: BrandMap[FoundationalID]['AmazonDetail']['AmazonQuery'],
            asinOverride: BrandMap[FoundationalID]['AmazonDetail']['AmazonID'],
            quantityList: [
              {
                unit: BrandMap[FoundationalID]['AmazonDetail']['AmazonUnit'],
                amount: parseFloat(BrandMap[FoundationalID]['AmazonDetail']['AmazonAmount'] * FoundationalToBrandRatio)
              }
            ]
          })
        } else {
          //If no brand Mapping, just query based on Ingredient info.
          var Ratio = list[FoundationalID]['AggregateMetricWeight'] / list[FoundationalID]['MetricWeight']
          tempPurchaseList[FoundationalID] = list[FoundationalID]
          tempPurchaseList[FoundationalID]['MetricWeight'] *= Ratio
          tempPurchaseList[FoundationalID]['MetricVolume'] *= Ratio
          if (tempPurchaseList[FoundationalID]['UsMeasurements']) {
            for ( i = tempPurchaseList[FoundationalID]['UsMeasurements'].length - 1; i >= 0; i--) {
              tempPurchaseList[FoundationalID]['UsMeasurements'][i]['Amount'] *= Ratio
            }
          }
          tempPurchasePayload.push({
            name: list[FoundationalID]['Name'],
            quantityList: [{ unit: 'grams', amount: list[FoundationalID]['AggregateMetricWeight'] }]
          })
        }
      }
      setPurchasePayload({ ingredients: tempPurchasePayload })
      setPurchaseList(tempPurchaseList)
      setScreen('BuyNow')
    }
  }

  const onClose = () => {
    setScreen('GroceryList')
    props.onClose()
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'flex-start',
        flexDirection: 'column',
        position: 'relative'
      }}
      sx={{px: {xs: '4.13%', sm: 0}}}
  >
      <TitleComponent
        title='Grocery Shop Online'
        description='Recieve an aggregated ingredient list to either take to your favorite grocery store or to order online. All for no extra cost. '
      />
      {(screen === 'GroceryList' || isDialog) && <GroceryList onNext={onBuyNow} onClose={onClose} />}

      {screen === 'BuyNow' && !isDialog && (
        <BuyNow
          purchasePayload={purchasePayload}
          onPrev={() => {
            setScreen('GroceryList')
          }}
          onNext={() => {
            ReactGA.event({category: 'checkout', action: 'Go To AmazonFresh'})
            setTimeout(() => {
              setScreen('AddToPantry')
            }, 0.0)
          }}
          onClose={onClose}
        />
      )}
      {screen === 'AddToPantry' && !isDialog && <AddToPantry purchaseList={purchaseList} onClose={onClose} />}

      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(255,255,255,0.8)' } }}
        onClose={onClose}
        open={isDialog && screen !== 'GroceryList'}
        fullWidth
        PaperProps={{
          style: {
            display: 'flex',
            backgroundColor: '#FFFFFF',
            paddingLeft: 25,
            paddingRight: 25,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)'
          }
        }}
      >
        {screen === 'BuyNow' && isDialog && (
          <BuyNow
            purchasePayload={purchasePayload}
            onPrev={() => {
              setScreen('GroceryList')
            }}
            onNext={() => {
              setTimeout(() => {
                setScreen('AddToPantry')
              }, 0.0)
            }}
            onClose={onClose}
          />
        )}
        {screen === 'AddToPantry' && isDialog && <AddToPantry purchaseList={purchaseList} onClose={onClose} />}
      </Dialog>
    </Grid>
  )
}

export default Order
