import {
	RESET,
	FETCH_ACCOUNT_INFO,
	FETCH_ACCOUNT_ERROR,
	FETCH_IMAGE_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {
	account: {},
	allergy: [],
	appliance: [],
	followers: [],
	following: [],
	group: [],
	response: null,
	errorMsg: '',
	avatarHash: ''
}

const userreducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_ACCOUNT_INFO:
			return {
				...state,
				account: action.account,
				allergy: action.allergy,
				appliance: action.appliance,
				followers: action.followers,
				following: action.following,
				group: action.group,
				response: 200,
				errorMsg: '',
				avatarHash: action.avatarHash
			}

		case FETCH_ACCOUNT_ERROR:
			return {
				...state,
				response: action.error,
				errorMsg: action.errorMsg
			}

		case FETCH_IMAGE_SUCCESS:
			return{
				...state,
				avatarHash: action.avatarHash
			}

		case RESET:
			return INITIAL_STATE

		default:
			return state
	}
}
export default userreducer
