import { FETCH_ACCOUNT_INFO, FETCH_ACCOUNT_ERROR, FETCH_IMAGE_SUCCESS, FETCH_IMAGE_ERROR } from './types'

import configureAxios from '../../helpers/configureAxios';

const api = configureAxios();

function receiveAccount(account, allergy, appliance, followers, following, group) {
  return {
    type: FETCH_ACCOUNT_INFO,
    account: account,
    allergy: allergy,
    appliance: appliance,
    followers: followers,
    following: following,
    group: group,
    avatarHash: Date.now()
  }
}

export const accountError = (error, errorMsg) => {
  return {
    type: FETCH_ACCOUNT_ERROR,
    error: error,
    errorMsg: errorMsg
  };
}

export const receiveAvatar = () => {
  console.log('here', Date.now())
  return {
    type: FETCH_IMAGE_SUCCESS,
    avatarHash: Date.now()
  };
}

export const avatarError = (error, errorMsg) => {
  return {
    type: FETCH_IMAGE_Error,
    error: error,
    errorMsg: errorMsg
  };
}


export const upgradeToChef = (userID) => {
  return async (dispatch) => {
      try {
        await api.put(`/userProfile/upgrade/${userID}`)
        .then(() => {
          dispatch(getAccount())
        })
      } catch (e) {
        console.log('Could Not Upgrade This Account At This Time', e)
      }
  }
}


// Verified Works with new API
export const getAccount = (catchMode = true) => {
  return async (dispatch) => {
    try {
      await api.get('/userAccount', {})
        .then(response => response.data.UserAccount)
        .then((data) => {
          data['ID'] = data['ID'] ? data['ID'] : ''
          data['Username'] = data['Username'] ? data['Username'] : ''
          data['Name'] = data['Name'] ? data['Name'] : ''
          data['Email'] = data['Email'] ? data['Email'] : ''
          data['Birthday'] = data['Birthday'] ? data['Birthday'] : ''
          data['Zipcode'] = data['Zipcode'] ? data['Zipcode'] : ''
          data['Newsletter'] = data['Newsletter'] ? data['Newsletter'] : false
          data['Group'] = data['Group'] ? data['Group'] : ['customer']
          data['Appliance'] = data['Appliance'] ? data['Appliance'] : []
          data['Allergy'] = data['Allergy'] ? data['Allergy'] : []
          data['Link'] = data['Link'] ? data['Link'] : []
          data['Tags'] = data['Tags'] ? data['Tags'] : []
          data['Bio'] = data['Bio'] ? data['Bio'] : []
          var following = data['Following'] ? data['Following'] : []
          var followers = data['Followers'] ? data['Followers'] : []
          dispatch(receiveAccount(data, data['Allergy'], data['Appliance'], followers, following, data['Group']))
      })
    } catch (e) {
        const errorMsg = e.message || e.description || 'Unspecified error'
        console.log({e})
        dispatch(accountError(e.response.status, errorMsg))
    }
  }
}

// Verified Works with new API
export const putAccount = (body) => {
  return async (dispatch) => {
    try {
      await api.put(
        '/userAccount',
        body
      ).then(() => {
        dispatch(getAccount())
      })
    } catch (e) {
      console.log({e})
      // TODO: Error Catch everything
      // const errorMsg = e.message || e.description || 'Unspecified error'
      // dispatch(accountError(e.response.status, errorMsg))
    }
  }
}

// Verified Works with new API
export const patchUsername = (body) => {
  return async (dispatch) => {
    try {
      await api.put(
        '/userAccount/username',
        body
      ).then(() => {
        dispatch(getAccount())
      })
    } catch (e) {
      console.log(e)
    }
  }
}

// Verified Works with new API
export const follow = (chefID) => {
  return async (dispatch) => {
    try {
      await api.put(`/userProfile/follow/${chefID}`)
      .then(() => {
        dispatch(getAccount())
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const unfollow = (chefID) => {
  return async (dispatch) => {
    try {
      await api.put(`/userProfile/unfollow/${chefID}`)
      .then(() => {
        dispatch(getAccount())
      })
    } catch (e) {
      console.log(e)
    }
  }
}

// Verified Works with new API
export const postAccount = (data) => {
  // Ensure standard data on transmission
  data['Username'] = data['Username'] ? data['Username'] : ''
  data['Name'] = data['Name'] ? data['Name'] : ''
  data['Email'] = data['Email'] ? data['Email'] : ''
  data['Birthday'] = data['Birthday'] ? data['Birthday'] : ''
  data['Zipcode'] = data['Zipcode'] ? data['Zipcode'] : ''
  data['Newsletter'] = data['Newsletter'] ? data['Newsletter'] : true
  data['Appliance'] = data['Appliance'] ? data['Appliance'] : []
  data['Allergy'] = data['Allergy'] ? data['Allergy'] : []
  data['Link'] = data['Link'] ? data['Link'] : []
  data['Tags'] = data['Tags'] ? data['Tags'] : []
  data['Bio'] = data['Bio'] ? data['Bio'] : []

  return async (dispatch) => {
    try {
      await api.post(
        '/userAccount',
        data
      ).then(() => {
        dispatch(getAccount())
      })
    } catch (e) {
      console.log({e})
    }
  }
}

export const putAvatar = (blob) => {
  return async (dispatch) => {
      api.put(`/media/avatar`, blob, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(()=>{
        dispatch(receiveAvatar())
      })
      .error((e)=>{
         const errorMsg = e.message || e.description || 'Unspecified error'
         dispatch(avatarError(e.response.status, errorMsg))
      })
  }
}