import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Grid, Typography, IconButton, Button, Checkbox } from '@mui/material'
import { TitleComponent } from '../../components/TitleComponent'
import ConfirmActionModal from '../../components/ConfirmActionModal'
import { toFraction, getPantryCount, getCartCount } from '../../helpers/helpers'
import { updateDisplay, delPantry } from '../../actions'
import CloseIcon from '@mui/icons-material/Close'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

const Ingredient = ({key, amount, mealsIn, name, unit, display, message, onPress, metricUnits, aggregateMetricWeight }) => {
  const theme = useTheme()
  var mealUses = []
  for (var mealId in mealsIn) {
    let ratio = (mealsIn[mealId]['MetricAmount'] / aggregateMetricWeight)
    mealUses.push(
      <Typography
        noWrap={true}
        variant='body2'
        style={{
          ...{ fontFamily: 'Roboto, "sans-serif"', color: '#555', fontSize: 10 },
          ...((message === 'In Pantry' || message === '') && { color: '#D0D0D0' }),
          ...(!metricUnits && { textTransform: 'capitalize' })
        }}
      >
        {toFraction(amount * ratio)}{unit !== 'g' && ' '}{unit} in {mealsIn[mealId]['Name']}
      </Typography>
    )
  }
  return (
    <Grid
      component={Button}
      onClick={onPress}
      style={{
        borderRadius: 15,
        padding: 10,
        marginTop: 15,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.03)',
        overflow: 'hidden'
      }}
    >
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <Checkbox
          icon={<AddCircleOutlineIcon style={{ color: '#D0D0D0' }} />}
          checkedIcon={
            message === 'In Pantry' ? (
              <RemoveCircleOutlineIcon style={{ color: '#D0D0D0' }} />
            ) : (
              <CircleCheckedFilled style={{ color: theme.palette.primary.main }} />
            )
          }
          checked={display}
          color='secondary'
        />
      </Grid>
      <Grid item xs={7} style={{ textAlign: 'left', overflow: 'hidden', display: 'block' }}>
        <Typography
          variant='body1'
          noWrap={true}
          style={{
            ...{ fontFamily: 'Roboto, "sans-serif"', color: '#333335', fontWeight: 'bold' },
            ...((message === 'In Pantry' || message === '') && { color: '#D0D0D0' })
          }}
        >
          {name}
        </Typography>
        {mealUses}
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 10 }}>
        <Typography
          variant='body2'
          style={{
            ...{ fontSize: 13, fontWeight: 'bold' },
            ...((message === 'In Pantry' || message === '') && { color: '#D0D0D0' })
          }}
        >
          { '' + toFraction(amount)}
          <span style={{ ...(metricUnits ? {} : { textTransform: 'capitalize' }) }}> {unit}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}

const GroceryList = (props) => {
  const dispatch = useDispatch()
  const [groceryList, setGroceryList] = useState({})
  const [ingredient, setIngredient] = useState(null)
  const [removePantryModal, setRemovePantryModal] = useState(false)
  const [metricUnits, setMetricUnits] = useState(! window.location.hostname.includes('.com'))

  const pantry = useSelector((state) => state.planner.pantry)
  const plan = useSelector((state) => (state.planner.plan ? state.planner.plan : {}))

  useEffect(() => {
    var aggregate = {}
    for (var MealId in plan) {
      for (var i = plan[MealId]['Ingredients'].length - 1; i >= 0; i--) {
        if (plan[MealId]['Ingredients'][i]['FamilyID'] in aggregate) {
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['AggregateMetricWeight'] +=
            (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) * plan[MealId]['Ingredients'][i]['MetricWeight']
          if (MealId in aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn']) {
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId]['Count']++
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId]['MetricAmount'] +=
              (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
              plan[MealId]['Ingredients'][i]['MetricWeight']
          } else {
            aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId] = {
              Count: 1,
              Name: plan[MealId]['Name'],
              MetricAmount:
                (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
                plan[MealId]['Ingredients'][i]['MetricWeight']
            }
          }
        } else {
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']] = JSON.parse(
            JSON.stringify(plan[MealId]['Ingredients'][i])
          )
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['AggregateMetricWeight'] =
            (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) * plan[MealId]['Ingredients'][i]['MetricWeight']
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'] = {}
          aggregate[plan[MealId]['Ingredients'][i]['FamilyID']]['MealsIn'][MealId] = {
            Count: 1,
            Name: plan[MealId]['Name'],
            MetricAmount:
              (plan[MealId]['Servings'] / plan[MealId]['RecipeServings']) *
              plan[MealId]['Ingredients'][i]['MetricWeight']
          }
        }
      }
    }
    setGroceryList(aggregate)
  }, [plan])

  /*
  // Uncomment for 'May Need More'

  const addMoreToPantry = () => {
    var tempIngredient = JSON.parse(JSON.stringify(ingredient))
    var ratio = 1.2 * (tempIngredient['AggregateMetricWeight'] / tempIngredient['MetricWeight'] )
    delete tempIngredient['NutritionFacts']
    delete tempIngredient['Display']
    delete tempIngredient['Message']
    delete tempIngredient['PrepOptions']
    delete tempIngredient['Image']
    tempIngredient['MetricWeight'] *= ratio
    tempIngredient['MetricVolume'] *= ratio

    for (let i = 0; i < tempIngredient['UsMeasurements'].length; i++){
      tempIngredient['UsMeasurements'][i]['Amount'] *= ratio
    }
    delete tempIngredient['AggregateMetricWeight']
    delete tempIngredient['UsStandardAmount']
    delete tempIngredient['UsStandardUnit']
    delete tempIngredient['MealsIn']
    dispatch(postPantry([tempIngredient]))
    setAddPantryModal(false)
  }
  */

  const removeFromPantry = () => {
    var removeIDs = []
    removeIDs.push(ingredient['ID'])
    for (var i = pantry.length - 1; i >= 0; i--) {
      if (pantry[i]['FamilyID'] === ingredient['FamilyID']) {
         removeIDs.push(pantry[i]['ID'])
        break
      }
    }
    for (var x in removeIDs){
      dispatch(delPantry(removeIDs[x]))
      dispatch(updateDisplay(plan, ingredient['FamilyID'], true, 'In Cart'))
    }
    setRemovePantryModal(false)
  }

  const onIngredientToggle = (ing) => {
    if (ing['Message'] === 'In Pantry' || ing['Message'] === 'May Need More') {
      setRemovePantryModal(true)
      setIngredient(ing)
    }
    else if (ing['Message'] === 'In Cart') {
      dispatch(updateDisplay(plan, ing['FamilyID'], false, ''))
    } else if (ing['Message'] === '') {
      dispatch(updateDisplay(plan, ing['FamilyID'], true, 'In Cart'))
    }
    /*
    else if (ing['Message'] === 'May Need More') {
      setAddPantryModal(true)
      setIngredient(ing)
    }
  */
  }

  return (
    <Grid
      container
      style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}
    >
      <TitleComponent
        title='Grocery Shop Online'
        description='Recieve an aggregated ingredient list to either take to your favorite grocery store or to order online. All for no extra cost. '
      />

      <Grid container style={{ position: 'relative' }}>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', paddingBottom: 75 }}>
          <Grid style={{position: 'sticky', zIndex: 3, backgroundColor: '#ffffff'}} sx={{top: { xs: 0, sm: 60}}}>
          <Grid
            container
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: 25,
              paddingBottom: 10
            }}
          >
            <Typography variant='h5'> Shopping List ({getCartCount(groceryList)}) </Typography>
            <IconButton onClick={props.onClose} sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <CloseIcon style={{ fontSize: 22 }} />
            </IconButton>
          </Grid>
            <Grid container style={{ display: 'flex', alignitems: 'flex-start', justifyContent: 'flex-start', paddingBottom: 5}}>
              <Typography
                variant='body2'
                onClick={() => {
                  setMetricUnits(false)
                }}
                style={{ ...{ cursor: 'pointer' }, ...(metricUnits ? { color: '#858c98' } : { color: '#000000' }) }}
              >
                US
              </Typography>
              <Typography style={{ color: '#858c98' }}>&nbsp;/&nbsp;</Typography>
              <Typography
                variant='body2'
                onClick={() => {
                  setMetricUnits(true)
                }}
                style={{ ...{ cursor: 'pointer' }, ...(metricUnits ? { color: '#000000' } : { color: '#858c98' }) }}
              >
                Metric
              </Typography>
            </Grid>
          </Grid>

          {Object.keys(groceryList).map(function (key) {
            if (['In Cart', ''].includes(groceryList[key]['Message'])) {
              return (
                <Ingredient
                  key={key}
                  id={key}
                  onPress={() => {
                    onIngredientToggle(groceryList[key])
                  }}
                  name={groceryList[key]['Name']}
                  aggregateMetricWeight={groceryList[key]['AggregateMetricWeight']}
                  metricUnits={metricUnits}
                  unit={metricUnits ? (groceryList[key]['MetricVolume'] === 0 ? 'g' : 'mL') : groceryList[key]['UsMeasurements'][0]['Unit']}
                  message={groceryList[key]['Message']}
                  display={groceryList[key]['Display']}
                  mealsIn={groceryList[key]['MealsIn']}
                  amount={
                    metricUnits
                      ? groceryList[key]['MetricVolume'] === 0
                        ? groceryList[key]['AggregateMetricWeight']
                        : groceryList[key]['MetricVolume'] * (groceryList[key]['AggregateMetricWeight'] / groceryList[key]['MetricWeight'])
                        : groceryList[key]['UsMeasurements'][0]['Amount'] * (groceryList[key]['AggregateMetricWeight'] / groceryList[key]['MetricWeight'])
                    }
                />
              )
            }
            return null
          })}
          {getPantryCount(groceryList) > 0 && (
            <Typography variant='h5' style={{ paddingTop: 25 }}>
              Found In Your Pantry ({getPantryCount(groceryList)})
            </Typography>
          )}
          {Object.keys(groceryList).map(function (key) {
            if (['In Pantry'].includes(groceryList[key]['Message'])) {
              return (
                <Ingredient
                  key={key}
                  id={key}
                  onPress={() => {
                    onIngredientToggle(groceryList[key])
                  }}
                  name={groceryList[key]['Name']}
                  metricUnits={metricUnits}
                  aggregateMetricWeight={groceryList[key]['AggregateMetricWeight']}
                  unit={metricUnits ? (groceryList[key]['MetricVolume'] === 0 ? 'g' : 'mL') : groceryList[key]['UsMeasurements'][0]['Unit']}
                  message={groceryList[key]['Message']}
                  display={groceryList[key]['Display']}
                  mealsIn={groceryList[key]['MealsIn']}
                  amount={
                    metricUnits
                      ? groceryList[key]['MetricVolume'] === 0
                        ? groceryList[key]['AggregateMetricWeight']
                        : groceryList[key]['MetricVolume'] * (groceryList[key]['AggregateMetricWeight'] / groceryList[key]['MetricWeight'])
                        : groceryList[key]['UsMeasurements'][0]['Amount'] * (groceryList[key]['AggregateMetricWeight'] / groceryList[key]['MetricWeight'])
                    }
                />
              )
            }
            return null
          })}
        </Grid>

        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={4} style={{ paddingTop: 95, borderRadius: 15, textAlign: 'center'}} sx={{ display: { xs: 'none', md: 'flex' } }}>
           <div>
            <Grid className='recipe-card' onClick={props.onNext} item xs={12} style={{ marginLeft: 'auto', border: '1px solid rgba(0,0,0,0.04)', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)', borderRadius: 15, padding: 10, position: 'sticky', top: 85, cursor: 'pointer'}}>
              <Typography variant='h5' style={{ textAlign: 'center', paddingBottom: 15, color: '#555' }}>
                Amazon<b style={{ color: '#7abd25' }}>Fresh</b>
              </Typography>
              <Typography variant='body2' style={{ textAlign: 'center', fontSize: 11, paddingBottom: 15 }}>
                Continue to AmazonFresh to choose your products, arrange delivery, and pay for your cart.
              </Typography>
              <Button
                type='button'
                onClick={props.onNext}
                disableElevation
                fullWidth={true}
                variant='contained'
                color='primary'
              >
                Purchase Groceries Now
              </Button>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={1} />
        <Button
          type='button'
          onClick={props.onNext}
          disableElevation
          variant='contained'
          sx={{ width:'91.68%', display: { xs: 'block', md: 'none' } }}
          style={{ position: 'fixed', bottom: 10, zIndex: 500 }}
        >
          Continue to Purchase Shopping List
        </Button>
      </Grid>

      <ConfirmActionModal
        title='Remove Ingredient From Pantry?'
        description='Would you like to remove this ingredient from your pantry? This will add the ingredient to your shopping cart.'
        onConfirmText='Yes'
        onCloseText='Cancel'
        open={removePantryModal}
        onConfirm={removeFromPantry}
        onClose={() => {
          setRemovePantryModal(false)
        }}
      />
    </Grid>
  )
}

export default GroceryList
