import React from 'react'
import { Helmet } from 'react-helmet'
const TitleComponent = ({ title, description }) => {
    var defaultTitle = 'Salt\'nPrep'
    var defaultDescription =
        'Salt\'nPrep is a free service that automates and optimizes meal planning and grocery shopping to reduce food waste. Tell us what ingredients you have leftover and we\'ll give you endless recipes to choose from. Grocery Delivery is available in select places.'
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
            <meta name='description' content={description ? description : defaultDescription} />
        </Helmet>
    )
}
export { TitleComponent }
