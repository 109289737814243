import React from 'react'
import { Chip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import AddIcon from '@mui/icons-material/Add'


const Tag = ({selected, label, handleClick, key}) => {
    return (
      <Chip
        key={key}
        size='small'
        clickable={true}
        label={label}
        variant={'outlined'}
        onClick={handleClick}
        onDelete={true}
        deleteIcon={selected ? <DoneIcon style={{color: 'rgb(71, 77, 87)'}}/> : <AddIcon style={{color: 'rgba(71, 77, 87, 0.7)'}}/> }
        style={{
          ...{margin: '0px 8px 8px 0px', padding: '2px 8px', borderColor:'#d8daec', height: 24, fontSize: 14, backgroundColor: 'rgb(241, 245, 246)', color: 'rgb(71, 77, 87)'},
          ... selected ?
          { opacity: 1 }
          :
          { opacity: 0.4 }
        }}
      />
    )
  }
export default Tag