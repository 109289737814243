import {
  AUTH_USER,
  AUTH_IN_PROGRESS,
  UNAUTH_USER,
  AUTH_ERROR,
  CLEARDOWN
} from './types'

import Auth from './Auth'
import ReactGA from 'react-ga4'

const auth = new Auth();

export const signinUser = ({ email, password }, callback) => {
  return async (dispatch) => {
    try{
    dispatch({ type: AUTH_IN_PROGRESS });
    auth.login(email, password)
      .then(() => {
        dispatch({ type: AUTH_USER });
        ReactGA.event({category: 'authenticate', action: 'login'})
        return callback();
      })
      .catch((error) => {
        const errorMsg = error.description || error.message || 'Unspecified error'
        return dispatch(authError(errorMsg));
      })
    }
    catch(error){
        console.log(error)
    }
  }
}

export const socialSignOn = ({ connection }, callback) => {
    return async (dispatch) => {
      try{
      dispatch({ type: AUTH_IN_PROGRESS });
      auth.authorize(connection)
        .then(() => {
          dispatch({ type: AUTH_USER });
          ReactGA.event({category: 'authenticate', action: 'login'})
          return callback();
        })
        .catch((error) => {
          const errorMsg = error.description || error.message || 'Unspecified error'
          return dispatch(authError(errorMsg));
        })
      }
      catch(error){
          console.log(error)
      }
    }
  }
export const registerUser = ({ email, password }, callback) => {
    return async (dispatch) => {
      try{
      dispatch({ type: AUTH_IN_PROGRESS });
      auth.signup(email, password)
        .then(() => {
          return dispatch(signinUser({email, password}, callback))
        })
        .catch((error) => {
          const errorMsg = error.description || error.message || 'Unspecified error'
          return dispatch(authError(errorMsg));
        })
      }
      catch(error){
          console.log(error)
      }
    }
  }


export const authError = (error) => {
  const timestamp = Date.now();
  return {
    type: AUTH_ERROR,
    error,
    timestamp
  };
}

export function logout() {
  auth.logout();
  return { type: UNAUTH_USER };
}

export function cleardown() {
  return {
    type: CLEARDOWN
  };
}

export const resetErrorMsg = () => {
  return async (dispatch) => {
    dispatch(authError(''))
  }
}

export function handleAuthentication() {
  return function (dispatch) {
    auth.handleAuthentication()
      .then((response) => {
        return dispatch({ type: AUTH_USER, token: response.token, profile: response.profile });
      })
      .catch(err => {
        return dispatch({ type: UNAUTH_USER });
      });
  }
}

export function getAuthState() {
  return function (dispatch) {
    auth.isAuthenticated()
      .then((response) => {
        return dispatch({ type: AUTH_USER, token: response.token, profile: response.profile });
      })
      .catch(err => {
        return dispatch({ type: UNAUTH_USER });
      });
  }
}

