import { combineReducers } from 'redux'
import PlanReducer from './PlanReducer'
import UserReducer from './UserReducer'
import AuthReducer from './AuthReducer'
import ForgotReducer from './ForgotReducer'

export default combineReducers({
	planner: PlanReducer,
	user: UserReducer,
	auth: AuthReducer,
	forgot: ForgotReducer
})
