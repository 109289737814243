import { getAmazonImg, blobToBase64 } from '../components/CreateMealPage/canvasUtils'

export const TopTags = [
  { title: 'Dinners', filter: '&category=Dinners', type: 'category', src: 'dinner.jpg' },
  { title: 'Breakfasts', filter: '&category=Breakfasts', type: 'category', src: 'breakfast.jpg' },
  { title: 'Vegetarian', filter: '&tag=Vegetarian', type: 'tag', src: 'vegetarian.jpg' },
  { title: 'Salads', filter: '&tag=Salads', type: 'tag', src: 'salads.jpg' },
  { title: 'Smoothies', filter: '&category=Smoothies', type: 'category', src: 'smoothies.jpg' },
  { title: 'Mexican', filter: '&tag=Mexican', type: 'tag', src: 'mexican.jpg' },
  { title: 'Indian', filter: '&tag=Indian', type: 'tag', src: 'indian.jpg' }
]

export const IngredientCategories = [
  { value: 'Dairy and Egg Products', label: 'Dairy and Egg Products' },
  { value: 'Spices and Herbs', label: 'Spices and Herbs' },
  { value: 'Fats and Oils', label: 'Fats and Oils' },
  { value: 'Poultry Products', label: 'Poultry Products' },
  { value: 'Soups Sauces and Gravies', label: 'Soups Sauces and Gravies' },
  { value: 'Sausages and Luncheon Meats', label: 'Sausages and Luncheon Meats' },
  { value: 'Breakfast Cereals', label: 'Breakfast Cereals' },
  { value: 'Fruits and Fruit Juices', label: 'Fruits and Fruit Juices' },
  { value: 'Pork Products', label: 'Pork Products' },
  { value: 'Vegetables and Vegetable Products', label: 'Vegetables and Vegetable Products' },
  { value: 'Nut and Seed Products', label: 'Nut and Seed Products' },
  { value: 'Beef Products', label: 'Beef Products' },
  { value: 'Beverages', label: 'Beverages' },
  { value: 'Finfish and Shellfish Products', label: 'Finfish and Shellfish Products' },
  { value: 'Legumes and Legume Products', label: 'Legumes and Legume Products' },
  { value: 'Lamb Veal and Game Products', label: 'Lamb Veal and Game Products' },
  { value: 'Baked Products', label: 'Baked Products' },
  { value: 'Sweets', label: 'Sweets' },
  { value: 'Cereal Grains and Pasta', label: 'Cereal Grains and Pasta' },
  { value: 'Snacks', label: 'Snacks' },
  { value: 'Fast Foods', label: 'Fast Foods' },
  { value: 'Meals Entrees and Side Dishes', label: 'Meals Entrees and Side Dishes' },
  { value: 'Alcoholic Beverages', label: 'Alcoholic Beverages' },
  { value: 'American Indian/Alaska Native Foods', label: 'American Indian/Alaska Native Foods' },
  { value: 'Restaurant Foods', label: 'Restaurant Foods' },
  { value: 'Quality Control Materials', label: 'Quality Control Materials' },
  { value: 'Baby Foods', label: 'Baby Foods' }
]

export const MealCategories = [
  { value: 'Breakfasts', label: 'Breakfasts', filter: '&category=Breakfasts' },
  { value: 'Dinners', label: 'Dinners', filter: '&category=Dinners' },
  { value: 'Desserts', label: 'Desserts', filter: '&category=Desserts' },
  { value: 'Sides', label: 'Sides', filter: '&category=Sides' },
  { value: 'Smoothies', label: 'Smoothies', filter: '&category=Smoothies' },
  { value: 'Snacks', label: 'Snacks', filter: '&category=Snacks' },
  { value: 'Drinks', label: 'Drinks', filter: '&category=Drinks' },
  { value: 'Appetizers', label: 'Appetizers', filter: '&category=Appetizers' },
  { value: 'Mains', label: 'Mains', filter: '&category=Mains' },
  { value: 'Lunches', label: 'Lunches', filter: '&category=Lunches' },
  { value: 'Salads', label: 'Salads', filter: '&category=Salads' },
  { value: 'Sandwhiches', label: 'Sandwhiches', filter: '&category=Sandwhiches' },
  { value: 'Soup, Stews & Chilis', label: 'Soup, Stews & Chilis', filter: '&category=Soup+Stews+&+Chilis' }
]
export const MealCategoriesEnum = [
  'Breakfasts', 'Dinners', 'Desserts', 'Sides'
]
export const IngredientTags = [
  { value: 'Meat', label: 'Meat' },
  { value: 'Butcher', label: 'Butcher' },
  { value: 'Vegan', label: 'Vegan' },
  { value: 'Vegetarian', label: 'Vegetarian' },
  { value: 'Nut Free', label: 'Nut Free' },
  { value: 'Contains Peanuts', label: 'Contains Peanuts' },
  { value: 'Canned', label: 'Canned' },
  { value: 'Frozen', label: 'Frozen' },
  { value: 'Prepared', label: 'Prepared' },
  { value: 'Deli', label: 'Deli' },
  { value: 'Produce', label: 'Produce' },
  { value: 'Gluten Free', label: 'Gluten Free' },
  { value: 'Whole Wheat', label: 'Whole Wheat' }
]
export const DietTags = [
  { value: 'Seafood', label: 'Seafood', filter: '&tag=Seafood' },
  { value: 'Vegetarian', label: 'Vegetarian', filter: '&tag=Vegetarian' },
  { value: 'Vegan', label: 'Vegan', filter: '&tag=Vegan' },
  { value: 'Keto', label: 'Keto', filter: '&tag=Keto' },
  { value: 'Low Calorie', label: 'Low Calorie', filter: '&tag=Low+Calorie' },
  { value: 'Low Carb', label: 'Low Carb', filter: '&tag=Low+Carb' },
  { value: 'Whole30', label: 'Whole30', filter: '&tag=Whole30' },
  { value: 'Gluten Free', label: 'Gluten Free', filter: '&tag=Gluten+Free' },
  { value: 'Under 30', label: 'Under 30', filter: '&tag=Under+30' },
  { value: 'Quick & Easy', label: 'Quick & Easy', filter: '&tag=Quick+&+Easy' },
  { value: 'Family', label: 'Family', filter: '&tag=Family' },
  { value: 'Kid Friendly', label: 'Kid Friendly', filter: '&tag=Kid+Friendly' }
]
export const MealTags = [
  { value: 'American', label: 'American', filter: '&tag=American' },
  { value: 'Korean', label: 'Korean', filter: '&tag=Korean' },
  { value: 'Vietnamese', label: 'Vietnamese', filter: '&tag=Vietnamese' },
  { value: 'Italian', label: 'Italian', filter: '&tag=Italian' },
  { value: 'Chinese', label: 'Chinese', filter: '&tag=Chinese' },
  { value: 'Greek', label: 'Greek', filter: '&tag=Greek' },
  { value: 'Indian', label: 'Indian', filter: '&tag=Indian' },
  { value: 'Mexican', label: 'Mexican', filter: '&tag=Mexican' },
  { value: 'Spanish', label: 'Spanish', filter: '&tag=Spanish' },
  { value: 'Thai', label: 'Thai', filter: '&tag=Thai' },
  { value: 'Japanese', label: 'Japanese', filter: '&tag=Japanese' },
  { value: 'African', label: 'African', filter: '&tag=African' },
  { value: 'Nigerian', label: 'Nigerian', filter: '&tag=Nigereian' },
  { value: 'Turkish', label: 'Turkish', filter: '&tag=Turkish' },
  { value: 'Mediterranean', label: 'Mediterranean', filter: '&tag=Mediterranean' },
  { value: 'Halal', label: 'Halal', filter: '&tag=Halal' },
  { value: 'French', label: 'French', filter: '&tag=French' },
  { value: 'European', label: 'European', filter: '&tag=European' },
  { value: 'Russian', label: 'Russian', filter: '&tag=Russian' },
  { value: 'Peruvian', label: 'Peruvian', filter: '&tag=Peruvian' },
  { value: 'Indonesian', label: 'Indonesian', filter: '&tag=Indonesian' },
  { value: 'Brazilian', label: 'Brazilian', filter: '&tag=Brazilian' },
  { value: 'Iranian', label: 'Iranian', filter: '&tag=Iranian' },
  { value: 'Pakistani', label: 'Pakistani', filter: '&tag=Pakistani' },
  { value: 'Tex Mex', label: 'Tex Mex', filter: '&tag=Tex+Mex' },
  { value: 'Afghan', label: 'Afghan', filter: '&tag=Afghan' },
  { value: 'Seafood', label: 'Seafood', filter: '&tag=Seafood' },
  { value: 'Vegetarian', label: 'Vegetarian', filter: '&tag=Vegetarian' },
  { value: 'Vegan', label: 'Vegan', filter: '&tag=Vegan' },
  { value: 'Keto', label: 'Keto', filter: '&tag=Keto' },
  { value: 'Low Calorie', label: 'Low Calorie', filter: '&tag=Low+Calorie' },
  { value: 'Low Carb', label: 'Low Carb', filter: '&tag=Low+Carb' },
  { value: 'Whole30', label: 'Whole30', filter: '&tag=Whole30' },
  { value: 'Gluten Free', label: 'Gluten Free', filter: '&tag=Gluten+Free' },
  { value: 'Under 30', label: 'Under 30', filter: '&tag=Under+30' },
// { value: 'Quick & Easy', label: 'Quick & Easy', filter: '&tag=Quick+&+Easy' },
  { value: 'Family', label: 'Family', filter: '&tag=Family' },
  { value: 'Kid Friendly', label: 'Kid Friendly', filter: '&tag=Kid+Friendly' }
]
export const DifficultyTags = [
  { value: 'Beginner', label: 'Beginner', filter: '&tag=Beginner' },
  { value: 'Intermediate', label: 'Intermediate', filter: '&tag=Intermediate' },
  { value: 'Advanced', label: 'Advanced', filter: '&tag=Advanced' }
]
export const DifficultyEnum = [
'Beginner', 'Intermediate', 'Advanced'
]

export const ApplianceTags = [
  { value: 'Crockpot', label: 'Crockpot' },
  { value: 'Oven', label: 'Oven' },
  { value: 'Stove', label: 'Stove' },
  { value: 'Air Fryer', label: 'Air Fryer' },
  { value: 'Deep Fryer', label: 'Deep Fryer' },
  { value: 'Toaster', label: 'Toaster' },
  { value: 'Skillet', label: 'Skillet' },
  { value: 'Blender', label: 'Blender' },
  { value: 'Pasta Maker', label: 'Pasta Maker' },
  { value: 'Microwave', label: 'Microwave' },
  { value: 'Grill', label: 'Grill' },
  { value: 'Food Processor', label: 'Food Processor' },
  { value: 'Pressure Cooker', label: 'Pressure Cooker' },
  { value: 'Instant Pot', label: 'Instant Pot' },
  { value: 'Rice Cooker', label: 'Rice Cooker' },
  { value: 'Hand Blender', label: 'Hand Blender' },
  { value: 'Bread Maker', label: 'Bread Maker' },
  { value: 'Thermometer', label: 'Thermometer' },
  { value: 'Large Pot', label: 'Large Pot' },
  { value: 'Pot', label: 'Pot' },
  { value: 'Pan', label: 'Pan' },
  { value: 'Frying Pan', label: 'Frying Pan' },
  { value: 'Saucepan', label: 'Saucepan' },
  { value: 'Large Saucepan', label: 'Large Saucepan' },
  { value: 'Large Pan', label: 'Large Pan' },
  { value: 'Whisk', label: 'Whisk' },
  { value: 'Bowl', label: 'Bowl' },
  { value: 'Large Bowl', label: 'Large Bowl' },
  { value: 'Dutch Oven', label: 'Dutch Oven' },
  { value: 'Hand Mixer', label: 'Hand Mixer' },
  { value: 'Stand Mixer', label: 'Stand Mixer' },
  { value: 'Mixer', label: 'Mixer' }
]

export const MyUnits = [
  { value: 'count', label: 'Count' },
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
  { value: 'ounce', label: 'Ounce' },
  { value: 'pound', label: 'Pound' },
  { value: 'clove', label: 'Clove' },
  { value: 'bunch', label: 'Bunch' },
  { value: 'slice', label: 'Slice' },
  { value: 'stalk', label: 'Stalk' },
  { value: 'leaves', label: 'Leaves' },
  { value: 'sprigs', label: 'Sprigs' },
  { value: 'scoop', label: 'Scoop' },
  { value: 'kilogram', label: 'Kilogram' },
  { value: 'gram', label: 'Gram' },
  { value: 'liter', label: 'Liter' },
  { value: 'milliliter', label: 'Milliliter' },
  { value: 'quart', label: 'Quart' },
  { value: 'tbsp', label: 'Tbsp' },
  { value: 'tsp', label: 'Tsp' },
  { value: 'cup', label: 'Cup' },
  { value: 'gallon', label: 'Gallon' },
  { value: 'fluid ounce', label: 'Fluid Ounce' },
  { value: 'pint', label: 'Pint' },
  { value: 'drop', label: 'Drop' }
]
export const MyMacros = [
  { value: 'Energy', label: 'Energy' },
  { value: 'Protein', label: 'Protein' },
  { value: 'Carbohydrate by difference ', label: 'Carbohydrate by difference' },
  { value: 'Total lipid (fat)', label: 'Total lipid (fat)' },
  { value: 'Fiber total dietary', label: 'Fiber total dietary' },
  { value: 'Sugars total including NLEA', label: 'Sugars total including NLEA' },
  { value: 'Calcium Ca', label: 'Calcium Ca' },
  { value: 'Iron Fe', label: 'Iron Fe' },
  { value: 'Water', label: 'Water' },
  { value: 'Magnesium Mg', label: 'Magnesium Mg' },
  { value: 'Phosphorus P', label: 'Phosphorus P' },
  { value: 'Potassium K', label: 'Potassium K' },
  { value: 'Sodium Na', label: 'Sodium Na' },
  { value: 'Zinc Zn', label: 'Zinc Zn' },
  { value: 'Copper Cu', label: 'Copper Cu' },
  { value: 'Calories', label: 'Calories' },
  { value: 'Selenium Se', label: 'Selenium Se' },
  { value: 'Vitamin C total ascorbic acid', label: 'Vitamin C total ascorbic acid' },
  { value: 'Thiamin', label: 'Thiamin' },
  { value: 'Riboflavin', label: 'Riboflavin' },
  { value: 'Niacin', label: 'Niacin' },
  { value: 'Vitamin B-6', label: 'Vitamin B-6' },
  { value: 'Folate total', label: 'Folate total' },
  { value: 'Folic acid', label: 'Folic acid' },
  { value: 'Folate food', label: 'Folate food' },
  { value: 'Folate DFE', label: 'Folate DFE' },
  { value: 'Riboflavin', label: 'Riboflavin' },
  { value: 'Choline total', label: 'Choline total' },
  { value: 'Vitamin B-12', label: 'Vitamin B-12' },
  { value: 'Vitamin B-12 added', label: 'Vitamin B-12 added' },
  { value: 'Vitamin A RAE', label: 'Vitamin A RAE' },
  { value: 'Vitamin A IU', label: 'Vitamin A IU' },
  { value: 'Vitamin A', label: 'Vitamin A' },
  { value: 'Retinol', label: 'Retinol' },
  { value: 'Carotene beta', label: 'Carotene beta' },
  { value: 'Carotene alpha', label: 'Carotene alpha' },
  { value: 'Cryptoxanthin beta', label: 'Cryptoxanthin beta' },
  { value: 'Lycopene', label: 'Lycopene' },
  { value: 'Lutein + zeaxanthin', label: 'Lutein + zeaxanthin' },
  { value: 'Vitamin E (alpha-tocopherol)', label: 'Vitamin E (alpha-tocopherol)' },
  { value: 'Vitamin E added', label: 'Vitamin E added' },
  { value: 'Vitamin D (D2 + D3)', label: 'Vitamin D (D2 + D3)' },
  { value: 'Vitamin K (phylloquinone)', label: 'Vitamin K (phylloquinone)' },
  { value: 'Fatty acids total saturated', label: 'Fatty acids total saturated' },
  { value: 'Fatty acids total monounsaturated', label: 'Fatty acids total monounsaturated' },
  { value: 'Fatty acids total polyunsaturated', label: 'Fatty acids total polyunsaturated' },
  { value: 'Cholesterol', label: 'Cholesterol' },
  { value: 'Alcohol ethyl', label: 'Alcohol ethyl' },
  { value: 'Caffeine', label: 'Caffeine' },
  { value: 'Theobromine', label: 'Theobromine' },
  { value: 'Pantothenic Acid', label: 'Pantothenic Acid' },
  { value: 'Manganese Mn', label: 'Manganese Mn' },
  { value: 'Ash', label: 'Ash' },
  { value: 'Betaine', label: 'Betaine' },
  { value: 'Vitamin D3 (cholecalciferol)', label: 'Vitamin D3 (cholecalciferol)' },
  { value: 'Fatty acids total trans', label: 'Fatty acids total trans' },
  { value: 'Fatty acids total trans-monoenoic', label: 'Fatty acids total trans-monoenoic' },
  { value: 'Tryptophan', label: 'Tryptophan' },
  { value: 'Threonine', label: 'Threonine' },
  { value: 'Isoleucine', label: 'Isoleucine' },
  { value: 'Leucine', label: 'Leucine' },
  { value: 'Lysine', label: 'Lysine' },
  { value: 'Methionine', label: 'Methionine' },
  { value: 'Cystine', label: 'Cystine' },
  { value: 'Phenylalanine', label: 'Phenylalanine' },
  { value: 'Tyrosine', label: 'Tyrosine' },
  { value: 'Valine', label: 'Valine' },
  { value: 'Arginine', label: 'Arginine' },
  { value: 'Histidine', label: 'Histidine' },
  { value: 'Aspartic acid', label: 'Aspartic acid' },
  { value: 'Glutamic acid', label: 'Glutamic acid' },
  { value: 'Glycine', label: 'Glycine' },
  { value: 'Proline', label: 'Proline' },
  { value: 'Alanine', label: 'Alanine' },
  { value: 'Serine', label: 'Serine' },
  { value: 'Hydroxyproline', label: 'Hydroxyproline' },
  { value: 'Serine', label: 'Serine' }
]
export const IngredientPrepOptions = [
  { value: 'Diced', label: 'Diced' },
  { value: 'Finely Diced', label: 'Finely Diced' },
  { value: 'Minced', label: 'Minced' },
  { value: 'Grated', label: 'Grated' },
  { value: 'Minced or Grated', label: 'Minced or Grated' },
  { value: 'Chopped', label: 'Chopped' },
  { value: 'Finely Chopped', label: 'Finely Chopped' },
  { value: 'Roughly Chopped', label: 'Roughly Chopped' },
  { value: 'Sliced', label: 'Sliced' },
  { value: 'Whole', label: 'Whole' },
  { value: 'Juiced', label: 'Juiced' },
  { value: 'Peeled', label: 'Peeled' },
  { value: 'Soaked', label: 'Soaked' },
  { value: 'Grated', label: 'Grated' },
  { value: 'Melted', label: 'Melted' },
  { value: 'Cubed', label: 'Cubed' },
  { value: 'Crumbled', label: 'Crumbled' },
  { value: 'Shredded', label: 'Shredded' },
  { value: 'Beaten', label: 'Beaten' },
  { value: 'Drained', label: 'Drained' },
  { value: 'Cooked', label: 'Cooked' },
  { value: 'Baked', label: 'Baked' },
  { value: 'Fried', label: 'Fried' },
  { value: 'Grilled', label: 'Grilled' },
  { value: 'Ribboned', label: 'Ribboned' },
  { value: 'Deseeded', label: 'Deseeded' },
  { value: 'Halved', label: 'Halved' },
  { value: 'Roughly Torn', label: 'Roughly Torn' },
  { value: 'Torn', label: 'Torn' },
  { value: 'Quartered', label: 'Quartered' },
  { value: 'Smashed', label: 'Smashed' },
  { value: 'Cut into 1/2 in pieces', label: 'Cut into 1/2 in pieces' },
  { value: 'Cut into 1 in pieces', label: 'Cut into 1 in pieces' },
  { value: 'Cut into 1/4 in pieces', label: 'Cut into 1/4 in pieces' },
  { value: 'Peeled and Smashed', label: 'Peeled and Smashed' }
]

export const IMAGE_SIZES = {
  small: { width: 50, height: 33, ext: '50x33', quality: 0.6},
  original: { width: 1500, height: 1000, ext: '', quality: 1},
  large: { width: 750, height: 500, ext: '750x500', quality: 0.6},
  medium: { width: 300, height: 200, ext: '300x200', quality: 0.6},
}

export async function getBlob64(imageSrc, croppedAreaPixels, quality, width, height){
  let croppedImage = await getAmazonImg(
    imageSrc,
    croppedAreaPixels,
    quality,
    width,
    height
  )
  blobToBase64(croppedImage)
  .then((img)=>{
    Promise.resolve(img.replace('data:application/octet-stream;base64,', ''))
  })
  .catch((error)=>{
    console.log(error)
    Promise.reject(error)
})
}


export function toDecimal(number) {
  if (number.toFixed(2) - Math.floor(number)) {
    return number.toFixed(2)
  }
  return Math.floor(number)
}


export function toFraction(number) {
  number = (1.0 / 8) * Math.round(8 * number)
  var str = number.toString().split('.')

  if (parseInt(str[1])) {
    if (!number) {
      number = this
    }
    var whole = String(number).split('.')[0]
    number = parseFloat('.' + String(number).split('.')[1])
    var num = '1'
    for (var z = 0; z < String(number).length - 2; z++) {
      num += '0'
    }
    number = number * num
    num = parseInt(num)
    for (z = 2; z < number + 1; z++) {
      if (number % z === 0 && num % z === 0) {
        number = number / z
        num = num / z
        z = 2
      }
    }
    if (number === 33 && num === 100) {
      return '1/3'
    } else if (number === 66 && num === 100) {
      return '2/3'
    } else if (number === 33 && num === 50) {
      return '2/3'
    } else if (number === 99 && num === 100) {
      return '1'
    } else {
      return (whole === '0' ? '' : whole + ' ') + number + '/' + num
    }
  }
  return str[0]
}

export function toTitleCase(str) {
  str = str.replace(/\+&*/g, ' ')
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function myConvert(number, convertFrom, convertTo) {
  if (convertFrom === convertTo) {
    return parseFloat(number)
  }
  if (
    convertTo.toLowerCase() === 'drop' ||
    convertFrom.toLowerCase() === 'drop' ||
    convertTo.toLowerCase() === 'scoop' ||
    convertFrom.toLowerCase() === 'scoop' ||
    convertTo.toLowerCase() === 'slice' ||
    convertTo.toLowerCase() === 'sprigs' ||
    convertTo.toLowerCase() === 'large' ||
    convertTo.toLowerCase() === 'medium' ||
    convertTo.toLowerCase() === 'small' ||
    convertFrom.toLowerCase() === 'slice' ||
    convertFrom.toLowerCase() === 'sprigs' ||
    convertFrom.toLowerCase() === 'small' ||
    convertFrom.toLowerCase() === 'medium' ||
    convertFrom.toLowerCase() === 'large' ||
    convertFrom.toLowerCase() === 'count' ||
    convertFrom.toLowerCase() === 'clove' ||
    convertFrom.toLowerCase() === 'bunch' ||
    convertTo.toLowerCase() === 'count' ||
    convertTo.toLowerCase() === 'clove' ||
    convertTo.toLowerCase() === 'bunch'
  ) {
    return parseFloat(number)
  }
  const convertUnits = [
    { value: 'us_tbsp', label: 'Tbs' },
    { value: 'us_tsp', label: 'tsp' },
    { value: 'us_cup', label: 'cup' },
    { value: 'us_g', label: 'gal' },
    { value: 'us_oz', label: 'fl-oz' },
    { value: 'us_pint', label: 'pnt' },
    { value: 'us_qt', label: 'qt' },
    { value: 'l', label: 'l' },
    { value: 'ml', label: 'ml' },
    { value: 'oz', label: 'oz' },
    { value: 'g', label: 'g' },
    { value: 'lb', label: 'lb' },
    { value: 'Count', label: 'Count' },
    { value: 'Clove', label: 'Cloves' },
    { value: 'Bunch', label: 'Bunches' },
    { value: 'Small', label: 'Small' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Large', label: 'Large' },
    { value: 'Tbsp', label: 'Tbs' },
    { value: 'Tsp', label: 'tsp' },
    { value: 'Cup', label: 'cup' },
    { value: 'Gallon', label: 'gal' },
    { value: 'Fluid Ounce', label: 'fl-oz' },
    { value: 'Pint', label: 'pnt' },
    { value: 'Quart', label: 'qt' },
    { value: 'Liter', label: 'l' },
    { value: 'Milliliter', label: 'ml' },
    { value: 'Ounces', label: 'oz' },
    { value: 'Ounce', label: 'oz' },
    { value: 'Grams', label: 'g' },
    { value: 'Gram', label: 'g' },
    { value: 'Pound', label: 'lb' },
    { value: 'Milligram', label: 'mg' },
    { value: 'Kilogram', label: 'kg' },
    { value: 'mg', label: 'mg' },
    { value: 'kg', label: 'kg' },
    { value: 'Slice', label: 'Slice' },
    { value: 'Sprigs', label: 'Sprigs' },
    { value: 'Drop', label: 'Drop' }
  ]
  var convert = require('convert-units')
  var newFrom,
    newTo = ''
  for (var x = 0; x < convertUnits.length; x++) {
    if (convertUnits[x]['value'].toLowerCase() === convertFrom.toLowerCase()) {
      newFrom = convertUnits[x]['label']
    }
    if (convertUnits[x]['value'].toLowerCase() === convertTo.toLowerCase()) {
      newTo = convertUnits[x]['label']
    }
  }
  return convert(parseFloat(number)).from(newFrom).to(newTo)
}
export function getUnit(unit) {
  const pyUnits = [
    { value: 'us_tbsp', label: 'Tbsp' },
    { value: 'us_tsp', label: 'Tsp' },
    { value: 'us_cup', label: 'Cup' },
    { value: 'us_g', label: 'Gallon' },
    { value: 'us_oz', label: 'Fluid Ounce' },
    { value: 'us_pint', label: 'Pint' },
    { value: 'us_qt', label: 'Quart' },
    { value: 'l', label: 'Liter' },
    { value: 'ml', label: 'Milliliter' },
    { value: 'oz', label: 'Ounces' },
    { value: 'g', label: 'Grams' },
    { value: 'lb', label: 'Pounds' },
    { value: 'count', label: 'Count' },
    { value: 'cloves', label: 'Cloves' },
    { value: 'bunches', label: 'Bunches' },
    { value: 'mg', label: 'Milligram' },
    { value: 'kg', label: 'Kilogram' }
  ]
  for (var x = 0; x < pyUnits.length; x++) {
    if (pyUnits[x]['value'] === unit) {
      return pyUnits[x]['label']
    }
  }
  return null
}


export function cleanUsername(username){
  if (username.length){
    return username.match(/[a-zA-Z0-9_]+/).toString()
  }
  return ''
}


export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateLowerCase(input){
  var lowerCaseLetters = /[a-z]/g
  if(input.match(lowerCaseLetters)){
    return true
  }
  return false
}

export function validateUpperCase(input){
  var upperCaseLetters = /[A-Z]/g
  if(input.match(upperCaseLetters)){
    return true
  }
  return false
}

export function validateNumber(input){
  var numbers = /[0-9]/g
  if(input.match(numbers)){
    return true
  }
  return false
}

export function validateLength(input){
  return input.length >= 8
}

export function validateSpecial(input){
  var specialChars = /[!@#$%^&*]/g
  if(input.match(specialChars)){
    return true
  }
  return false
}

export function validateWebsite(website) {
  var re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  return re.test(String(website).toLowerCase())
}


export function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toGMTString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
export function truncateText(text, length) {
  if (text.length <= length) {
    return text
  }

  return text.substr(0, length) + '\u2026'
}

export function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function checkCookie(cname) {
  var user = getCookie(cname)
  if (user !== '') {
    return true
  } else {
    return false
  }
}
export function isFollowingUser(followings, user) {
  for (var i = followings.length - 1; i >= 0; i--) {
    if (followings[i]['Name'].toLowerCase() === user.toLowerCase()) {
      return true
    }
  }
  return false
}

export function formatOrderDateTime(time){
  var month = ['Jan ', 'Feb ', 'Mar ', 'Apr ', 'May ', 'June ', 'July ', 'Aug ', 'Sep ', 'Oct ', 'Nov ', 'Dec ']
  var date = new Date(parseInt(time))
  return month[date.getMonth()] + date.getDate().toString()
}

export function compareMealObjects(o1, o2) {
  var singleCompare = ['Name', 'Category', 'Difficulty', 'Description', 'PrepTime', 'CookTime', 'Servings', 'BlogSite']
  for (var x in singleCompare) {
    var item = singleCompare[x]
    if (o1[item] !== o2[item]) {
      return false
    }
  }

  var arrayCompare = ['Tags', 'Appliances', 'FoundationIngredientList', 'InstructionList']
  for (x in arrayCompare) {
    item = arrayCompare[x]
    if (o1[item].length !== o2[item].length) {
      return false
    }
  }

  var ret = true
  //compare Ingredients, Instructions, Tags, Appliances
  ;['Tags', 'Appliances'].forEach((i) => {
    for ( x in o1[i]) {
      if (o1[i][x] !== o2[i][x]) {
        ret = false
      }
    }
  })
  for (x in o1['FoundationIngredientList']) {
    if (o1['FoundationIngredientList'][x]['ID'] !== o2['FoundationIngredientList'][x]['ID']) {
      ret = false
    }
    if (o1['FoundationIngredientList'][x]['MetricWeight'] !== o2['FoundationIngredientList'][x]['MetricWeight']) {
      ret = false
    }
  }
  for (x in o1['InstructionList']) {
    if (o1['InstructionList'][x]['Step'] !== o2['InstructionList'][x]['Step']) {
      ret = false
    }
    if (o1['InstructionList'][x]['Description'] !== o2['InstructionList'][x]['Description']) {
      ret = false
    }
    if (o1['InstructionList'][x]['Title'] !== o2['InstructionList'][x]['Title']) {
      ret = false
    }
  }
  return ret
}

export const getPantryCount = (list) => {
  var count = 0
  if (list === undefined || list === {}) {
    return count
  }
  for (var ID in list) {
    if (list[ID]['Message'] === 'In Pantry') {
      count++
    }
  }
  return count
}

export const getCartCount = (list) => {
  var count = 0
  if (list === undefined || list === {}) {
    return count
  }
  for (var ID in list) {
    if (list[ID]['Message'] === 'In Cart' || list[ID]['Message'] === 'May Need More') {
      count++
    }
  }
  return count
}

export function parseJwt (token) {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.log(error)
    return token
  }

};