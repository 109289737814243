import React, { useEffect, useState } from 'react'
import {
    Typography,
    Grid,
    IconButton,
    Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PageWrapper from '../components/PageWrapper'
import Meal from '../components/Meal'
import {formatOrderDateTime} from '../helpers/helpers'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

function CookPage() {
    const navigate = useNavigate()
    const orders = useSelector((state) => state.planner.orders)
    const [index, setIndex] = useState(null)
    const [options, setOptions] = useState([])

    useEffect(()=>{
        if(Object.keys(orders).length){
            var tempOptions = []
            Object.keys(orders).map((id) => (tempOptions.push(formatOrderDateTime(id))))
            setOptions(tempOptions)
            setIndex(0)
        }
    },[orders])

    return (
        <PageWrapper>
        {index !== null ?
            <Grid item xs={12} md={12} style={{ paddingTop: 40}}>
                <Typography variant='h4' style={{paddingBottom: 35}}>Lets Start Cooking!</Typography>
                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <Typography variant='body1' >Your Meals from</Typography>
                    <IconButton disabled={index === 0} onClick={()=>{setIndex((i)=> i - 1)}} color='primary'><NavigateBeforeIcon style={{...{fontSize: 20},...index === 0 && {color:'lightgrey'}}}/></IconButton>
                    <Typography variant='body1'>{options[index]}</Typography>
                    <IconButton disabled={index === options.length - 1} onClick={()=>{setIndex((i)=> i + 1)}}><NavigateNextIcon style={{...{fontSize: 20},...index === options.length - 1 && {color:'lightgrey'}}}/></IconButton>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 35, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems:'flex-start', flexWrap: 'wrap' }}>
                    { orders[Object.keys(orders)[index]].map((meal) => {
                        return(
                        <Meal
                            key={meal['ID']}
                            meal={meal}
                            name={meal['Name']}
                            mealID={meal['ID']}
                            chefID={meal['ChefID']}
                            chefName={meal['ChefName']}
                            favoriteCount={meal['FavoriteCount']}
                            ingredients={[]}
                            mode='see'
                        />
                        )})}
                </Grid>
            </Grid>
        :
            <Grid item xs={12} sm={6} style={{margin:'auto', paddingTop: 45, textAlign: 'left'}}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center', paddingBottom: 35 }}>
                <Meal
                    disabled={true}
                    key={0}
                    meal={{}}
                    name={''}
                    mealID={1}
                    chefID={1}
                    chefName={''}
                    favoriteCount={0}
                    ingredients={[]}
                    mode='see'
                />
                </Grid>
                <Typography variant='h4' >It looks like you don't have any meals to cook...</Typography>
                <Typography variant='body1' style={{paddingTop: 25, paddingBottom: 25}}>Once you have checked out your first order, your meals will appear here.</Typography>
                <Button onClick={()=>{navigate('/plan')}} variant='contained' disableElevation size='large' style={{ borderRadius: 10, paddingLeft: 25, paddingTop: 10, paddingBottom: 10}}>Plan your Meals <NavigateNextIcon style={{color: 'white'}}/></Button>
            </Grid>
        }
        </PageWrapper>
    )
}

export default CookPage