import React from 'react'
import CrossfadeImage from 'react-crossfade-image'
import '../styles/helpers.css'
import env_config from '../env_config'

class DynamicImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image: this.props.placeholder
    }
  }
  componentDidMount() {
    var source = `https://${env_config.s3.BUCKET}.s3.amazonaws.com/${this.props.bucket}/${this.props.url}.jpg`
    if (this.props.jpgIncluded) {
      source = `https://${env_config.s3.BUCKET}.s3.amazonaws.com/${this.props.bucket}/${this.props.url}`
    }
    if (this.props.hash) {
      source += `?${Date.now()}`
    }
    const imageLoader = new Image()
    imageLoader.src = source
    imageLoader.onload = () => {
      this.setState({ image: source })
    }
  }

  render() {
    return (
      <CrossfadeImage
        style={{ ...{ width: '100%', height: '100%' }, ...this.props.style }}
        alt={this.props.alt}
        src={this.state.image}
      />
    )
  }
}
export default DynamicImage
