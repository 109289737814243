import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography, OutlinedInput
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { upgradeToChef } from '../actions'
import { sendUpdateEmail } from '../helpers/API'

export default function UpgradeModal(props) {
  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')
  const ID = useSelector((state) => state.user.account.ID)
  const email = useSelector((state) => state.user.account.Email)
  const name = useSelector((state) => state.user.account.Name)
  const username = useSelector((state) => state.user.account.Username)
  const dispatch = useDispatch()
  const [sent, setSent] = useState(false)

  const applyForCode = async() => {
    if (sent){
      setMessage('You have already applied. We will get back to you shortly.')
    }
    else {
      const body = `User ${name}, with Username ${username} and ID ${ID} is requesting a Chef Account.`
      const subject = 'Chef Account Upgrade Requested'
      try {
        sendUpdateEmail(subject, body, email)
        .then((response) => {
          setSent(true)
          setMessage('Application Sent - We will review your profile and get back to you shortly.')
        })
      } catch (e) {
        console.log({e})
        setMessage('An unexpected error occured. Please try again later.')
      }
    }
  }

  const submitActivationCode = ()=>{
    if (code === 'SALTCHEFBETA'){
      setSent(()=>true)
      try {
        dispatch(upgradeToChef(ID))
      }
      catch (e){
        setMessage('Could Not Upgrade This Account At This Time')
      }
    }
    else {
      setMessage('Invalid Code')
      setSent(()=>false)
    }

  }
  return (
    <Dialog
      open={props.open}
      onClose={props.onMinimize ? props.onMinimize : props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' style={{ padding: 20 }}>
        <Typography variant='h6' style={{ textAlign: 'center' }}>
          Unlock Chef Benefits
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <DialogContentText id='alert-dialog-description'>
          <Typography variant='body1' style={{ textAlign: 'center', paddingBottom: 10 }}>
            Enter your promo code to upgrade to a Chef Account
          </Typography>
        </DialogContentText>
        <Typography variant='body2' style={{textAlign: 'center', paddingBottom: 15}}>{message}</Typography>
        <OutlinedInput
              onChange={(e)=>{setCode(e.target.value)}}
              placeholder='CODE'
              fullWidth
              required
              color='primary'
              id='code'
              value={code}
              inputProps={{ style: { backgroundColor: 'white', padding: 10, fontSize: 14 } }}
            />
      </DialogContent>
      <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: 20, paddingTop: 0 }}>
         <Button
          onClick={applyForCode}
          disableElevation
          size='small'
          fullWidth
          variant='text'
          color='primary'
          autoFocus
          disabled={sent}
        >
        No promo code?  Click here to apply
        </Button>
        <Button
          onClick={submitActivationCode}
          disableElevation
          size='small'
          fullWidth
          variant='contained'
          color='primary'
          style={{ textTransform: 'uppercase' }}
          autoFocus
          disabled={code.length < 10 || sent}
        >
        Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
