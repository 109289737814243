import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Grid, Checkbox, Button, Typography, IconButton } from '@mui/material'
import { toFraction } from '../../helpers/helpers'
import { postPantry, resetPlan, postOrders } from '../../actions'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ReactGA from 'react-ga4'

const Ingredient = ({ amount, unit, ID, name, display, onPress }) => {
  const title = '' + toFraction(amount) + unit
  const theme = useTheme()
  return (
    <Grid
      key={ID}
      component={Button}
      onClick={() => {
        onPress(ID)
      }}
      style={{
        borderRadius: 15,
        padding: 10,
        marginTop: 15,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.03)'
      }}
    >
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <Checkbox
          checked={display}
          icon={<AddCircleOutlineIcon style={{ color: '#D0D0D0' }} />}
          checkedIcon={<CircleCheckedFilled style={{ color: theme.palette.primary.main }} />}
          color='primary'
        />
      </Grid>
      <Grid item xs={7} style={{ textAlign: 'left', overflow: 'hidden', display: 'block' }}>
        <Typography
          variant='body1'
          style={{
            ...{ fontFamily: 'Roboto, "sans-serif"', color: '#333335', fontWeight: 'bold' },
            ...(!display && { color: '#D0D0D0' })
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 10 }}>
        <Typography
          variant='body2'
          style={{ ...{ fontSize: 13, fontWeight: 'bold', display: 'none' }, ...(!display && { color: '#D0D0D0' }) }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

const AddToPantry = (props) => {
  const plan = useSelector((state) => state.planner.plan)
  const auth = useSelector((state) => state.auth.authenticated)
  const [myList, setMyList] = useState(props.purchaseList)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  /* ------------ render methods ------------ */
  useEffect(() => {
    setMyList(props.purchaseList)
  }, [props.purchaseList])

  const confirmPurchase = () => {
    if (!auth) {
      navigate('/login')
    } else {
      ReactGA.event({category: 'checkout', action: 'Add to Pantry'})
      var newHistory = []
      for (var mealID in plan) {
        newHistory.push({
          ChefID: plan[mealID]['ChefID'],
          ChefName: plan[mealID]['ChefName'],
          Name: plan[mealID]['Name'],
          ID: plan[mealID]['ID']
        })
      }

      var newPantry = []
      for (var i in myList) {
        if (myList[i]['Display']) {
          var ingredient = {
            Category: myList[i]['Category'],
            FDIC: myList[i]['FDIC'],
            FamilyID: myList[i]['FamilyID'],
            ID: myList[i]['ID'],
            MetricVolume: myList[i]['MetricVolume'],
            MetricWeight: myList[i]['MetricWeight'],
            Name: myList[i]['Name'],
            UsMeasurements: myList[i]['UsMeasurements'],
            DateAdded: Date.now()
          }
          newPantry.push(ingredient)
        }
      }
      if (newPantry.length > 0) {
        dispatch(postPantry(newPantry))
      }
      dispatch(postOrders(newHistory))
      dispatch(resetPlan())
      props.onClose()
    }
  }
  const toggleDisplay = (id) => {
    var tempList = { ...myList }
    tempList[id]['Display'] = !myList[id]['Display']
    setMyList(tempList)
  }
  return (
    <Grid
      container
      style={{ display: 'flex', flex: 1, alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}
    >
      <Grid container style={{ position: 'relative' }}>
        <Grid item xs={12} sm={11} style={{ margin:'auto', display: 'flex', flexDirection: 'column' }}>
          <Grid
            container
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: 25,
              paddingBottom: 10,position: 'sticky', zIndex: 3, backgroundColor: '#ffffff',top:0
            }}
          >
            <Typography variant='h5'>Add to Pantry </Typography>
            <IconButton onClick={props.onClose} sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <CloseIcon style={{ fontSize: 22 }} />
            </IconButton>
          </Grid>

          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flex: 1,
              margin: 'auto'
            }}
          >
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', paddingBottom: 75 }}>
              {Object.keys(myList).map(function (id, index) {
                return (
                  <Ingredient
                    key={index}
                    ID={id}
                    onPress={(e) => {
                      toggleDisplay(e)
                    }}
                    display={myList[id]['Display']}
                    unit={'g'}
                    amount={myList[id]['MetricWeight']}
                    name={myList[id]['Name']}
                  />
                )
              })}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                bottom: 10,
                zIndex: 2,
                position: { xs: 'fixed', sm: 'sticky' },
                width: { xs: '91.68%', sm: '100%' }
              }}
            >
              <Button
                onClick={props.onClose}
                variant='outlined'
                fullWidth
                disableElevation
                style={{ marginBottom: 10, backgroundColor: 'white' }}
              >
                I did not purchase anything
              </Button>
              <Button onClick={confirmPurchase} variant='contained' color='primary' fullWidth disableElevation>
                I Purchased the Selected Ingredients
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddToPantry
