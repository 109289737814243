import React, { useState } from 'react'
import { Typography, Grid, IconButton, Button, Menu, MenuItem } from '@mui/material'
import { updateServings, deleteMeal } from '../actions';
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import DynamicImage from './DynamicImage'
import placeholder from '../media/placeholder.jpg'
import Restaurant from '@mui/icons-material/Restaurant'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import '../styles/helpers.css'

const MenuMeal = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const pantry = useSelector((state) => state.planner.pantry)
  const plan = useSelector((state) => (state.planner.plan ? state.planner.plan : {}))
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChange = (event) => {
    dispatch(updateServings(pantry, plan, props.mealID, event.target.value))
    setAnchorEl(null)
  }

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
      <Grid
        className='recipe-card'
        sx={{ display: 'flex', width:{ xs: 160, sm: 180, md: 200}, minWidth:{ xs: 160, sm: 180, md: 200}}}
        style={{
          position: 'relative',
          marginRight: 20,
          marginTop: 20,
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          overflow: 'hidden',
          transitionDuration:'0.3s',
          willChange:'transform'
        }}
      >
        <div onClick={()=>{navigate(`/recipe/${props.name}/${props.mealID}`, {state: { from: location.pathname}})}} style={{cursor: 'pointer' }}>
          <DynamicImage
            style={{height: '100px', width: '100%', objectFit: 'cover' }}
            alt={props.name}
            placeholder={placeholder}
            bucket={'protected/' + props.chefID}
            url={props.mealID + '300x200'}
          />
          <div className='twolinesContainer' style={{ padding: 10 }}>
            <Typography
              className='twolinesText'
              variant='body1'
              style={{ textAlign: 'left', fontWeight: 'bold' }}
            >
              {props.name}
            </Typography>
          </div>
        </div>
        <div style={{ position: 'absolute', top: 5, left: 5 }}>
          <Button
            className='icon-hover'
            onClick={handleOpen}
            style={{
              borderRadius: 25,
              backgroundColor: 'rgba(0,0,0,0.6)',
              display: 'flex',
              flexDisplay: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            <Restaurant style={{ fontSize: 16, color: 'white' }} />
            <Typography variant='body1' style={{ fontSize: 15, fontWeight: 'bold', color: 'white', paddingLeft: 2, paddingRight: 1 }}>
              &nbsp;{props.servings}
            </Typography>
            <ExpandMoreIcon style={{ fontSize: 20, color: 'white' }} />
          </Button>
          <Menu
            scroll='body'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null)
            }}
            onOpen={handleOpen}
            value={props.servings}
            onChange={handleChange}
            PaperProps={{ style: { maxHeight: 150 } }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            getContentAnchorEl={null}
          >
            {Array.apply(null, { length: 100 }).map((value, index) => (
              <MenuItem selected={props.servings === index + 1} onClick={handleChange} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <IconButton
          className='icon-hover'
          onClick={() => {
            dispatch(deleteMeal(pantry, plan, props.mealID))
          }}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 5,
            right: 5,
            borderRadius: 25,
            backgroundColor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            flexDisplay: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5
          }}
        >
          <CloseIcon style={{ fontSize: 22, color: 'white'}} />
        </IconButton>
      </Grid>
  )
}
export default MenuMeal

/*
      <Grid
          onClick={()=>{navigate(`/recipe/${props.name}/${props.mealID}`, {state: { from: location.pathname}})}}
          className='hoverNoRaise'
          item
          xs={12}
          sx={{ display: { xs: 'none', sm: 'none' } }}
          style={{
            padding: 10,
            borderWidth: 0,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderColor: '#eff4f3',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
        >
          <Grid item xs={4} style={{ paddingRight: 10 }}>
            <div style={{ overflow: 'hidden', borderRadius: 5 }}>
              <DynamicImage
                style={{ display: 'table', height: '100%', width: '100%', minWidth: 75 }}
                alt={props.name}
                placeholder={placeholder}
                bucket={'protected/' + props.chefID}
                url={props.mealID + '300x200'}
              />
            </div>
          </Grid>
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                className='hoverText'
                noWrap={true}
                style={{
                  whiteSpace: 'noWrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  color: '#000000',
                  textTransform: 'capitalize',
                  fontWeight: 'bold'
                }}
              >
                {props.name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Typography variant='body1' style={{ fontSize: 18, fontWeight: 'bold' }}>
                {props.servings}
              </Typography>
              <Restaurant style={{ fontSize: 15, color: 'black' }} />
            </Grid>
          </Grid>
        </Grid>*/
