import React from 'react'
import { Typography, Grid, IconButton } from '@mui/material'
import DynamicImage from './DynamicImage'
import placeholder from '../media/placeholder.jpg'
import '../styles/helpers.css'

export default function MealTemplate(props) {
  return (
    <Grid key={props.mealid} item xs={6} sm={4} md={4} lg={4} style={{ padding: 10, cursor: 'pointer' }}>
      <Grid
        className='recipe-card'
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          fontFamily: 'Roboto, "sans-serif"',
          boxShadow: '0px 1px 4px rgba(0, 0, 0,0.1)',
          borderRadius: 6,
          overflow: 'hidden'
        }}
      >
        <div onClick={props.handleTemplateClick}>
          <DynamicImage
            style={{ display: 'table', height: '100%', width: '100%' }}
            alt={props.name}
            placeholder={placeholder}
            bucket={`protected/${props.chefID}`}
            url={props.mealid + '300x200'}
            hash={props.hash ? props.hash : false}
          />
          <div className='twolinesContainer' style={{ padding: 10 }}>
            <Typography
              className='twolinesText'
              variant='body1'
              style={{ textAlign: 'left', fontFamily: 'Source Sans Pro, "sans-serif"', color: '#000000' }}
            >
              {props.name}
            </Typography>
          </div>
        </div>
        {props.icon && (
          <IconButton
            className='icon-hover'
            onClick={props.handleIconButton}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: 5,
              right: 5,
              borderRadius: 25,
              backgroundColor: 'rgba(0,0,0,0.6)',
              display: 'flex',
              flexDisplay: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10
            }}
          >
            {props.icon}
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}
