import React, { useState, useEffect } from 'react'
import LoginPage from './LoginPage'
import LogInEmail from './LogInEmail'
import RegisterEmail from './RegisterEmail'
import NewUserInfo from './NewUserInfo'
import { Dialog } from '@mui/material'
import { TitleComponent } from '../TitleComponent'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetErrorMsg } from '../../actions'

export default function Login(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [step, setStep] = useState('login')
  const [username, setUsername] = useState('')
  const [body, setBody] = useState(null)
  const { state } = useLocation()

  const responseCode = useSelector((s) => s.user.response)

  useEffect(()=>{
    if(responseCode === 500 || responseCode === 403){
      navigate('/welcome')
    }
  }, [responseCode])

  useEffect(()=>{
    dispatch(resetErrorMsg())
  },[step])

  const handleGuest = () =>{
    if (state){
      if (state.from){
        navigate(state.from)
      }
      else if (state.backRoute){
        navigate(state.backRoute)
      }
    }
    else {
      navigate('/plan')
    }
  }

  const setFurtherInfo = (b, uname) => {
    setStep('newUserInfo')
    setBody(b)
    setUsername(uname)
  }

  const renderScreen = () => {
    switch (step) {
      case 'login':
        return <LoginPage handleGuest={handleGuest} setFurtherInfo={setFurtherInfo} login={true} setStep={setStep} {...props} />
      case 'Sign In':
        return <LogInEmail mode={step} login={true} setStep={setStep} {...props} />
      case 'Sign Up':
        return <RegisterEmail mode={step} login={false} setStep={setStep} {...props} />
      case 'passwordRecovery':
        return <LogInEmail mode={step} login={false} setStep={setStep} {...props} />
      case 'newUserInfo':
        return <NewUserInfo username={username} body={body}/>
      default:
        return <LoginPage handleGuest={handleGuest} setFurtherInfo={setFurtherInfo} setStep={setStep} {...props} />
    }
  }
  return (
    <Dialog
      BackdropProps={{ style: { backgroundColor: '#FFFFFFF2' } }}
      open={true}
      fullWidth
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
          height: '100%',
          paddingBottom: 0,
          marginBottom: 0,
          paddingTop: 0,
          marginTop: 0
        }
      }}
    >
      <TitleComponent
        title='Log In'
        description="Make an account with Salt'nPrep to automate and optimize your grocery shopping experience for free."
      />
      {renderScreen()}
    </Dialog>
  )
}
