import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DoneIcon from '@mui/icons-material/Done'


export default function FilterButton({menu, title, isSelected, items, selectedItems, setSelectedItems, selectOne = false, icon, onToggleOn, onToggleOff, disabled = false}) {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null)
    const [label, setLabel] = useState(title)

    useEffect(()=>{
        if (!selectedItems.length){
            setLabel(title)
        }
    },[selectedItems])

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClick = (event) => {
        if(menu){
            handleOpen(event)
        }
        else if (isSelected){
            onToggleOff()
        }
        else{
            onToggleOn()
        }
    }

    const handleSelect = (item) => {
        var tempSelectedItems = [...selectedItems]
        if (tempSelectedItems.includes(item)) {
            tempSelectedItems = tempSelectedItems.filter(element => {
                return element !== item;
            })
        }
        else {
            if (selectOne) {
                tempSelectedItems = []
            }
            tempSelectedItems.push(item)
        }

        setSelectedItems(tempSelectedItems)
        var countSelected = tempSelectedItems.length

        if ( countSelected === 0 ) {
            setLabel(title)
        }
        else if ( countSelected === 1 ){
            setLabel(tempSelectedItems[0])
        }
        else {
            setLabel(`${tempSelectedItems[0]} +${countSelected - 1}`)
        }

        setAnchorEl(null)
    }

    return (
        <div>
        <Button
            disabled={disabled}
            style={{...buttonStyle, ...isSelected && {backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main}}}
            variant={isSelected ? 'contained' : 'outlined'}
            disableElevation
            startIcon={isSelected ? <DoneIcon style={{color: theme.palette.primary.main}} /> : icon}
            endIcon={menu && <ArrowDropDownIcon style={{...isSelected && { color: theme.palette.primary.main }}}/>}
            onClick={handleClick}
        >
            {label}
        </Button>
        <Menu
            elevation={1}
            scroll='body'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
                setAnchorEl(null)
            }}
            onOpen={handleOpen}
            PaperProps={{ style: {borderRadius: 6, minWidth: 120, maxHeight: 300}}}
            sx={{mt:'2px'}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            getContentAnchorEl={null}
            >
            {items.map((item, index) => (
                <MenuItem key={index} dense={true} style={menuStyle} onClick={()=>{handleSelect(item)}} selected={selectedItems.includes(item)}>
                   <div style={{width: 35, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        {selectedItems.includes(item) && <DoneIcon style={{fontSize: 16, color: 'rgb(71, 77, 87)'}}/>}
                     </div>
                   {item}
                </MenuItem>
            ))}
        </Menu>
        </div>
    )
}

FilterButton.propTypes = {
    menu: PropTypes.bool,
    selectedItems: PropTypes.array,
    items: PropTypes.array,
    label: PropTypes.string
}

FilterButton.defaultProps = {
    menu: false,
    items: [],
    selectedItems: [],
    label: ''
}

const buttonStyle = {
    fontWeight: 300,
    margin: '0px 8px 8px 0px',
    padding: '4px 15px',
    borderColor:'#d8daec',
    height: 30,
    fontSize: 14,
    color: 'rgb(71, 77, 87)',
    whiteSpace: 'nowrap',
    minWidth:'max-content'
}

const menuStyle = {
    fontWeight: 300,
    padding: '10px 25px 10px 0px',
    height: 30,
    fontSize: 15,
    color: 'rgb(71, 77, 87)'
}