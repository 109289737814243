import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import DynamicImage from '../../components/DynamicImage';
import placeholder from '../../media/placeholder.jpg';
import '../../styles/helpers.css';

function CompactMeal({name, mealID, chefID}) {
  // TODO: use selector to get meal details using ID
  const navigate = useNavigate();

  const navigateToRecipe = () => navigate(`/recipe/${name}/${mealID}`, { state: { back: true } });

  return (
      <Box onClick={navigateToRecipe}
            sx={{
              //border: '1px solid rgba(209, 214, 220)',
              border: '2px solid white',
              transitionDuration: '0.3s',
              willChange: 'transform',
              overflow: 'hidden',
              borderRadius: '9999px',
              marginRight: {xs: '-1rem', sm: '-1rem'},
              width: '2.5rem',
              minWidth: '2.5rem',
              minHeight: '2.5rem',
              height: '2.5rem',
              cursor: 'pointer',
              zIndex: 10002
            }}>
        <DynamicImage
          style={{height:'2.5rem', objectFit: 'cover'}}
          alt={name}
          placeholder={placeholder}
          bucket={`protected/${chefID}`}
          url={mealID + '300x200'}
        />
      </Box>
  )
}

export default CompactMeal;
