import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {resetPlan} from '../../actions';
import {useTheme} from '@mui/material/styles';
import {BsBasketFill} from 'react-icons/bs';
import useMediaQuery from '@mui/material/useMediaQuery';
import {IconButton, Menu, MenuItem, Box} from '@mui/material';
// import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


function PlanMenuButton({disabled, openOrderDrawer}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const collapsed = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const onClickGoToCart = (event) => {
    openOrderDrawer();
    setAnchorEl(null);
  }
  const onClickClearList = (event) => {
    dispatch(resetPlan());
    setAnchorEl(null)
  }

  /* const onClickSavePlan = (event) => {
    // TODO: implement
    setAnchorEl(null);
  }
  const onClickPrintList = (event) => {
    // TODO: implement
    setAnchorEl(null);
  }
  */
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }}>
      <Menu
        scroll='body'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        onOpen={handleOpen}
        PaperProps={{ style: { paddingLeft: 10, paddingRight:10, borderRadius: 15, width: 130, border: '1px solid rgba(0,0,0,0.04)', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)' } }}
        sx={{ ml: { xs: 0, null: '44px' } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
      >
        {collapsed &&
          <MenuItem onClick={onClickGoToCart}
                  sx={{p: 1}}>
          <BsBasketFill style={{paddingRight:7.5}}/>
          Go to Cart
        </MenuItem>
        }
        <MenuItem onClick={onClickClearList}
                  sx={{p: 1}}>
          <DeleteOutlinedIcon style={{ fontSize: 18, paddingRight: 7.5 }} />
          Clear List
        </MenuItem>
        {/*
        <MenuItem onClick={onClickSavePlan}
                  sx={{p: 1}}>
          <SaveOutlinedIcon style={{ fontSize: 18, paddingRight: 7.5 }} />
          Save Plan
        </MenuItem>
        <MenuItem onClick={onClickPrintList}
                  sx={{p: 1}}>
          <LocalPrintshopOutlinedIcon style={{ fontSize: 18, paddingRight: 7.5 }} />
          Print List
        </MenuItem>
        */}
      </Menu>
          <IconButton
            onClick={handleOpen}
            style={{
              cursor: 'pointer',
              marginRight: 5,
              position: 'relative',
              left: 5
            }}
          >
            <MoreHorizIcon style={{cursor: 'pointer'}}/>
          </IconButton>
       {/*
          <>
            <IconButton
              onClick={handleOpen}
              disabled={disabled}
              className='icon-hover-primary'
              sx={{ mr: 1,
                cursor: 'pointer',
                display: 'none',
                flexDisplay: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: disabled
                  ? 'rgba(0, 0, 0, 0.12)'
                  : theme.palette.primary.main
              }}
            >
              <ExpandMoreIcon
                style={{
                  cursor: 'pointer',
                  color: !disabled ? 'white'
                     : 'rgba(0, 0, 0, 0.26)'
                }
              }/>
            </IconButton>
            <Box sx={{ width: 200 }}>
              <Button
                disabled={disabled}
                disableElevation
                onClick={onClickClearList}
                variant='contained'
                color='primary'
                size='large'
                fullWidth
                sx={{
                  pl: 2, pr: 2,
                  height: '40px',
                  whiteSpace: 'nowrap',
                  minWidth:'max-content'
                }}
              >
                {false && <BsBasketFill style={{paddingRight:7.5}}/>}
                Clear List
              </Button>
            </Box>
          </>
        */}
    </Box>
  );
}

export default PlanMenuButton;
