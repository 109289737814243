import React, {useState} from 'react'
import { FcGoogle } from 'react-icons/fc'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { socialSignOn } from '../../actions'

export default function GoogleAuth({title, isLoading, setIsLoading}) {
    const dispatch = useDispatch()

    const handleClick = (event) => {
        event.preventDefault()
        setIsLoading(()=>true)
        dispatch(socialSignOn({connection:'google-oauth2'}))
    }

    return (
        <Button
            size='small'
            variant='outlined'
            style={{
                borderRadius: 25,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                margin: 'auto',
                marginTop: 15,
                padding: 10,
                width: '80%',
                textAlign: 'center'
            }}
            onClick={handleClick}
            disabled={isLoading}
            startIcon={<FcGoogle style={{fontSize: 24 }} />}
        >
            {title}
        </Button>
    )
}

    /*
    constructor(props) {
        super(props)
        this.signIn = this.signIn.bind(this)
        this.state = { isLoading: false }
    }

    componentDidMount() {
        const ga = window.gapi && window.gapi.auth2 ? window.gapi.auth2.getAuthInstance() : null
        if (!ga) {
            this.createScript()
        }
    }

    signIn() {
        this.setState({ isLoading: true })
        const ga = window.gapi.auth2.getAuthInstance()
        ga.signIn().then(
            (googleUser) => {
                this.getAWSCredentials(googleUser)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    async getAWSCredentials(googleUser) {
        const { id_token, expires_at } = googleUser.getAuthResponse()
        const profile = googleUser.getBasicProfile()
        let user = {
            name: profile.getName(),
            email: profile.getEmail()
        }

       // await Auth.federatedSignIn('google', { token: id_token, expires_at }, user).then(() => {
        //    this.props.onLogin(user)
       // })
    }

    createScript() {
        // load the Google SDK
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        script.async = true
        script.onload = this.initGapi
        document.body.appendChild(script)
    }

    initGapi() {
        // init the Google SDK client
        const g = window.gapi
        g.load('auth2', function () {
            g.auth2.init({
                client_id: '823313102598-se6vld0f5f8e3panqjj5a8uiqn5rc5af.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid'
            })
        })
    }
*/