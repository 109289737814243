import {
	FETCH_PLAN_SUCCESS,
	SET_MEAL_SUCCESS,
	FETCH_FAVORITES_SUCCESS,
	FETCH_HISTORY_SUCCESS,
	UNAUTH_USER,
	FETCH_PANTRY_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {
	orders: [],
	plan: {},
	favorites: [],
	pantry: []
}

const planreducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_HISTORY_SUCCESS:
			return { ...state, orders: action.orders }

		case FETCH_PLAN_SUCCESS:
			return { ...state, plan: action.plan }

		case SET_MEAL_SUCCESS:
			return { ...state, plan: action.plan }

		case FETCH_FAVORITES_SUCCESS:
			return { ...state, favorites: action.favorites }

		case FETCH_PANTRY_SUCCESS:
			return { ...state, pantry: action.pantry, plan: action.plan }

		case UNAUTH_USER:
			return INITIAL_STATE

		default:
			return state
	}
}
export default planreducer
