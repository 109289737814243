import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, TextField, InputAdornment, IconButton, Tooltip, CircularProgress } from '@mui/material'

import { postAccount } from '../../actions'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { cleanUsername} from '../../helpers/helpers'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { usernameExists } from '../../helpers/API'
import { BiError, BiCheckShield } from 'react-icons/bi'
import { useTheme } from '@mui/material/styles'
import { handleMailChimpSubscription } from '../../helpers/API'

export default function NewUserInfo(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [username, setUsername] = useState(props.username)
  const [usernameClash, setUsernameClash] = useState(true)
  const [isLoadingClash, setIsLoadingClash] = useState(false)

  const theme = useTheme()


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async() => {
    setIsLoadingClash(()=>true)
    let clash = await usernameExists(username)
    setUsernameClash(clash)
    setIsLoadingClash(()=>false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUsername = async(event) => {
    setIsLoadingClash(()=>true)
    var cleaned = cleanUsername(event.target.value)
    setUsername(cleaned)
    if (cleaned.length > 6){
      let clash = await usernameExists(cleaned)
      setUsernameClash(clash)
    }
    setIsLoadingClash(()=>false)
  }

  const handleContinueDisabled = () => {
    return (
      usernameClash ||
      !(username.length > 6)
    )
  }
  const handleContinue = () => {
      let body = props.body
      body['Username'] = username
      dispatch(postAccount(body))
      handleMailChimpSubscription(body['Email'], body['Name'], 'subscribe')
      navigate('/plan')
  }
  return (
    <Grid
      container
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
      }}
    >
      <Grid
        item
        xs={11}
        sm={10}
        style={{ width: '100%', position: 'absolute', top: 10, display: 'flex', alignItems: 'flex-end' }}
      >
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <CloseIcon size={32} style={{ color: 'black' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>
          Welcome to Salt'nPrep
        </Typography>

      </Grid>

      <Grid
        container
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item xs={8} md={4} style={{ margin: 'auto', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body1' style={{ textAlign: 'left', fontWeight: 'bold', paddingBottom: 5}}>
          Select a Username
        </Typography>
          <TextField
            color='primary'
            size='small'
            onChange={handleUsername}
            fullWidth
            required
            id='username'
            autoComplete='new-username'
            value={username}
            label='Username'
            margin='normal'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonOutlineOutlinedIcon style={{ color: '#BBB' }} />
                </InputAdornment>
              ),
               endAdornment: (
                <InputAdornment position='end'>
                {isLoadingClash ?
                  <CircularProgress size={24}/>
                  :
                <Tooltip arrow placement='top' title={username.length < 7 ? 'Username must be at least 7 characters' : (usernameClash ? 'This username is not available' : 'This username is available!')}>
                 <span>
                    <IconButton size='small' disabled>
                      {
                        (username.length < 7 || usernameClash) ? <BiError style={{color: '#eed202', fontSize: 24}}/> : <BiCheckShield style={{color: theme.palette.primary.main, fontSize: 24}}/>
                      }
                    </IconButton>
                  </span>
                </Tooltip>
              }
                </InputAdornment>
              )
            }}
          />
          <Button
            disabled={handleContinueDisabled()}
            onClick={handleContinue}
            variant='contained'
            color='primary'
            fullWidth
            size='small'
            disableElevation
            style={{ padding: 10, marginTop: 15, marginBottom: 15, borderRadius: 25, color: 'white' }}
          >
            Register
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
        <Typography variant='body2' style={{ color: '#757575', textAlign: 'center' }}>
          Click "Continue" to agree to SaltnPrep's <Link to='/termsofservice'>Terms of Service</Link> and acknowledge
          that SaltnPrep's <Link to='/privacypolicy'>Privacy Policy</Link> applies to you.
        </Typography>
      </Grid>
    </Grid>
  )
}
