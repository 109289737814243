
// turn this on/off for globally enabling/disabling debug logging
const DEBUG = true;


const noop = () => {};
const TAG_PREFIX = `>>>`;

/**
 * log each arg with a separate console.log() call,
 * so when it is output to a modern browser console,
 * collection types will be expandable
 */
const _logForEach = function() {
  [...arguments].forEach((arg) => console.log(arg));
};

const _createLogError = (tag) => {
  return function() {
    [...arguments].forEach((arg) => {
      if (typeof arg === 'string')
        console.error(`${tag}: ${arg}`);
      else console.error(arg);
    });
  };
};

const _createInactiveDebugLogger = (tag) => {
  return {
    error: _createLogError(tag), // log errors even for inactive debuggers
    enter: noop,
    exit: noop,
    log: noop,
  };
};

/**
 * A debugLogger is created in, specific to, and enabled/disabled by, a particular file.
 * They log to the console and can be grouped using enter() and exit() to encapsulate logs
 * for a code block.
 */
const createDebugLogger = (tag, enabled) => {
  if (!DEBUG || !enabled)
    return _createInactiveDebugLogger(tag);

  console.log(`Logging turned on for ${tag}`);

  let enter = function(name) {
    console.group(`${TAG_PREFIX} ${tag} :: ${name}`);
  };
  let exit = function() {
    _logForEach(...arguments);
    console.groupEnd();
  };

  return {
    error: _createLogError(tag),
    enter,
    log: _logForEach,
    exit,
  };
};

export {
  createDebugLogger,
};
